import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { useMediaQuery } from "react-responsive";

import { Sparkles } from "lucide-react"

import { Skeleton } from "src/components/ui/skeleton";

import { useQuery } from '@tanstack/react-query';

import { scrapeAdjuntos } from 'src/utils/Models';

import { ScrapeAdjuntosResponse } from 'src/types/Types';

import { Button } from "src/components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";

function SkeletonCard({ className }: { className?: string }) {

	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					<Skeleton className="w-[350px] h-[30px]" />
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
							<TableHead>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						<TableRow>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
							<TableCell>
								<Skeleton className="w-[100px] h-[20px]" />
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}

export default function AdjuntosCard({codigo, className}: { codigo: string, className?: string }) {

	const navigate = useNavigate();

	const location = useLocation();

	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const {isPending, isError, data, error} = useQuery<ScrapeAdjuntosResponse>({
		queryKey: ['adjuntos', codigo],
		queryFn: () => scrapeAdjuntos(codigo),
		staleTime: 1000 * 60 * 60 * 1,
		enabled: !!codigo,
		retry: false,
	});

	if (codigo === '') {
		return <SkeletonCard className={className} />;
	}	
	const adjuntos = data?.data?.adjuntos || [];

	if (isPending) {
		return <SkeletonCard className={className} />;
	}

	if (isError) {
		return (
			<div>{String(error)}</div>
		);
	}

	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Adjuntos
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Nombre</TableHead>
							{!isMobile && (<TableHead>Tipo</TableHead>)}
							{!isMobile && (<TableHead>Descripción</TableHead>)}
							{!isMobile && (<TableHead>Tamaño</TableHead>)}
							<TableHead>Fecha Adjunto</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{adjuntos.map((adjunto, index) => (
							<TableRow key={index} onClick={() => window.open(adjunto.url)} className="cursor-pointer">
								<TableCell>{adjunto.nombre}</TableCell>
								{!isMobile && (<TableCell>{adjunto.tipo}</TableCell>)}
								{!isMobile && (<TableCell>{adjunto.descripcion}</TableCell>)}
								{!isMobile && (<TableCell>{adjunto.tamaño}</TableCell>)}
								<TableCell>{adjunto.fecha_adjunto}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Button className="mt-4 w-full text-lg" onClick={() => navigate(`${location.pathname}/chat`)} variant="default" size="lg">
					<Sparkles size={20} className="mr-5" />
					Chat
				</Button>
			</CardContent>
		</Card>
	)
}