import {
	Card,
	CardContent,
	CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { Separator } from "src/components/ui/separator";

import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "src/components/ui/collapsible"

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	// TableFooter,
	TableHead,
	// TableHeader,
	TableRow,
} from "src/components/ui/table"

import { Button } from "src/components/ui/button"

import { ChevronsUpDown } from "lucide-react"

import { CompraAgilDetailCompleto } from 'src/types/Types';

export default function ClienteCard( {detail}: {detail: CompraAgilDetailCompleto} ) {
	return(	
		<Card>
			<CardHeader>
				<CardTitle className="scroll-m-20 text-2xl tracking-tight">
					Organismo: {detail.empresa}
				</CardTitle>
				<CardDescription>
					{detail.rut}
				</CardDescription>
				<Table>
					<TableBody>
						<TableRow>
							<TableHead>Organización</TableHead>
							<TableCell>{detail.organizacion}</TableCell>
						</TableRow>
						<TableRow>
							<TableHead>Dirección</TableHead>
							<TableCell>{detail.direccion}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardHeader>
			<CardContent>
				<Separator />
				<Collapsible>
					<div className="flex items-center justify-between m-3">
						<span className="text-lg font-semibold">Contacto</span>
						<CollapsibleTrigger className="flex items-center">
							<Button variant="outline" size="icon">
								<ChevronsUpDown className="h-4" />
							</Button>
						</CollapsibleTrigger>
					</div>
					<CollapsibleContent>
						<Table>
							<TableBody>
								{detail.nombre && (
									<TableRow>
										<TableHead>Nombre</TableHead>
										<TableCell>{detail.nombreContacto}</TableCell>
									</TableRow>
								)}
								{detail.email && (
									<TableRow>
										<TableHead>Email</TableHead>
										<TableCell>{<a href={`mailto:${detail.email}`}>{detail.email}</a>}</TableCell>
									</TableRow>
								)}
								{detail.telefono && (
									<TableRow>
										<TableHead>Teléfono</TableHead>
										<TableCell>{detail.telefono}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</CollapsibleContent>
				</Collapsible>
				<Separator />
			</CardContent>
		</Card>
	);
}