import * as React from "react"

import {
	CornerDownLeft,
	Paperclip,
	Mic,
	ArrowsUpFromLine,
	Share,
	ChevronRight,
} from "lucide-react"

import { ScrollArea } from "src/components/ui/scroll-area"

import { Badge } from "src/components/ui/badge"

import { Button } from "src/components/ui/button"

import { LoadingSpinner } from "src/components/ui/loading"

import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "src/components/ui/drawer"

import { Separator } from "src/components/ui/separator"

import { Label } from "src/components/ui/label"

import { Input } from "src/components/ui/input"

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	// FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { Textarea } from "src/components/ui/textarea"

import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "src/components/ui/hover-card"

import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "src/components/ui/table"

import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "src/components/ui/tooltip"

import Loading from "src/components/Loading"

import { queryDocumentos, queryCache, scrapeAdjuntos } from "src/utils/Models"

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { QueryAdjuntoResponse, ScrapeAdjuntosResponse, Docs } from "src/types/Types"

import { useQuery } from '@tanstack/react-query'

import { useLocation } from "react-router-dom";

export default function LicitChat() {

	// TODO: add resizable a la caja de preguntas en desktop

	// TODO: agregar botón para compartir

	// TODO: agregar funcionalidad de micrófono con whisper

	const codigo = useLocation().pathname.split("/")[3];

	const [query, setQuery] = React.useState<string>('');
	const [referencias, setReferencias] = React.useState<number>(1);

	const cacheQuery = useQuery({
		queryKey: ['cache', codigo],
		queryFn: () => queryCache(codigo),
		staleTime: 1000 * 60 * 60 * 1,
	})

	const consultaQuery = useQuery<QueryAdjuntoResponse>({
		queryKey: ['ask', codigo, query, referencias],
		queryFn: () => queryDocumentos(codigo, query, referencias),
		staleTime: 1000 * 60 * 60 * 1,
		enabled: !!query && !!codigo,
	})

	const adjuntosQuery = useQuery<ScrapeAdjuntosResponse>({
		queryKey: ['adjuntos', codigo],
		queryFn: () => scrapeAdjuntos(codigo),
		staleTime: 1000 * 60 * 60 * 1,
		enabled: !!codigo,
	});

	type PYR = {
		pregunta: string;
		respuesta: string;
		docs: Docs[];
	}

	const [pyr, setpyr] = React.useState<PYR[]>([]);

	const respuesta = consultaQuery.data?.data || "";

	const adjuntos = adjuntosQuery.data?.data?.adjuntos || [];

	React.useEffect(() => {
		if (respuesta) {
			setpyr((prev) => [...prev, { 
				pregunta: respuesta.query,
				respuesta: respuesta.response, 
				docs: respuesta.docs,
			}])
		}
	}
	, [respuesta])

	const FormSchema = z.object({
		message: z
		.string()
		.min(1, {
			message: "Por favor ingresa un mensaje.",
		})
		.max(100, {
			message: "El mensaje no puede ser mayor a 100 caracteres.",
		}),
		referencias: z
		.preprocess((value) => Number(value), z
			.number({
				required_error: "Referencia es requerida",
				invalid_type_error: "Referencia debe ser un número",
			})
			.gte(1, {
				message: "Referencia debe ser mayor o igual a 1.",
			})
			.lte(5, {
				message: "Referencia debe ser menor o igual a 5.",
			})
		)
	})
	
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			message: '',
			referencias: 1,
		},
	})

	async function onSubmit(data: z.infer<typeof FormSchema>) {
		setQuery(data.message);
		setReferencias(Number(data.referencias));
	}

	if (cacheQuery.isLoading) return <Loading />

	function PreguntasTipo() {

		let preguntasTipo = [
			"¿Cuál es el plazo de entrega?",
			"¿Cuánto es el presupuesto autorizado?",
			"¿Cuál es el alcance del proyecto?",
			"¿Cuáles son los requisitos para una persona jurídica?",
			"¿Cuáles son los requisitos para una persona natural?",
		]

		return (
			<div className="grid gap-3 text-base">
				<ul className="cursor-pointer select-none space-y-2">
					{preguntasTipo.map((pregunta, index) => (
						<li key={index} className="flex items-center pl-2 hover:bg-primary/10 rounded h-10" onClick={() => form.setValue('message', pregunta)}>
							{pregunta}
							<ChevronRight className="size-4 ml-4" />
						</li>
					))}
				</ul>
			</div>
		)
	}

	function AdjuntosList() {
		return (
			<ScrollArea className="max-h-[20vh] overflow-auto">
				<Table>
					<TableBody>
						{adjuntos.map((adjunto, index) => (
							<TableRow key={index} onClick={() => window.open(adjunto.url)} className="cursor-pointer">
								<TableCell>{adjunto.nombre}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</ScrollArea>
		)
	}

	return (
		<div className="grid w-full h-[calc(100vh-120px)]">
		<div className="flex flex-col">
		<header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
			<h1 className="text-xl font-semibold">Consultas a Adjuntos</h1>
			<Drawer>
			<DrawerTrigger asChild>
				<Button variant="ghost" size="icon" className="md:hidden">
				<ArrowsUpFromLine className="size-4" />
				<span className="sr-only">Preguntas Tipo</span>
				</Button>
			</DrawerTrigger>
			<DrawerContent className="max-h-[80vh]">
				<DrawerHeader>
				<DrawerTitle>Preguntas Recomendadas</DrawerTitle>
				<DrawerDescription>
					Selecciona una pregunta para enviar.
				</DrawerDescription>
				</DrawerHeader>
				<fieldset className="grid gap-6 rounded-lg border p-4 w-full">
					<legend className="-ml-1 px-1 text-sm font-medium">
						Preguntas Tipo
					</legend>
					<PreguntasTipo />
				</fieldset>
				<fieldset className="grid gap-6 rounded-lg border p-4 w-full">
					<legend className="-ml-1 px-1 text-sm font-medium">
						Adjuntos
					</legend>
					{adjuntosQuery.isLoading && <LoadingSpinner />}
					<AdjuntosList />
				</fieldset>
			</DrawerContent>
			</Drawer>
			<Button
			variant="outline"
			size="sm"
			className="ml-auto gap-1.5 text-sm"
			>
			<Share className="size-3.5" />
				Compartir
			</Button>
		</header>
		<main className="grid flex-1 gap-4 overflow-auto p-4 md:grid-cols-2 lg:grid-cols-3">
			<div
			className="relative hidden flex-col items-start gap-8 md:flex" x-chunk="dashboard-03-chunk-0"
			>
				<fieldset className="grid gap-6 rounded-lg border p-4 w-full">
					<legend className="-ml-1 px-1 text-sm font-medium">
						Preguntas Tipo
					</legend>
					<PreguntasTipo />
				</fieldset>
				<fieldset className="grid gap-6 rounded-lg border p-4 w-full">
					<legend className="-ml-1 px-1 text-sm font-medium">
						Adjuntos
					</legend>
					{adjuntosQuery.isLoading && <LoadingSpinner />}
					<AdjuntosList />
				</fieldset>
			</div>
			<div className="relative flex h-full min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
			<Badge variant="outline" className="absolute right-3 top-3">
				Salida
			</Badge>
			<ScrollArea className="flex-1 p-10 max-h-[60vh] overflow-auto">
				{consultaQuery.isLoading && <LoadingSpinner />}
				{consultaQuery.isError && <p>Error: {String(consultaQuery.error)}</p>}
				{pyr && pyr.map((item, index) => (
					<div key={index} className="grid gap-2">
						<h1 className="text-lg font-semibold">Usuario</h1>
						<p>{item.pregunta}</p>
						<h1 className="text-lg font-semibold">Asistente</h1>
						<p>{item.respuesta}</p>
						<div className="flex flex-col items-start gap-2">
							<h1 className="text-base font-semibold">Referencias:</h1>
							{item.docs && item.docs.map((doc: Docs, index: number) => (
								<HoverCard>
									<HoverCardTrigger asChild>
										<Button variant="link" className="text-sm" size="sm" onClick={() => window.open(`${doc.metadata.source}#page=${doc.metadata.page + 1}`)} key={index}>
											{doc.metadata.source.split("/")[5]} pag. {doc.metadata.page + 1}
										</Button>
									</HoverCardTrigger>
									<HoverCardContent className="w-80" side="top">
										<div><p className="text-pretty line-clamp-10">...{doc.page_content}</p></div>
									</HoverCardContent>
								</HoverCard>
							))}
						</div>
						<Separator />
					</div>
				))}
			</ScrollArea>
			<Form {...form}>
				<form
					className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring" 
					x-chunk="dashboard-03-chunk-1"
					onSubmit={form.handleSubmit(onSubmit)} 
				>
					<div className="flex flex-row p-3">
						<FormField
						control={form.control}
						name="message"
						render={({ field }) => (
							<FormItem className="gap-1.5 w-full">
								<FormMessage />
								<FormControl>
									<Textarea
										id="message"
										placeholder="Escribe tu consulta acá..."
										className="min-h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0 bg-muted"
										{...field}
									/>
								</FormControl>
							</FormItem>
							)}
						/>
						<FormField
						control={form.control}
						name="referencias"
						render={({ field }) => (
							<FormItem className="w-48 ml-auto gap-1.5 pl-3">
								<FormMessage />
								<Label>Cantidad de Referencias</Label>
								<FormControl>
									<Input id="referencias" type="number" placeholder="1" {...field} />
								</FormControl>
							</FormItem>
							)}
						/>
					</div>
					<div className="flex items-center p-3 pt-0">
					<Tooltip>
						<TooltipTrigger asChild>
						<Button variant="ghost" size="icon">
							<Mic className="size-4" />
							<span className="sr-only">Usar Micrófono</span>
						</Button>
						</TooltipTrigger>
						<TooltipContent side="top">Usar Micrófono</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
						<Button variant="ghost" size="icon">
							<Paperclip className="size-4" />
							<span className="sr-only">Adjuntar Archivo</span>
						</Button>
						</TooltipTrigger>
						<TooltipContent side="top">Adjuntar Archivo</TooltipContent>
					</Tooltip>
					<Button type="submit" size="sm" className="ml-auto gap-1.5" disabled={consultaQuery.isLoading}>
						Realizar Consulta
						<CornerDownLeft className="size-3.5" />
					</Button>
					</div>
				</form>
			</Form>
			</div>
		</main>
		</div>
	</div>
	)
}
