import { createContext, useContext, ReactNode, useState } from "react";
import { googleLogout } from '@react-oauth/google';
import { fetchMenu, fetchConfiguration, fetchUserData } from "src/utils/UsersAuth";

import { useQuery, useQueryClient } from '@tanstack/react-query';

import Loading from "src/components/Loading";

import {
	MenuItemGroup,
	MenuResponse,
	ConfigurationResponse,
	ProfileItem,
	ProfileResponse,
} from "src/types/Types";

type AuthProviderValue = {
	token: string | null;
	menu: MenuItemGroup[];
	configuration: any;
	profile: ProfileItem;
	login: (token: string) => void;
	logout: () => void;
};

const AuthContext = createContext<AuthProviderValue | null>(null);

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {

	const [token, setCurrentToken] = useState<string | null>(
		sessionStorage.getItem('token') || null
	);

	const queryClient = useQueryClient();
	
	const login = async (token: string) => {
		sessionStorage.setItem('token', token);
		setCurrentToken(token);
		
		await queryClient.invalidateQueries({queryKey: ['menu']});
		await queryClient.invalidateQueries({queryKey: ['configuration']});
		await queryClient.invalidateQueries({queryKey: ['profile']});
		
	};

	const { isLoading: isLoadingMenu, data: dataMenu } = useQuery<MenuResponse>({
		queryKey: ['menu'],
		queryFn: fetchMenu,
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!token,
	});

	const { isLoading: isLoadingConfig, data: dataConfig } = useQuery<ConfigurationResponse>({
		queryKey: ['configuration'],
		queryFn: fetchConfiguration,
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!token,
		retry: false,
	});

	const { isLoading: isLoadingProfile, data: dataProfile } = useQuery<ProfileResponse>({
		queryKey: ['profile'],
		queryFn: fetchUserData,
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!token,
	});

	const menu = dataMenu?.data?.menu || [];

	const configuration = dataConfig?.data?.configuration || {};

	const profile = dataProfile?.data?.profile || {picture: '', name: '', email: '', create_date: '', email_verified: false, family_name: '', given_name: ''};

	const logout = async () => {

		setCurrentToken(null);
		sessionStorage.removeItem('token');
		googleLogout();
		
		await queryClient.invalidateQueries({queryKey: ['menu']});
		await queryClient.invalidateQueries({queryKey: ['configuration']});
		await queryClient.invalidateQueries({queryKey: ['profile']});
		
	}

	if (isLoadingMenu || isLoadingConfig || isLoadingProfile) {
		return <Loading />;
	}
	
	return (
		<AuthContext.Provider value={{ token, menu, configuration, profile, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
