import { Fade } from "react-awesome-reveal";

export default function Solucion() {

	return (
		<Fade triggerOnce>
			<div className="flex flex-col text-center items-center justify-center mt-20">
				<h2 className="text-4xl font-bold text-sky-400">
					Nos propusimos desarrollar una solución a estos problemas...
				</h2>
				<h2 className="text-5xl font-extrabold tracking-tight lg:text-5xl mt-20">
					Te presentamos Licita Easy
				</h2>
				<h2 className="tracking-tight lg:text-3xl mt-10 opacity-80">
					Un sistema que te permite acceder a las mejores oportunidades de negocio en el mercado público, de forma rápida y sencilla.
				</h2>
				<h3 className="lg:text-2xl mt-10 max-w-[900px]">
					Con nuestros modelos de Inteligencia Artificial entrenados para realizar tareas operativas de búsqueda, análisis, predicción y procesamiento masivo de documentos tu productividad va a aumentar exponencialmente. Ya no tienes que dedicarle tiempo al procesamiento manual, lo que te permitirá enfocarte en tareas más estratégicas y de mayor valor para tu negocio.
				</h3>
			</div>
		</Fade>
	);
}