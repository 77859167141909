import { useParams } from 'react-router-dom';

import CompraAgilDetail from './CompraAgil';
import LicitacionDetail from './Licitacion';

export default function LicitDetail({ codigo: codigoParam }: { codigo?: string }) {
	
	const codigoURL = useParams<{ codigo?: string }>();

	const codigo = codigoParam || codigoURL.codigo || '';
	
	return (
		<>	
			{codigo.includes("-COT") ? (
				<CompraAgilDetail codigo={codigo} />
			) : (
				<LicitacionDetail codigo={codigo} />
			)}
		</>
	);
}
