import AppRoutes from './components/Routes'
import { AuthProvider } from "./components/AuthContext";
import { BrowserRouter } from 'react-router-dom';
import './App.css'
import { ThemeProvider } from "src/components/ThemeProvider"
import { Toaster } from "src/components/ui/toaster"

import { TooltipProvider } from "src/components/ui/tooltip"

import { GoogleOAuthProvider } from '@react-oauth/google';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient()

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID || ''}>
				<ThemeProvider
					themes={["light", "dark", "system"]}
					attribute="class"
					defaultTheme="system"
					enableColorScheme={false}
					enableSystem
					disableTransitionOnChange
				>
					<TooltipProvider>
						<AuthProvider>
							<BrowserRouter>
								<AppRoutes />
							</BrowserRouter>
						</AuthProvider>
					</TooltipProvider>
				</ThemeProvider>
				<Toaster />
			</GoogleOAuthProvider>
		</QueryClientProvider>
	)
}

export default App;