import * as React from "react"

import { 
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'src/components/ui/table'

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"

import { ChevronDown } from "lucide-react"

import { Input } from "src/components/ui/input"

import { LoadingSpinner } from "src/components/ui/loading"

import { Button } from "src/components/ui/button"

import { columns } from "./columns";

export function DataTable({queryResponse}: {queryResponse: any}) {

	const [sorting, setSorting] = React.useState<SortingState>([])

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	)

	const [pagination, setPagination] = React.useState<PaginationState>({
		pageIndex: 0,
		pageSize: 8,
	})

	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({
			'Tipo': false,
			'FechaImportAPI': false,
	})

	const data = React.useMemo(() => queryResponse.data?.data?.detalle ?? [], [queryResponse.data]);

	const table = useReactTable({
		data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			pagination,
		},
	})

	if (queryResponse.isError) return <div>Error: {String(queryResponse.error)}</div>

	return (
		<div className="w-full">
			<div className="flex items-center py-4 m-1">
			<Input
				placeholder="Filtrar por Código..."
				value={(table.getColumn("CodigoExterno")?.getFilterValue() as string) ?? ""}
				onChange={(event) =>
				table.getColumn("CodigoExterno")?.setFilterValue(event.target.value)
				}
				className="max-w-sm"
			/>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
				<Button variant="outline" className="ml-auto">
					Columnas <ChevronDown className="ml-2 h-4 w-4" />
				</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
				{table
					.getAllColumns()
					.filter((column) => column.getCanHide())
					.map((column) => {
					return (
						<DropdownMenuCheckboxItem
						key={column.id}
						className="capitalize"
						checked={column.getIsVisible()}
						onCheckedChange={(value) =>
							column.toggleVisibility(!!value)
						}
						>
						{column.id}
						</DropdownMenuCheckboxItem>
					)
					})}
				</DropdownMenuContent>
			</DropdownMenu>
			</div>
			<div className="rounded-md border">
			<Table className="bg-background">
				<TableHeader>
				{table.getHeaderGroups().map((headerGroup) => (
					<TableRow key={headerGroup.id}>
					{headerGroup.headers.map((header) => {
						return (
						<TableHead key={header.id}>
							{header.isPlaceholder
							? null
							: flexRender(
								header.column.columnDef.header,
								header.getContext()
								)}
						</TableHead>
						)
					})}
					</TableRow>
				))}
				</TableHeader>
				<TableBody>
				{table.getRowModel().rows?.length ? (
					table.getRowModel().rows.map((row) => (
					<TableRow
						key={row.id}
						data-state={row.getIsSelected() && "selected"}
					>
						{row.getVisibleCells().map((cell) => (
						<TableCell key={cell.id}>
							{flexRender(
							cell.column.columnDef.cell,
							cell.getContext()
							)}
						</TableCell>
						))}
					</TableRow>
					))
				) : (
					<TableRow>
					<TableCell
						colSpan={columns.length}
						className="h-24 text-center"
					>
						{queryResponse.isLoading ? <LoadingSpinner text="Cargando..." /> : "No hay datos."}
					</TableCell>
					</TableRow>
				)}
				</TableBody>
			</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<div className="space-x-2">
					<Button
					variant="outline"
					size="sm"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
					>
						Anterior
					</Button>
					<Button
					variant="outline"
					size="sm"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
					>
						Siguiente
					</Button>
				</div>
			</div>
		</div>
	);
}
