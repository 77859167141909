import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Icons } from "src/components/ui/icons"
import { Button } from "src/components/ui/button"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"
import { Input } from "src/components/ui/input"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { loginUserEmail, loginUserGoogle, fetchUserInfo, loginUserGoogleToken } from 'src/utils/Users';

import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import { Mail } from 'lucide-react'

import { Link } from "react-router-dom"

import {
	Alert,
	AlertDescription,
} from "src/components/ui/alert"

import { useAuth } from 'src/components/AuthContext';

export default function LoginForm() {

	const auth = useAuth();

	const login = auth?.login;

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});
	
	const FormSchema = z.object({
	email : z
		.string()
		.email({
		message: "Por favor ingresa un email válido.",
		}),
	password: z
		.string()
		.min(1, {
			message: "Por favor ingresa tu contraseña.",
		})
	})

	const form = useForm<z.infer<typeof FormSchema>>({
	resolver: zodResolver(FormSchema),
	defaultValues: {
		email: '',
		password: '',
	},
	})

	async function onSubmit(data: z.infer<typeof FormSchema>) {

		setIsLoading(true);
		const result = await loginUserEmail(data.email, data.password);
		setApiResponse(result);
		setIsLoading(false);

	}

	const googleLogin = useGoogleLogin({
		onSuccess: async (credentialResponse: any) => {
			setIsLoading(true);
			const info = await fetchUserInfo(credentialResponse.access_token);
			const result = await loginUserGoogle(info);
			setApiResponse(result);
			setIsLoading(false);
		},
		onError: errorResponse => {
			console.error(errorResponse);
		},
	});
	
	async function handleApiResponse(credentialResponse: any) {
		setIsLoading(true);
		const response = await loginUserGoogleToken(credentialResponse.credential || '');
		setApiResponse(response);
		setIsLoading(false);
	}

	useGoogleOneTapLogin({
		onSuccess: credentialResponse => {
			handleApiResponse(credentialResponse);
		},
		onError: () => {
			setApiResponse({ status: 500, message: 'Hubo un error al iniciar sesión con Google.', data: { token: ''}});
		},
		cancel_on_tap_outside: false,
	});

	useEffect(() => {
		const executeLogin = async () => {
			if (apiResponse?.status === 200) {
				login && login(apiResponse.data?.token || '');
				navigate('/home');
			}
		};
		executeLogin();
	}, [apiResponse, navigate, login]);

	return (
		<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[450px]">
			<div className="flex flex-col space-y-2 text-center">
				<h1 className="text-4xl font-semibold tracking-tight">
					¡Bienvenido de Nuevo!
				</h1>
				<p className="text-2xl text-muted-foreground">
					Ingresa tu correo para iniciar sesión
				</p>
			</div>
			<div className="flex flex-col space-y-4">
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input id="email" type="email" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Contraseña</FormLabel>
								<FormControl>
									<Input id="password" type="password" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<div className="flex justify-center w-full">
						<Button type="submit" disabled={isLoading} className="w-full">
						{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						) : (
							<Mail className="mr-2 h-4 w-4" />
						)}{" "}
							Inicia Sesión
						</Button>
					</div>
					{apiResponse.status && (
						<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
							<AlertDescription>
								{apiResponse.message}
							</AlertDescription>
						</Alert>
					)}
					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t" />
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-background px-2 text-muted-foreground">
							o bien
							</span>
						</div>
					</div>
					<div className="flex justify-center w-full">
						<Button variant="outline" type="button" disabled={isLoading} onClick={() => googleLogin()} className="w-full">
						{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						) : (
							<Icons.Google className="mr-2 h-4 w-4" />
						)}{" "}
						Inicia sesión usando Google
						</Button>
					</div>
				</form>
			</Form>
		</div>
		<div className="flex flex-col space-y-4">
			<p className="text-center text-muted-foreground">
				¿No tienes una cuenta?{" "}
				<Link
					to="/register"
					className="underline underline-offset-4 hover:text-primary"
				>
					Regístrate
				</Link>
			</p>
		</div>
		<div className="flex flex-col space-y-4">
			<p className="text-center text-muted-foreground">
				<Link
					to="/forgot-password"
					className="underline underline-offset-4 hover:text-primary"
				>
					¿Olvidaste tu contraseña?
				</Link>
			</p>
		</div>
		<p className="px-8 text-center text-sm text-muted-foreground">
			<Link
				to="#"
				className="underline underline-offset-4 hover:text-primary"
			>
				Términos de Servicio
			</Link>{" "}
				- {" "}
			<Link
				to="#"
				className="underline underline-offset-4 hover:text-primary"
			>
				Política de Privacidad
			</Link>
			.
		</p>
	</div>
  )
}
