import React, { useEffect, useRef, useState } from 'react';
import d3KitTimeline from 'd3kit-timeline';
import { timeFormatLocale } from 'd3-time-format';
import spanishLocale from 'src/lib/es-MX.json';
import {
  Card,
  CardTitle,
  CardHeader,
  CardContent,
} from "./card"
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "./table"

interface TimelineDataItem {
  date: number;
  content: string;
  color: string;
}

interface TimelineChartProps {
  data: TimelineDataItem[];
  isMobile: boolean;
}

const TimelineChart: React.FC<TimelineChartProps> = ({ data, isMobile }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartDimensions, setChartDimensions] = useState({ width: 500, height: 500 });
  const [cardData, setCardData] = useState({ open: false, data: {"content": "", "date": 0, "color": "", "time": new Date()} });

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (!chartContainer) return;

    const today = { date: new Date().getTime(), content: 'HOY', color: '#cf082b' }

    const todayIndex = data.findIndex(item => item.content === 'HOY');

    if (todayIndex !== -1) {
      data.splice(todayIndex, 1);
    }

    data.push(today);

    const processedData = data.map(item => ({
      ...item,
      time: new Date(item.date),
    }));

    const maxDate = processedData.reduce((max, p) => p.date > max ? p.date : max, processedData[0].date);
    const minDate = processedData.reduce((min, p) => p.date < min ? p.date : min, processedData[0].date);
    const diff = maxDate - minDate;

    const localeFormat = (diff: number) => {
      if (diff < 1000 * 60 * 60 * 24 * 1) {
        return '%H:%M';
      }
      if (diff < 1000 * 60 * 60 * 24 * 7) {
        return '%a %d';
      }
      if (diff < 1000 * 60 * 60 * 24 * 30) {
        return '%d %b';
      }
      if (diff < 1000 * 60 * 60 * 24 * 30 * 7) {
        return '%b';
      }
      return '%b %y';
    };

    const format = timeFormatLocale(spanishLocale).format(localeFormat(diff));

    let chart = new d3KitTimeline(chartContainer, {
      direction: isMobile ? 'right' : 'down',
      initialHeight: chartDimensions.height,
      initialWidth: chartDimensions.width,
      layerGap: 40,
      margin: { 
        left: 40, 
        right: 40, 
        top: 40, 
        bottom: 40 
      },
      labella: {
        maxPos: chartDimensions.width - (!isMobile ? 250 : 0),
        algorithm: 'simple',
        density: 0.85,
      },
      textFn: (d) => d.content,
      labelBgColor: (d) => d.color,
      timeFn: (d) => d.time,
      linkColor: (d) => d.color,
      dotColor: (d) => d.color,
      dotRadius: (d) => d.content==='HOY'? 5: 3,
      textStyle: {
        'font-weight': function(d: any){ return d.content==='HOY'? 700: 400},
        'font-size': function(d: any){ return d.content==='HOY'? '1.2em': '1em'}
      },
      formatAxis: (axis) => {
        axis.tickFormat(format);
        axis.ticks(6);
      },
    });

    chart
      .data(processedData)
      .updateDimensionNow()
      .resizeToFit()
      .on('labelMouseover', (d: any) => {
        setCardData({ open: true, data: d.data })
      })
      .on('labelMouseout', () => {
        setCardData({ open: false, data: {"content": "", "date": 0, "color": "", "time": new Date()} })
      })

    return () => {
      chartContainer.innerHTML = '';
    };
  }, [data, isMobile, chartDimensions]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setChartDimensions({ width, height });
      }
    });

    if (chartContainer) {
      resizeObserver.observe(chartContainer);
    }

    return () => {
      if (chartContainer) {
        resizeObserver.unobserve(chartContainer);
      }
    };
  }, []);

  return (
    <>
      <div ref={chartContainerRef} id="timeline" style={{ width: '100%', height: '100%', position: 'relative' }}>
        {cardData.open && (
          <Card className="absolute top-0 right-0 z-10 max-w-52">
            <CardHeader>
              <CardTitle>{cardData.data.content}</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody className="text-xs">
                  <TableRow>
                    <TableHead>Fecha</TableHead>
                    <TableCell>{cardData.data.time.toLocaleDateString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHead>Hora</TableHead>
                    <TableCell>{cardData.data.time.toLocaleTimeString()}</TableCell>
                  </TableRow>
                  {(cardData.data.date - Date.now() > 0) && (
                    <>
                      <TableRow>
                        <TableHead>Días</TableHead>
                        <TableCell>{Math.floor((cardData.data.date - Date.now()) / (1000 * 60 * 60 * 24))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableHead>Horas</TableHead>
                        <TableCell>{Math.floor((cardData.data.date - Date.now()) / (1000 * 60 * 60)) + ":" + Math.floor((cardData.data.date - Date.now()) % (1000 * 60 * 60) / (1000 * 60))}</TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
};

export { TimelineChart }