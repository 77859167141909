import React from "react";

import {
	Card,
	CardContent,
	CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card";

import { Input } from "src/components/ui/input"

import { Button } from "src/components/ui/button"

import {
	Form,
	FormControl,	
	FormField,
	FormItem,
	// FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import LicitDetail from "src/components/App/LicitDetail";

import { SearchCode } from "lucide-react";

export default function BusquedaDirecta() {

	const ultimaConsultaID = sessionStorage.getItem('ultimaConsultaID') || '';

	const [query, setQuery] = React.useState<string>(ultimaConsultaID);

	async function onSubmit(data: z.infer<typeof FormSchema>) {
		setQuery(data.consulta);
		sessionStorage.setItem('ultimaConsultaID', data.consulta);
	}

	const queryRegex = new RegExp('^\\d{1,10}-\\d{1,10}-[A-Z0-9]{1,5}$');

	const FormSchema = z.object({
		consulta: z
			.string()
			.min(1, {
				message: "Por favor ingresa una búsqueda.",
			})
			.max(100, {
				message: "La búsqueda no puede tener más de 100 caracteres.",
			})
			.regex(queryRegex, {
				message: "Por favor ingresa un código válido.",
			}),
		})
	
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			consulta: query,
		},
	})

	return (
		<Card>
		<CardHeader>
			<CardTitle>Busqueda Directa</CardTitle>
			<CardDescription>Busqueda por el código de licitación o compra ágil</CardDescription>
		</CardHeader>
		<CardContent>
			<div className="w-full">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex justify-center items-center">
							<div className="flex flex-col md:flex-row items-center w-1/2 min-w-[350px] pb-5">
								<FormField
									control={form.control}
									name="consulta"
									render={({ field }) => (
										<FormItem className="w-full text-lg pb-2">
											<FormControl>
												<Input
													placeholder="Ingresa el Código de la Licitación o Compra Ágil..."
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<Button type="submit" size="icon" className="w-full md:w-20">
									<SearchCode />
								</Button>
							</div>
						</div>
					</form>
				</Form>
				{query && <LicitDetail codigo={query} />}
			</div>
		</CardContent>
	</Card> 
);
}
