import { Button } from "../../../ui/button"
import { FaLongArrowAltLeft } from "react-icons/fa";
import DialogFormClient from "./DialogFormClient"

export default function ValidationforNok() {
	return (
		<div className="container relative h-screen flex-col items-center justify-center grid mt-20">
			<div className=" mx-auto flex w-full flex-col justify-center space-y-10 sm:w-[950px] ">
				<div className="flex flex-col space-y-20 text-center mb-10">
					<h1 className="text-4xl font-semibold tracking-tight">
						No pudimos verificar el dominio de tu empresa con el correo de tu cuenta
					</h1>
					<p className="text-2xl text-muted-foreground">
						Puedes volver atrás y continuar con la configuracion como cuenta de persona natural, 
						o puedes crear una solicitud para activar tu cuenta llenando el formulario de registro 
						al presionar el botón "Crear solicitud"
					</p>
				</div>
			</div>
			<div className="flex justify-between">
				<Button className="space-x-2" type="button" onClick={() => window.history.back()}>
					<FaLongArrowAltLeft className="mr-2 h-4 w-4" /> Atras
				</Button>
				<DialogFormClient />
			</div>
		</div>
	)
}