import { Eye, EyeOff } from "lucide-react"

import { CompraAgilDetailResponse, FollowedDetailsResponse } from 'src/types/Types';
import { addFollowed, deleteFollowed, fetchFollowedDetails } from 'src/utils/Models';

import { Button } from "src/components/ui/button"

import ResumenCard from "./components/ResumenCard";
import ClienteCard from "./components/ClienteCard";
import FechasCard from "./components/FechasCard";
import ItemsCard from './components/ItemsCard';
import PresupuestoCard from "./components/PresupuestoCard";

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from "src/components/ui/use-toast"

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip"

import { fetchDetailsCA } from 'src/utils/Models';

import Loading from "src/components/Loading"

export default function CompraAgilDetail({codigo}: {codigo: string}){

	const caReq = useQuery<CompraAgilDetailResponse>({
		queryKey: ['detail', codigo],
		queryFn: () => fetchDetailsCA(codigo),
		staleTime: 1000 * 60 * 10,
		enabled: !!codigo,
	});

	const followReq = useQuery<FollowedDetailsResponse>({
		queryKey: ['seguidos'],
		queryFn: fetchFollowedDetails,
		staleTime: 1000 * 60 * 60 * 2,
	});

	const queryClient = useQueryClient();
	const { toast } = useToast();
	
	const addMutation = useMutation({
		mutationFn: addFollowed,
		onMutate: () => {
			toast({
				title: "Agregando Compra Ágil",
				description: "Agregando la Compra Ágil a tus seguidas.",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['seguidos'],
			});
			toast({
				title: "Compra Ágil agregada",
				description: "La Compra Ágil se agregó a tu lista de seguidos.",
			});
		},
		onError: () => {
			toast({
				title: "Error al agregar",
				description: "Hubo un error al intentar agregar la Compra Ágil a tus seguidas, por favor intenta nuevamente.",
			});
		},
	});

	const deleteMutation = useMutation({
		mutationFn: deleteFollowed,
		onMutate: () => {
			toast({
				title: "Eliminando licitación",
				description: "Eliminando la licitación de tus seguidas.",
			});
		},
		onSuccess: () => {
			queryClient.setQueryData(
				['seguidos'],
				(prev: FollowedDetailsResponse) => {
					return {
						...prev,
						data: {
							...prev.data,
							follows_details: prev.data.follows_details.filter(item => item.CodigoExterno !== codigo),
						}
					}
				}
			)
			queryClient.invalidateQueries({
				queryKey: ['seguidos'],
			});
			toast({
				title: "Licitación eliminada",
				description: "La licitación ha sido eliminada de tus seguidas.",
			});
		},
		onError: () => {
			toast({
				title: "Error al eliminar",
				description: "Hubo un error al intentar eliminar la licitación de tus seguidas, por favor intenta nuevamente.",
			});
		},
	});

	const isFollowed = followReq?.data?.data?.follows_details?.some(item => item.CodigoExterno === codigo);

	const detail = caReq.data?.data?.detalle[0] || {};

	if (caReq.isError) {
		return (
			<div className="flex justify-center items-center mt-10">
				<h1 className="text-3xl">No se encontraron datos</h1>
			</div>
		);
	};

	return (
		<>
			<div className='flex space-x-10 items-center mt-5'>
				<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl justify-left">
					Detalle de la Compra Ágil Código: {codigo}
				</h1>
				{!followReq.isLoading && (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button variant="secondary" size="icon" onClick={() => {
									if (isFollowed) {
										deleteMutation.mutate(codigo);
									} else {
										addMutation.mutate(codigo);
									}
								}}>
									{isFollowed ? (
										<EyeOff />
									) : (
										<Eye />
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>{isFollowed ? 'Dejar de seguir' : 'Seguir'}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
			</div>
			{caReq.isPending ? (<Loading />) : (
				<div className="grid md:grid-cols-3 justify-center items-center gap-10 p-10 mt-10 border bg-muted/40">
					<ResumenCard detail={detail as any} />
					<ClienteCard detail={detail as any} />
					<PresupuestoCard detail={detail as any} />
					<ItemsCard detail={detail as any} />
					<FechasCard detail={detail as any} />
				</div>
			)}
		</>
	);
}