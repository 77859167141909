import { Skeleton } from "src/components/ui/skeleton"

import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

export default function AtrasoCard({ className }: { className?: string }) {
	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Atraso
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Skeleton className="h-20 w-48" />
			</CardContent>
		</Card>
	)
}