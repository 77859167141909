import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"

import { ColumnDef } from "@tanstack/react-table"

import { ArrowUpDown, MoreHorizontal } from "lucide-react"

import { Badge } from 'src/components/ui/badge';

import { Button } from "src/components/ui/button"

import DialogLicitacion from "src/components/App/DialogLicitacion";

import { deleteFollowed } from "src/utils/Models";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Licitacion } from "src/types/Types";

import { useNavigate } from "react-router-dom";

import { useToast } from "src/components/ui/use-toast"

function goMercadoPublico(codigo: string) {
	window.open(`https://www.mercadopublico.cl/Procurement/Modules/RFB/DetailsAcquisition.aspx?idlicitacion=${codigo}`, "_blank");
}

const nf = new Intl.NumberFormat("es-CL", {
	style: "currency",
	currency: "CLP",
})

const triggerComponent = (CodigoExterno: string) => (
	<Button	variant="outline">
		{CodigoExterno}
	</Button>
)

const DropdownMenuComponent = ({ licitacion }: { licitacion: Licitacion }) => {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: deleteFollowed,
		onMutate: () => {
			toast({
				title: "Eliminando licitación",
				description: "Eliminando la licitación de tus seguidas.",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['seguidos'],
			});
			queryClient.setQueryData(
				['seguidos'],
				(oldData: any) => {
					const newData = oldData.data.follows_details.filter((item: any) => item.CodigoExterno !== licitacion.CodigoExterno);
					return {
						data: {
							follows_details: newData,
						},
					};
				}
			);
			toast({
				title: "Licitación eliminada",
				description: "La licitación ha sido eliminada de tus seguidas.",
			});
		},
		onError: () => {
			toast({
				title: "Error al eliminar",
				description: "Hubo un error al intentar eliminar la licitación de tus seguidas, por favor intenta nuevamente.",
			});
		},
	});

	const navigate = useNavigate();

	const { toast } = useToast();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="h-8 w-8 p-0">
					<span className="sr-only">Abrir Menu</span>
					<MoreHorizontal className="h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuLabel>Acciones</DropdownMenuLabel>
				<DropdownMenuItem onClick={() => navigator.clipboard.writeText(licitacion.CodigoExterno)}>
					Copiar ID de Licitación
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={() => mutation.mutate(licitacion.CodigoExterno)}>
					Eliminar Seguida
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => navigate(`/seguimiento/licitaciones/${licitacion.CodigoExterno}`)}>
					Ver Detalle
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => goMercadoPublico(licitacion.CodigoExterno)}>
					Ver Ficha Mercado Público
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export const columns: ColumnDef<Licitacion>[] = [
	{
		accessorKey: "CodigoExterno",
		header: "CodigoExterno",
		cell: ({ row }) => (
			<DialogLicitacion licitacion={row.original} triggerComponent={triggerComponent(row.getValue("CodigoExterno"))} />
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: "Nombre",
		header: "Nombre",
		cell: ({ row }) => (
			<div><Badge className="mr-2">{row.getValue("Tipo")}</Badge>{row.getValue("Nombre")}</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: "NombreOrganismo",
		header: "Organismo",
		cell: ({ row }) => (
			<div>{row.getValue("NombreOrganismo")}</div>
		),
		enableSorting: false,
		enableHiding: true,
	},
	{
		accessorKey: "FechaPublicacion",
		header: ({ column }) => {
			return (
				<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
					Fecha de Publicación
					<ArrowUpDown className="ml-2 h-4 w-4" />
				</Button>
			)
		},
		cell: ({ row }) => (
			<div>{new Date(row.getValue("FechaPublicacion")).toLocaleDateString()}</div>
		),
		enableSorting: true,
		enableHiding: true,
	},
	{
		accessorKey: "FechaCierre",
		header: ({ column }) => {
			return (
				<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
					Fecha de Cierre
					<ArrowUpDown className="ml-2 h-4 w-4" />
				</Button>
			)
		},
		cell: ({ row }) => (
			<div>{new Date(row.getValue("FechaCierre")).toLocaleDateString()}</div>
		),
		enableSorting: true,
		enableHiding: true,
	},
	{
		accessorKey: "FechaImportAPI",
		header: "Fecha de Importación",
		cell: ({ row }) => (
			<div>{new Date(row.getValue("FechaImportAPI")).toLocaleDateString()}</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: "Tipo",
		header: "Tipo",
		cell: ({ row }) => (
			<div>{row.getValue("Tipo")}</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: "Estado",
		header: "Estado",
		cell: ({ row }) => (
			<div>{row.getValue("Estado")}</div>
		),
		enableSorting: false,
		enableHiding: true,
	},
	{
		accessorKey: "MontoEstimado",
		header: ({ column }) => {
			return (
				<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
					Monto Estimado
					<ArrowUpDown className="ml-2 h-4 w-4" />
				</Button>
			)
		},
		cell: ({ row }) => (
			<div>{nf.format(row.getValue("MontoEstimado"))}</div>
		),
		enableSorting: true,
		enableHiding: true,
	},
	{
		accessorKey: "Moneda",
		header: "Moneda",
		cell: ({ row }) => (
			<div>{row.getValue("Moneda")}</div>
		),
		enableSorting: false,
		enableHiding: true,
	},
	{
		id: "actions",
		enableHiding: false,
		cell: ({ row }) => {
			const licitacion = row.original;
			return <DropdownMenuComponent licitacion={licitacion} />;
		},
	}
]
