import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card";

export default function Simlares() {

	return (
		<Card>
			<CardHeader>
				<CardTitle>Simlares a tus Seguidos</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="mx-auto flex w-full h-[calc(100vh-240px)] flex-col items-center justify-center space-y-6 lg:w-[950px]">
					<div className="flex flex-col space-y-2 text-center">
						<h1 className="text-2xl font-semibold tracking-tight">Módulo pendiente de desarrollo</h1>
						<p className="mt-5 text-base text-muted-foreground">Este módulo se encuentra en desarrollo, te notificaremos cuando esté disponible</p>
						<p className="mt-5 text-base text-muted-foreground">Puedes seguir navegando en la plataforma y disfrutar de todas las funcionalidades que tenemos para ti</p>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}