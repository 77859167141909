import { Button } from "src/components/ui/button";
import { useNavigate } from "react-router-dom";

export default function ValidateCompany() {

	const navigate = useNavigate();

	return(
		<div className="container relative flex-col items-center justify-center md:grid">
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[800px]">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-4xl font-semibold tracking-tight">
						¡El dominio de tu empresa fue validado correctamente!
					</h1>
					<p className="text-2xl text-muted-foreground">
						Para entender bien tu negocio y poder entregarte las mejores recomendaciones vamos a acceder a tu 
						historial de ordenes de compra en el mercado público, pero antes, necesitamos que completes un formulario
						que nos ayudará a entregarte las mejores recomendaciones.
					</p>
				</div >
				<div className="flex items-center flex-col">
					<Button onClick={() => navigate("/onboarding/form")} size="lg">
						Completar formulario
					</Button>
				</div>
			</div>
		</div>
	)
}