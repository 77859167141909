import CalendarComponent from "./components/CalendarComponent";

import { useQuery } from '@tanstack/react-query'

import Loading from "src/components/Loading";

import {
	Card,
	CardTitle,
	CardContent,
} from "src/components/ui/card";

import moment from "moment";

import { fetchFollowedDetails } from "src/utils/Models";

import { FollowedDetailsResponse } from "src/types/Types";

export default function Calendar() {

	const { isPending, isError, data, error } = useQuery<FollowedDetailsResponse>({
		queryKey: ['seguidos'],
		queryFn: () => fetchFollowedDetails(),
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
	})

	const followedDetails = data?.data.follows_details || [];

	const eventosCierre = followedDetails.map((event: any) => {
		return {
			id: event.id,
			start: moment(event.FechaCierre).toDate(),
			title: event.Nombre,
			allDay: false,
			extendedProps: {
				type: event.Tipo,
				class: 'Cierre',
				status: event.Estado,
				code: event.CodigoExterno,
				description: event.Descripcion, 
			},
			backgroundColor: '#910000',
		}
	});
	
	const eventosPublicacion = followedDetails.map((event: any) => {
		return {
			id: event.id,
			start: moment(event.FechaPublicacion).toDate(),
			title: event.Nombre,
			allDay: false,
			extendedProps: {
				type: event.Tipo,
				class: 'Publicación',
				status: event.Estado,
				code: event.CodigoExterno,
				description: event.Descripcion, 
			},
			backgroundColor: '#019100',
		}
	});
	
	const allEvents = [...eventosCierre, ...eventosPublicacion];

	if (isPending) return <Loading />;

	if (isError) return <div>Error: {String(error)}</div>;

	return (
		<div className="flex items-center justify-center w-full">
			<Card>
				<CardTitle className="m-4">Calendario</CardTitle>
				<CardContent className="items-center justify-center w-full">
					<CalendarComponent events={allEvents} />
				</CardContent>
			</Card>
		</div>
	);
	
}