import { Fade, Zoom } from 'react-awesome-reveal';

export default function Loading() {
	const logo_mini = "/logo/LE-Icon.png";
	
	return (
		<div className="flex items-center justify-center h-[80vh]">
			<Fade direction="up" triggerOnce duration={4000}>
				<Zoom cascade>
					<img src={logo_mini} className="rounded-full h-44 w-44" alt="Loading" />
				</Zoom>
			</Fade>
		</div>
	);
};