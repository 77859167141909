import {
	Card,
	CardContent,
	CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { Separator } from "src/components/ui/separator";

import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "src/components/ui/collapsible"

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	// TableFooter,
	TableHead,
	// TableHeader,
	TableRow,
} from "src/components/ui/table"

import { ChevronsUpDown } from "lucide-react"

import { LicitacionDetailCompleto } from 'src/types/Types';

export default function ClienteCard( {detail, className}: { detail: LicitacionDetailCompleto, className?: string } ) {
	
	return(
		<Card className={className}>
			<CardHeader>
				<CardTitle className="scroll-m-20 text-2xl tracking-tight">
					Organismo: {detail.NombreOrganismo}
				</CardTitle>
				<CardDescription>
					RUT: {detail.RutUnidad}
				</CardDescription>
				<CardDescription>
					Cantidad Reclamos: {detail.CantidadReclamos}
				</CardDescription>
				<Table>
					<TableBody>
						<TableRow>
							<TableHead>Nombre</TableHead>
							<TableCell>{detail.NombreUnidad}</TableCell>
						</TableRow>
						<TableRow>
							<TableHead>Comuna</TableHead>
							<TableCell>{detail.ComunaUnidad}</TableCell>
						</TableRow>
						<TableRow>
							<TableHead>Dirección</TableHead>
							<TableCell>{detail.DireccionUnidad}</TableCell>
						</TableRow>
						<TableRow>
							<TableHead>Región</TableHead>
							<TableCell>{detail.RegionUnidad}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col items-left w-full">
					<Separator />
					<Collapsible>
						<div className="flex items-center justify-between m-3">
							<span className="text-lg font-semibold">Usuario</span>
							<CollapsibleTrigger className="flex items-center">
								<span className="flex items-center cursor-pointer hover:bg-muted p-2 rounded">
									<ChevronsUpDown className="h-4" />
								</span>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent>
							<Table>
								<TableBody>
									{detail.NombreResponsableContrato && (
										<TableRow>
											<TableHead>Responsable Contrato</TableHead>
											<TableCell>{detail.NombreResponsableContrato}</TableCell>
										</TableRow>
									)}
									{detail.FonoResponsableContrato && (
										<TableRow>
											<TableHead>Teléfono</TableHead>
											<TableCell>{detail.FonoResponsableContrato}</TableCell>
										</TableRow>
									)}
									{detail.EmailResponsableContrato && (
										<TableRow>
											<TableHead>Email</TableHead>
											<TableCell>{<a href={`mailto:${detail.EmailResponsableContrato}`}>{detail.EmailResponsableContrato}</a>}</TableCell>
										</TableRow>
									)}
									<Separator />
									{detail.NombreUsuario && (
										<TableRow>
											<TableHead>Usuario</TableHead>
											<TableCell>{detail.NombreUsuario}</TableCell>
										</TableRow>
									)}
									{detail.RutUsuario && (
										<TableRow>
											<TableHead>RUT</TableHead>
											<TableCell>{detail.RutUsuario}</TableCell>
										</TableRow>
									)}
									{detail.CodigoUsuario && (
										<TableRow>
											<TableHead>Código</TableHead>
											<TableCell>{detail.CodigoUsuario}</TableCell>
										</TableRow>
									)}
									{detail.CargoUsuario && (
										<TableRow>
											<TableHead>Cargo</TableHead>
											<TableCell>{detail.CargoUsuario}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</CollapsibleContent>
					</Collapsible>
					<Separator />
				</div>
			</CardContent>
		</Card>
	);
}
