import React, { useState } from "react";

import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "../../../ui/alert"

import { 
	Dialog, 
	DialogContent, 
	DialogDescription, 
	DialogHeader, 
	DialogTitle, 
	DialogTrigger,
	DialogFooter
} from "../../../ui/dialog";

import { Separator } from "../../../ui/separator";

import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";
import { Input } from "src/components/ui/input";
import { GrCircleInformation } from "react-icons/gr";
import { z } from "zod"
import { useForm } from "react-hook-form";
import { fetchRutData, postRutData } from "src/utils/FormEmpresas";
import { cleanRut, validateRut } from 'rutlib';
import { Icons } from "src/components/ui/icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function EmpresaDialog() {

	const [isFormUnlocked, setIsFormUnlocked] = useState(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [showAlert, setShowAlert] = useState(false);
	const [rutAlert, setRutAlert] = useState(false);
	const [rutInvalidAlert, setRutInvalidAlert] = useState(false);

	const navigate = useNavigate();
	
	const RutSchema = z.object({
		rut: z.string().min(8).max(10),
	});

	const [ActivityInfo, setActivityInfo] = useState<{ codigo: string; giro: string; afecta: boolean }[] | undefined>(undefined);

	const [BusinesDetails, setBusinesDetails] = useState<{ razon_social: string; fecha_inicio_actividades: string; inicio_actividades: string } | undefined>(undefined);

	const { handleSubmit: handleSubmitRut, register: registerRut, formState: { errors: errorsRut } } = useForm<z.infer<typeof RutSchema>>();

	const rutSubmit = async (data: z.infer<typeof RutSchema>) => {
		try {
			setIsLoading(true)
			const rut = validateRut(data.rut);
			const finalrut = cleanRut(data.rut)

			if (rut === true) {
				
				const result = await fetchRutData(finalrut);
				const actividades = result.data.situacion_tributaria.actividades
				setActivityInfo(actividades);
				const businessdetails = result.data.situacion_tributaria
				setBusinesDetails(businessdetails);
				setShowAlert(false)

				if (result.status === 200) {
					
					setRutAlert(false)
					setIsLoading(false)
					sessionStorage.setItem('rut', finalrut);
					setIsFormUnlocked(true);

				} else {

					setIsLoading(false)
					setIsFormUnlocked(false);
					setShowAlert(true);

				}

			} else {
 
				setRutAlert(true)
				setIsLoading(false)

			}
		} catch (error) {
			console.error("Error al enviar la solicitud:", error);
		}
	};

	const dataSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			setIsLoading(true)
			const rut = sessionStorage.getItem('rut');
			const finalrut = rut ? cleanRut(rut) : '';
			const dataSubmit = {
				rut: finalrut,
				tipo_usuario: 'empresa',
				razon_social: BusinesDetails?.razon_social,
				fecha_inicio_actividades: BusinesDetails?.fecha_inicio_actividades,
				inicio_actividades: BusinesDetails?.inicio_actividades,
				actividades: ActivityInfo,
			};
			const result = await postRutData(dataSubmit);

			if (result.status === 200) {
				setIsLoading(false)
				navigate('/onboarding/validate-company')
			} else if (result.status !== 200 && result.message === "Rut no corresponde a una empresa") {
				setIsLoading(false)
				setRutInvalidAlert(true);
			} else {
				setIsLoading(false)
				navigate('/onboarding/validate-company-nok')
			}
		} catch (error) {
			console.error("Error al enviar la solicitud:", error);
		}
	};

	return (
		<Dialog>
			<DialogTrigger className="rounded-r-lg flex items-center bg-gray-600 p-10" asChild>
				<div className="space-x-1">
					<Button className="p-10 border-0 space-x-3" variant="secondary">Cuenta <br /> Empresa
						<GrCircleInformation size={24} />
					</Button>
				</div>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>
						Validemos tu cuenta de empresa
					</DialogTitle>
					<DialogDescription>
						Para comenzar debes ingresar el rut de tu empresa y revisar los datos asociados a tu rut, a continuación 
						validaremos el dominio de tu correo con el de la empresa.
					</DialogDescription>
				</DialogHeader>
				<form onSubmit={handleSubmitRut(rutSubmit)}>
					<div className="grid gap-4 py-4">
						<div className="flex flex-col items-start gap-4">
							<Label>Rut de empresa</Label>
							<Input 
								disabled={isLoading}
								id="rut"
								placeholder="12345678-9"
								{...registerRut("rut")} // Register the input field with react-hook-form
								className="col-span-3"
							/>
							{rutAlert && (
								<Alert variant="destructive">
									<AlertTitle>Rut Inválido</AlertTitle>
									<AlertDescription>
										Completa con un rut valido
									</AlertDescription>
								</Alert>
							)}
							{errorsRut.rut && <p className="text-red-500">{errorsRut.rut.message}</p>} {/* Display error message if validation fails */}
						</div>
					</div>
					<Button type="submit" disabled={isLoading}>
						{isLoading ? <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" /> : <AiOutlineCheckCircle className="mr-2" />}
						{!isLoading && 'Validar'}
					</Button>
					<Separator className="mt-2 mb-2" />
				</form>
				{showAlert && (
					<Alert variant="destructive">
						<AlertDescription>
							Hubo un problema al validar el RUT, inténtalo nuevamente.
						</AlertDescription>
					</Alert>
				)}
				<form onSubmit={dataSubmit}>
					<table className="table-auto min-w-full">
						<thead>
							{ActivityInfo && Object.keys(ActivityInfo).length !== 0 && (
								<tr>
									<th className="px-4 py-2">Código</th>
									<th className="px-4 py-2 flex justify-start align-center">Actividad</th>
									<th className="px-4 py-2">Afecta IVA</th>
								</tr>
							)}
						</thead>
						<tbody>
							{ActivityInfo?.map((item, index) => (
								<tr key={index}>
									<td className="border px-4 py-2">{item.codigo}</td>
									<td className="border px-4 py-2">{item.giro}</td>
									<td className="border px-4 py-2">{item.afecta ? 'si' : 'no'}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className={`transition-opacity ${isFormUnlocked ? 'opacity-100 pointer-events-auto' : 'opacity-40 pointer-events-none'}`}>
						<div>
							<div className="grid gap-4 py-4">
								<div className="flex flex-col items-start gap-4">
									<Label>Razon Social</Label>
									<Input
										id="razon_social"
										placeholder="Razon Social S.A."
										className="col-span-3"
										defaultValue={BusinesDetails?.razon_social ?? ''}
										readOnly
									/>
								</div>
								<div className="grid grid-cols-3 gap-2 items-start">
									<div className="col-span-1">
										<Label className="col-span-1">
											Inicio de Actividades
										</Label>
										<Input
											id="inicio_actividades"
											placeholder=""
											className="col-span-1"
											defaultValue={BusinesDetails?.inicio_actividades ?? ''}
											readOnly
										/>
									</div>
									<div className="col-span-2">
										<Label className="col-span-2">
											Fecha Inicio de Actividades
										</Label>
										<Input
											id="fecha_inicio_actividades"
											placeholder="dd-mm-aaaa"
											className="col-span-2"
											defaultValue={BusinesDetails?.fecha_inicio_actividades ?? ''}
											readOnly
										/>
									</div>
								</div>
							</div>
							{rutInvalidAlert && (
								<Alert className="my-5" variant={"destructive"}>
									<AlertDescription>
										El rut ingresado no corresponde a una empresa.
									</AlertDescription>
								</Alert>
							)}
						</div>
					</div>
					<Separator className="mt-2 mb-2" />
					<DialogFooter>
						<Button variant="default" type="submit" disabled={!isFormUnlocked} className="float-right">
							{!isLoading && 'Continuar'}
							{isLoading ? <Icons.Spinner className="ml-2 h-4 w-4 animate-spin" /> : <AiOutlineArrowRight className="ml-2" />}
						</Button>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
}