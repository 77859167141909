import { Badge } from "src/components/ui/badge";

import {
	Card,
	CardContent,
	CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { LicitacionDetailCompleto } from 'src/types/Types';

function getVariant(estado: string) {
	switch (estado) {
		case "Cerrada":
			return "destructive";
		case "Adjudicada":
			return "warning";
		case "Desierta":
			return "failed";
		case "Revocada":
			return "failed";
		case "Suspendida":
			return "warning";
		case "Publicada":
			return "success";
		case "OC Emitida":
			return "destructive";
		case "Cancelada":
			return "canceled";
		case "Desierta (o art. 3 ó 9 Ley 19.886)":
			return "failed";
		default:
			return "default";
	}
}

export default function ResumenCard( {detail, className}: { detail: LicitacionDetailCompleto, className?: string } ) {

	const variant = getVariant(detail.Estado);

	return(
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					{detail.Nombre}
				</CardTitle>
				<CardDescription className="text-lg">
					Código: {detail.CodigoExterno}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col space-y-5">
					<div>
						<p className="text-sm font-semibold">Tipo</p>
						<p className="text-lg font-semibold text-muted-foreground">({detail.Tipo}) {detail.TipoDescripcion}</p>
					</div>
					<div>
						<p className="text-sm font-semibold">Descripción</p>
						<p className="text-base font-semibold text-muted-foreground">{detail.Descripcion}</p>
					</div>
					<div>
						<Badge variant={variant}>{detail.Estado}</Badge>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
