import axios from "axios"
const baseurl = process.env.REACT_APP_BACKEND_URL ?? '';

const axiosInstance = axios.create({
	baseURL: baseurl,
});
axiosInstance.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';
	
	
	return config;
});

const recommenderLicit = async () =>{
	const response = await axiosInstance.get('licitaciones/recommender')
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

export {
	recommenderLicit
}