import { Separator } from "src/components/ui/separator"
import { BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';
import Copyright from "src/components/Copyright";

const Footer = () => {

	return (
		<footer className="container">
			<div className="flex flex-row text-center justify-end">
				{/* <div className="category flex flex-col m-8">
					<h2>Categoría 1</h2>
					<ul className="my-2 ml-2 [&>li]:mt-2">
						<li>Item 1</li>
						<li>Item 2</li>
						<li>Item 3</li>
					</ul>
				</div>
				<div className="category flex flex-col m-8">
					<h2>Categoría 2</h2>
					<ul className="my-2 ml-2 [&>li]:mt-2">
						<li>Item 1</li>
						<li>Item 2</li>
						<li>Item 3</li>
					</ul>
				</div> */}
			</div>
			<Separator />
			<div className="flex flex-col text-center justify-between m-8">
				<div className="flex items-center text-center justify-start">
					<Copyright />
				</div>
				<div className="flex items-center text-center justify-start space-x-4 mt-4">
					<BsFacebook className="mb-8" />
					<BsGithub className="mb-8" />
					<BsInstagram className="mb-8" />
					<BsTwitter className="mb-8" />
				</div>
			</div>
		</footer>
	);
}

export default Footer;