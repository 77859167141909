import * as React from "react";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "src/components/ui/dialog";

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui/button"
import { BadgeCheck, SendHorizontal } from 'lucide-react';
import { submitRequest } from "src/utils/FormEmpresas";
import { useForm } from "react-hook-form";
import { cleanRut, validateRut } from 'rutlib';
import { Input } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import { useNavigate } from "react-router-dom";

import { useToast } from "src/components/ui/use-toast"
import { useMutation } from "@tanstack/react-query";

export default function DialogFormClient() {

	const mutation = useMutation({
		mutationFn: (formData: FormData) => submitRequest(formData),
		onMutate: () => {
			toast({
				title: "Cargando...",
				description: "Enviando solicitud a nuestros servidores.",
			});
		},
		onSuccess: () => {
			toast({
				title: "Enviado",
				description: "Tu solicitud ha sido enviada, te enviaremos un correo con los siguientes pasos.",
			});
			navigate("/");
		},
	});
	
	const navigate = useNavigate();
	const { toast } = useToast();

	const phoneRegex = new RegExp(
		/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
	);

	const loginSchema = z.object({
		rut: z
			.string()
			.min(8, {
				message: "Por favor ingresa un RUT válido.",
			})
			.max(15, {
				message: "El RUT no puede ser mayor a 15 caracteres.",
			}),
		nombre: z
			.string()
			.min(1, {
				message: "Por favor ingresa tu nombre.",
			})
			.max(100, {
				message: "El nombre no puede ser mayor a 100 caracteres.",
			}),
		celular: z
			.string()
			.min(1, {
				message: "Por favor ingresa tu número de celular.",
			})
			.max(20, {
				message: "El número de celular no puede ser mayor a 20 caracteres.",
			})
			.regex(phoneRegex, {
				message: "Por favor ingresa un número de celular válido.",
			}),
		mensaje: z
			.string()
			.min(1, {
				message: "Por favor ingresa un mensaje.",
			})
			.max(255, {
				message: "El mensaje no puede ser mayor a 255 caracteres.",
			}),
		adjunto: z
			.instanceof(File)
			.optional()
			.nullable(),
	});

	const form = useForm<z.infer<typeof loginSchema>>({
		resolver: zodResolver(loginSchema),
		defaultValues: {
			rut: "",
			nombre: "",
			celular: "",
			mensaje: "",
			adjunto: null,
		},
	});

	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] ?? null;
		setSelectedFile(file);
	}

	const onSubmit = (data: z.infer<typeof loginSchema>) => {
		if (validateRut(data.rut) === false) {
			toast({
				title: "RUT inválido",
				description: "Por favor ingresa un RUT válido.",
			})
			return;
		}

		const finalrut = cleanRut(data.rut)
		const formData = new FormData();
		formData.append('rut', finalrut);
		formData.append('nombre', data.nombre);
		formData.append('celular', data.celular);
		formData.append('mensaje', data.mensaje);
		if (selectedFile) {
			formData.append('adjunto', selectedFile);
		}
		mutation.mutate(formData);
	};

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="default">
					Crear solicitud
					<BadgeCheck className="ml-2" size={18} />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Ingresa solicitud para crear tu cuenta</DialogTitle>
					<DialogDescription>
						Ingresa los datos solicitados en el formulario, te enviaremos un correo con los siguientes pasos.
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
						<FormField
							control={form.control}
							name="rut"
							render={({ field }) => (
								<FormItem>
									<FormLabel>RUT de la empresa</FormLabel>
									<FormControl>
										<Input id="rut" {...field} />
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="nombre"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Nombre del solicitante</FormLabel>
									<FormControl>
										<Input id="nombre" type="text" {...field} />
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="celular"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Celular</FormLabel>
									<FormControl>
										<Input id="celular" type="tel" placeholder="+569..." {...field} />
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="mensaje"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Mensaje</FormLabel>
									<FormControl>
										<Textarea 
											id="mensaje"
											placeholder="Ingresa un mensaje para justificar la solicitud, te podremos pedir más información si es necesario."
											{...field} />
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="adjunto"
							render={({ field }) => (
								<FormItem className="flex flex-col items-center">
									<FormControl>
									<Input 
										id="adjunto" 
										className="cursor-pointer" 
										disabled={mutation.isPending} 
										type="file" 
										onChange={handleFileChange}
										value={undefined}
									/>
									</FormControl>
									<FormMessage />
									{mutation.isError && (
										<FormMessage>
											{(mutation.error as any)?.response?.data?.detail?.files?.adjunto[0] ?? "Ha ocurrido un error al enviar tu solicitud, por favor intenta nuevamente."}
										</FormMessage>
									)}
									<FormLabel>Adjunta un archivo que nos sirva para revisar tu solicitud, formatos aceptados: pdf, doc, docx (opcional)</FormLabel>
								</FormItem>
							)}
						/>
						<Button type="submit" disabled={mutation.isPending}>
							Enviar
							<SendHorizontal className="ml-2" size={18} />
						</Button>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}