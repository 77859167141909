import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "src/components/ui/tabs"

import Equipo from "./components/Equipo"
import Perfil from "./components/Perfil"
import Facturacion from "./components/Facturacion"
import Configuracion from "./components/Configuracion"

export default function Usuario() {

	return (
		<>
			<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl m-5 justify-left">
				Perfil de Usuario
			</h1>
			<Tabs defaultValue="perfil" orientation="vertical">
				<TabsList>
					<TabsTrigger value="perfil">Mi Perfil</TabsTrigger>
					<TabsTrigger value="equipo">Mi Equipo</TabsTrigger>
					<TabsTrigger value="facturacion">Plan y Facturación</TabsTrigger>
					<TabsTrigger value="configuracion">Configuración</TabsTrigger>
				</TabsList>
				<TabsContent value="perfil">
					<Perfil />
				</TabsContent>
				<TabsContent value="equipo">
					<Equipo />
				</TabsContent>
				<TabsContent value="facturacion">
					<Facturacion />
				</TabsContent>
				<TabsContent value="configuracion">
					<Configuracion />
				</TabsContent>				
			</Tabs>
		</>
	);
}