import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	// TableFooter,
	TableHead,
	// TableHeader,
	TableRow,
} from "src/components/ui/table"

import { Separator } from "src/components/ui/separator"

import { LicitacionDetailCompleto } from 'src/types/Types';

export default function PresupuestoCard( {detail, className}: {detail: LicitacionDetailCompleto, className?: string} ) {

	const nf = new Intl.NumberFormat("es-CL", {
		style: "currency",
		currency: "CLP",
	})

	return(
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Presupuesto
				</CardTitle>
			</CardHeader>
			<CardContent>
				{detail.VisibilidadMonto === 1 ? (
					<div className="grid grid-cols-3 gap-4">
						<div className="col-span-2">
							<p className="text-sm font-semibold">MontoEstimado</p>
							<h1 className="scroll-m-20 text-3xl tracking-tight">{nf.format(detail.MontoEstimado)}</h1>
						</div>
						<div>
							<p className="text-sm font-semibold">Moneda</p>
							<p className="text-lg font-semibold text-muted-foreground">{detail.Moneda}</p>
						</div>
					</div>
				) : (
					<p className="text-lg font-semibold text-muted-foreground">Monto no informado.</p>
				)}
				{detail.JustificacionMontoEstimado && (
					<div className="mt-4">
						<p className="text-sm font-semibold">Justificación</p>
						<p className="text-sm font-semibold text-muted-foreground">{detail.JustificacionMontoEstimado}</p>
					</div>
				)}
				<Separator className="mt-2 mb-2"/>
				<h2 className="text-lg font-semibold">Pago</h2>
				<div>
					<Table>
						<TableBody>
							<TableRow>
								<TableHead>Modalidad de Pago</TableHead>
								<TableCell>{detail.Modalidad_Pago}</TableCell>
							</TableRow>
							<TableRow>
								<TableHead>Responsable</TableHead>
								<TableCell>{detail.NombreResponsablePago}</TableCell>
							</TableRow>
							{detail.EmailResponsablePago && (
								<TableRow>
									<TableHead>Email</TableHead>
									<TableCell><a href={`mailto:${detail.EmailResponsablePago}`}>{detail.EmailResponsablePago}</a></TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
				<Separator className="mt-2 mb-2"/>
				<h2 className="text-lg font-semibold">Contrato</h2>
				<div>
					<Table>
						<TableBody>
							<TableRow>
								<TableHead>Permite Subcontratación</TableHead>
								<TableCell>{detail.SubContratacion === 1 ? "Sí" : "No"}</TableCell>
							</TableRow>
							{detail.ProhibicionContratacion && (
								<TableRow>
									<TableHead>Prohibición de Contratación</TableHead>
									<TableCell>{detail.ProhibicionContratacion}</TableCell>
								</TableRow>
							)}
							{detail.TiempoDuracionContrato !== 0 && (
								<TableRow>
									<TableHead>Duración del Contrato</TableHead>
									<TableCell>{detail.TiempoDuracionContrato} {detail.UnidadTiempoDuracionContratoDescripcion}</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</CardContent>
		</Card>
	);
}
