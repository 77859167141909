import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "src/components/ui/button";

import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	FormDescription
} from "src/components/ui/form";
import { editProfile } from "src/utils/UsersAuth";
import { useAuth } from "src/components/AuthContext";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/card";

import { Label } from "src/components/ui/label";

import { Checkbox } from "src/components/ui/checkbox";

import { Upload } from 'lucide-react';

import React, { useEffect, useRef } from "react";
import { Input } from "src/components/ui/input";
import { ProfileItem } from "src/types/Types";

import { useToast } from "src/components/ui/use-toast"

import { useMutation, useQueryClient } from "@tanstack/react-query";

const UserSchema = z.object({
	family_name: z
		.string()
		.min(3, { message: "Por favor ingresa un Apellido valido." })
		.max(50, { message: "El apellido no puede tener mas de 50 caracteres." }),
	given_name: z
		.string()
		.min(2, { message: "Por favor ingresa un Nombre valido." })
		.max(50, { message: "El nombre no puede tener mas de 50 caracteres." }),
	name: z
		.string()
		.min(2, { message: "Por favor ingresa un nombre de usuario valido." })
		.max(50, { message: "El nombre de usuario no puede tener mas de 50 caracteres." }),
	picture: z
		.string()
});

export default function Perfil() {

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: (formData: FormData) => editProfile(formData),
		onMutate: () => {
			toast({
				title: "Actualizando",
				description: "Actualizando tu perfil en la plataforma.",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['profile'],
			});
			toast({
				title: "Perfil Actualizado",
				description: "Tu perfil ha sido actualizado, puedes seguir navegando."
			});
		},
		onError: () => {
			toast({
				title: "Error al Actualizar",
				description: "Hubo un error al intentar actualizar tu perfil, por favor intenta nuevamente.",
			});
		},
	});

	const { toast } = useToast();

	const auth = useAuth()
	const profile_data = auth?.profile as ProfileItem
	const [isFormModified, setIsFormModified] = React.useState<boolean>(false);

	const form = useForm<z.infer<typeof UserSchema>>({
		resolver: zodResolver(UserSchema),
		defaultValues: {
			family_name: profile_data.family_name,
			given_name: profile_data.given_name,
			name: profile_data.name,
			picture: profile_data.picture,
		},
	});

	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleImageClick = () => {
		fileInputRef.current?.click()
	}

	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				form.setValue('picture', reader.result as string);
			};
			reader.readAsDataURL(file);
		} else {
			setSelectedFile(null);
			form.setValue('picture', '');
		}
	};

	useEffect(() => {
		if (form.formState.isDirty || selectedFile) {
			setIsFormModified(true);
		} else {
			setIsFormModified(false);
		}
	}, [form.formState.isDirty, selectedFile]);

	const onSubmit = (data: z.infer<typeof UserSchema>) => {
		const formData = new FormData();
		formData.append('family_name', data.family_name);
		formData.append('given_name', data.given_name);
		formData.append('name', data.name);
		if (selectedFile) {
			formData.append('picture', selectedFile);
		}
		if (data.picture === profile_data.picture) {
			data.picture = "";
		}
		mutation.mutate(formData);
	};
	
	return (
		<Card>
			<CardHeader>
				<CardTitle>Gestiona tus preferencias de perfil</CardTitle>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="grid md:grid-cols-3">
							<div className="flex flex-col md:justify-start space-y-10 md:grid-cols-1">
								<FormField
									control={form.control}
									name="picture"
									render={({ field }) => (
										<FormItem className="flex flex-col items-center">
											<FormControl>
												<>
													<input
														type="file"
														ref={fileInputRef}
														className="hidden"
														onChange={handleFileChange}
													/>
													<img
														src={field.value}
														alt="Imagen de Perfil"
														className="object-cover object-center w-60 h-60 rounded-full shadow-md cursor-pointer hover:opacity-80"
														onClick={handleImageClick}
													/>
													<Input id="picture" type="hidden" {...field} disabled={mutation.isPending} />
												</>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<h1 className="text-lg text-center text-muted-foreground font-semibold tracking-tight">
									{profile_data.email}
								</h1>
								<Label className="text-center text-muted-foreground">
									<Checkbox
										checked={profile_data.email_verified}
										disabled
										className="mr-2"
									/>
									{profile_data.email_verified ? "Email Verificado" : "Email No Verificado"}
								</Label>
							</div>
							<div className="flex flex-col gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="family_name"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Apellidos</FormLabel>
											<FormControl>
												<Input id="family_name" type="text"  {...field} disabled={mutation.isPending} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="given_name"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Nombre</FormLabel>
											<FormControl>
												<Input id="given_name" type="text" {...field} disabled={mutation.isPending} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Alias de Usuario</FormLabel>
											<FormControl>
												<Input id="name" type="text" {...field} disabled={mutation.isPending} />
											</FormControl>
											<FormMessage />
											<FormDescription>
												El alias de usuario es el nombre que se muestra en la plataforma.
											</FormDescription>
										</FormItem>
									)}
								/>
							</div>
						</div>
						<div className="flex py-2 justify-end w-full">
							<Button
								variant="default"
								type="submit"
								size="lg"
								disabled={mutation.isPending || !isFormModified}
							>
								Actualizar Perfil
								<Upload className="ml-2 h-5 w-5" />
							</Button>
						</div>
						<div className="flex py-2 justify-end w-full">
							<Button
								variant="destructive"
								disabled
							>
								Eliminar Perfil
							</Button>
						</div>
					</form>
				</Form>
			</CardContent>
		</Card>
	);
}
