const Copyright = () => {
	
	const version = process.env.REACT_APP_VERSION || 'latest';

	return (
		<p className="text-sm text-muted-foreground text-center">
			{'Copyright © '}
			<a color="inherit" href="https://www.licitaeasy.cl/">
				Licita Easy
			</a>{' '}
			{new Date().getFullYear()}
			{'.'}
			{' Version: '}
			{version}
		</p>
		);
	}

export default Copyright;