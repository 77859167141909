import React from "react"
import { useForm } from "react-hook-form"
import { useState } from 'react';
import { Icons } from "src/components/ui/icons"
import { Button } from "src/components/ui/button"

import { Fade, Slide } from "react-awesome-reveal";

import { 
	Alert, 
	AlertDescription, 
	// AlertTitle 
} from "src/components/ui/alert"

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { Card, CardContent } from "src/components/ui/card"

import { Input } from "src/components/ui/input"
import { Textarea } from "src/components/ui/textarea";

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { submitContactForm } from 'src/utils/Users';

import { Mail } from 'lucide-react'

const Contacto = () => {

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});

	const phoneRegex = new RegExp(
		/^\+?[\d\s]{10,}$/
	);
	
	const FormSchema = z.object({
	nombre: z
		.string()
		.min(1, {
			message: "Por favor ingresa tu nombre.",
		})
		.max(255, {
			message: "El nombre no puede ser mayor a 255 caracteres.",
		}),
	email: z
		.string()
		.min(1, {
			message: "Por favor ingresa tu correo.",
		})
		.max(255, {
			message: "El correo no puede ser mayor a 255 caracteres.",
		})
		.email({
			message: "Por favor ingresa un email válido.",
		}),
	celular: z
		.string()
		.min(1, {
			message: "Por favor ingresa tu número de celular.",
		})
		.max(20, {
			message: "El número de celular no puede ser mayor a 20 caracteres.",
		})
		.regex(phoneRegex, {
			message: "Por favor ingresa un número de celular válido.",
		}),
	mensaje: z
		.string()
		.min(1, {
			message: "Por favor ingresa tu mensaje.",
		})
		.max(500, {
			message: "El mensaje no puede ser mayor a 500 caracteres.",
		}),
	})

	const form = useForm<z.infer<typeof FormSchema>>({
	resolver: zodResolver(FormSchema),
	defaultValues: {
		nombre: "",
		email: "",
		celular: "",
		mensaje: "",
	},
	})

	async function onSubmit(data: z.infer<typeof FormSchema>) {
		setIsLoading(true);
		const result = await submitContactForm(data.nombre, data.email, data.celular, data.mensaje);
		setApiResponse(result);
		setIsLoading(false);
	}

	return (
		<Fade triggerOnce>
			<div className="container space-y-6 mb-10 flex flex-col justify-center items-center">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">Contacto</h1>
					<h1 className="scroll-m-20 font-extrabold tracking-tight lg:text-2xl">Envíamos un mensaje y te contactaremos en breve</h1>
				</div>
				<Slide direction="right" duration={1000} triggerOnce>
					<Card className='bg-background/80'>
						<CardContent>
							<div className="flex flex-col space-y-4">
							<Form {...form}>
								<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 m-8">
									<FormField
										control={form.control}
										name="nombre"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Nombre</FormLabel>
												<FormControl>
													<Input id="nombre" type="text" {...field} />
												</FormControl>
												<FormMessage/>
											</FormItem>
										)}
									/>
									<div className="flex flex-row space-x-4">
										<FormField
											control={form.control}
											name="email"
											render={({ field }) => (
												<FormItem className="flex-1">
													<FormLabel>Email</FormLabel>
													<FormControl>
														<Input id="email" type="email" {...field} />
													</FormControl>
													<FormMessage/>
												</FormItem>
											)}
										/>
										<FormField
											control={form.control}
											name="celular"
											render={({ field }) => (
												<FormItem className="flex-1">
													<FormLabel>Celular</FormLabel>
													<FormControl>
														<Input id="celular" type="text" {...field} />
													</FormControl>
													<FormMessage/>
												</FormItem>
											)}
										/>
									</div>
									<FormField
										control={form.control}
										name="mensaje"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Mensaje</FormLabel>
												<FormControl>
													<Textarea id="mensaje" {...field} />
												</FormControl>
												<FormMessage/>
											</FormItem>
										)}
									/>
									<div className="flex justify-center w-full">
										<Button type="submit" disabled={isLoading} className="w-80">
										{isLoading ? (
											<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
										) : (
											<Mail className="mr-2 h-4 w-4" />
										)}{" "}
											Enviar
										</Button>
									</div>
									{apiResponse.status && (
										<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
											<AlertDescription>
												{apiResponse.message}
											</AlertDescription>
										</Alert>
									)}
								</form>
							</Form>
						</div>
					</CardContent>
				</Card>
			</Slide>
		</div>
	</Fade>
  )
}  

export default Contacto;