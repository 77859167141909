import { FC } from 'react';

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { Pointer, Speech, FolderCheck, Fingerprint, BrainCircuit } from 'lucide-react';

import { Fade } from "react-awesome-reveal";

interface CardProps {
	Icon: FC;
	title: string;
	description: string;
}

const CustomCard: FC<CardProps> = ({ Icon, title, description }) => {

	return (
		<Card className='flex flex-wrap mx-auto w-[400px] h-auto m-3 bg-secondary/65'>
			<CardHeader>
				<div className="flex justify-left">
					<Icon />
				</div>
			</CardHeader>
			<CardContent>
				<CardTitle>
					{title}
				</CardTitle>
				<CardDescription className="mt-4 text-base">
					{description}
				</CardDescription>
			</CardContent>
		</Card>
	);
}

export default function Customers() {

	return (
		<div className="container py-8 py-md-11 border-top mt-20">
			<Fade triggerOnce>
				<div className="text-center">
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">Características Principales</h1>
					<p className="text-2xl">Que hace de nuestra plataforma la mejor opción para ti</p>
				</div>
				<div className="flex flex-wrap mt-10 justify-center">
					<Fade cascade direction="up" duration={400} triggerOnce>
						<CustomCard 
							Icon={Pointer}
							title="Usabilidad" 
							description="Una plataforma de fácil acceso y uso, con un diseño intuitivo y amigable, nos esforzamos por hacer que tu experiencia sea lo más sencilla posible."
						/>
						<CustomCard 
							Icon={BrainCircuit}
							title="Tecnología" 
							description="Utilizamos tecnología de punta, para ofrecerte un servicio de calidad, con las últimas tendencias en inteligencia artificial y machine learning. Nos mantenemos a la vanguardia para ofrecerte lo mejor."
						/>
						<CustomCard 
							Icon={FolderCheck}
							title="Transparencia"
							description="Te ofrecemos la información que necesitas de forma clara y concisa, no tenemos letra pequeña, ni costos ocultos, todo es transparente."
						/>
						<CustomCard 
							Icon={Speech}
							title="Soporte"
							description="Contamos con un equipo de soporte técnico altamente capacitado, dispuesto a ayudarte en cualquier momento."
						/>
						<CustomCard 
							Icon={Fingerprint}
							title="Seguridad"
							description="La seguridad de tu información es nuestra prioridad, por eso contamos con los más altos estándares de en el manejo de los datos de tu empresa."
						/>
					</Fade>
				</div>
			</Fade>
		</div>
	);
}