import { Route, Routes } from 'react-router-dom';

import Landing from './Landing';
import WorkInProgress from './WorkInProgress';
import UserLayout from './User/UserLayout';
import Register from './User/Register';
import RegisterForm from './User/Register/components/RegisterForm';
import LoginForm from './User/Login';
import OnBoarding from './OnBoarding';
import ForgotPassword from './User/ForgotPassword';
import ResetPassword from './User/ResetPassword';
import ConfirmEmail from './User/ConfirmEmail';
import AppLayout from './App/AppLayout';
import ExpandeNegocio  from './App/ExpandeNegocio';
import AnaliticaReportes from './App/AnaliticaReportes';
import AnaliticaKpi from './App/AnaliticaKpi';
import Similares from './App/Similares';
import Home from './App';
import Usuario from './App/Usuario';
import LicitDetail from "./App/LicitDetail"
import LicitChat from "./App/LicitChat"
import Seguidos from './App/Seguidos';
import Calendar from './App/Calendar';
import Recommender from "./App/Recommender"
import BusquedasGuardadas from './App/BusquedasGuardadas';
import BusquedaInteligente from './App/BusquedaInteligente';
import ValidateCompany from './OnBoarding/components/ValidateCompany/ValidateCompany';
import OnBoardingLayout from "./OnBoarding/OnBoardingLayout"
import ValidationforNok from './OnBoarding/components/ValidationforNok';
import Form from './OnBoarding/components/Form/Form';
import BusquedaId from './App/BusquedaDirecta';

const AppRoutes = () => {
	return (

		<Routes>
			<Route path="/*" element={<WorkInProgress />} />
			<Route path="/" element={<Landing />} />

			<Route element={<UserLayout />} >
				<Route path="/login" element={<LoginForm />} />
				<Route path="/register" element={<Register />} />
				<Route path="/registerform" element={<RegisterForm />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/forgot-password/:token" element={<ResetPassword />} />
				<Route path="/confirm-email/:token" element={<ConfirmEmail />} />
			</Route>

			<Route element={<AppLayout />} >
				<Route path='/home' element={<Home />} />
				<Route path='/perfil' element={<Usuario />} />
				<Route path="/seguimiento/calendario" element={<Calendar />} />
				<Route path="/seguimiento/calendario/:codigo" element={<LicitDetail />} />
				<Route path="/seguimiento/calendario/:codigo/chat" element={<LicitChat />} />
				<Route path="/seguimiento/licitaciones" element={<Seguidos />} />
				<Route path="/seguimiento/licitaciones/:codigo" element={<LicitDetail />} />
				<Route path="/seguimiento/licitaciones/:codigo/chat" element={<LicitChat />} />
				<Route path='/descubre/recomendador' element={<Recommender/>} />
				<Route path="/descubre/recomendador/:codigo" element={<LicitDetail />} />
				<Route path="/descubre/recomendador/:codigo/chat" element={<LicitChat />} />
				<Route path="/descubre/expande" element={<ExpandeNegocio />} />
				<Route path="/explora/guardadas" element={<BusquedasGuardadas />} />
				<Route path="/explora/guardadas/:codigo" element={<LicitDetail />} />
				<Route path="/explora/guardadas/:codigo/chat" element={<LicitChat />} />
				<Route path='/explora/busqueda' element={<BusquedaInteligente/>} />
				<Route path='/explora/busqueda/:codigo' element={<LicitDetail/>} />
				<Route path="/explora/busqueda/:codigo/chat" element={<LicitChat />} />
				<Route path='/explora/directa' element={<BusquedaId/>} />
				<Route path="/explora/similares" element={<Similares/>}/>
				<Route path="/analitica/graficos" element={<AnaliticaKpi/>}/>
				<Route path="/analitica/reportes" element={<AnaliticaReportes/>}/>
			</Route>

			<Route element={<OnBoardingLayout/>}>
				<Route path="/onboarding" element={<OnBoarding />} />
				<Route path='/onboarding/validate-company' element={<ValidateCompany/>} />
				<Route path='/onboarding/validate-company-nok' element={<ValidationforNok/>} />
				<Route path='/onboarding/form' element={<Form/>} />
			</Route>
		</Routes>
	);
}

export default AppRoutes;
