import { useState } from "react";

import {
	Card,
	CardTitle,
	CardHeader,
	CardDescription,
	CardContent,
} from "src/components/ui/card";

import { EditIcon, Trash2 } from "lucide-react";

import { Skeleton } from "src/components/ui/skeleton"

import { fetchBusquedaInteligente, fetchBusquedaPorID } from "src/utils/Models";

import { deleteBusquedaGuardada, updateBusquedaGuardada } from "src/utils/BusquedasGuardadas";

import { ScrollBar, ScrollArea } from "src/components/ui/scroll-area";

import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";

import { Tooltip, TooltipContent, TooltipTrigger } from "src/components/ui/tooltip"

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { BusquedaGuardadaResponse, BusquedaResponse } from "src/types/Types";

import { useToast } from "src/components/ui/use-toast"

import DialogLicitacion from "src/components/App/DialogLicitacion";

function SkeletonCard() {
	return (
		<div className="flex flex-wrap items-center justify-center">
			<Skeleton className="h-80 w-60 m-1" />
			<Skeleton className="h-80 w-60 m-1" />
			<Skeleton className="h-80 w-60 m-1" />
			<Skeleton className="h-80 w-60 m-1" />
			<Skeleton className="h-80 w-60 m-1" />
			<Skeleton className="h-80 w-60 m-1" />
		</div>
	)
}

export default function BusquedaCard({ busqueda }: any) {

	const queryClient = useQueryClient();

	const { toast } = useToast();

	busqueda.top_k = 6;

	const queryRegex = new RegExp('^\\d{1,10}-\\d{1,10}-[A-Z0-9]{1,5}$');

	const queryResult = useQuery<BusquedaResponse>({
		queryKey: ['busqueda_inteligente', busqueda.query, busqueda.filtros, busqueda.top_k],
		queryFn: async () => {
			if (queryRegex.test(busqueda.query)) {
				const response = await fetchBusquedaPorID(busqueda.query, busqueda.filtros, busqueda.top_k);
				return response;
			} else {
				const response = await fetchBusquedaInteligente(busqueda.query, busqueda.filtros, busqueda.top_k);
				return response;
			}
		},
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!busqueda.query && busqueda.query.length > 0,
	})

	const resultados = queryResult.data?.data?.detalle || [];
	
	const mutation = useMutation({
		mutationFn: deleteBusquedaGuardada,
		onMutate: () => {
			toast({
				title: "Eliminando búsqueda",
				description: "Eliminando la búsqueda de tus guardadas.",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['busquedas'],
			});
			toast({
				title: "Búsqueda eliminada",
				description: "La búsqueda ha sido eliminada de tus guardadas.",
			});
			queryClient.setQueryData(
				['busquedas'],
				(prev: BusquedaGuardadaResponse) => {
					return {
						data: prev.data.busquedas.filter((item: any) => item.token !== busqueda.token),
					}
				}
			);
		},
		onError: () => {
			toast({
				title: "Error al eliminar",
				description: "No se pudo eliminar la búsqueda de tus guardadas.",
			});
		},
	});

	const mutationEdit = useMutation({
		mutationFn: updateBusquedaGuardada,
		onMutate: () => {
			toast({
				title: "Editando búsqueda",
				description: "Editando la búsqueda.",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['busquedas'],
			});
			toast({
				title: "Búsqueda editada",
				description: "La búsqueda ha sido editada.",
			});
			queryClient.setQueryData(
				['busquedas'],
				(prev: BusquedaGuardadaResponse) => {
					return {
						data: prev.data.busquedas.map((item: any) => {
							if (item.token === busqueda.token) {
								return {
									...item,
									query: busqueda.query,
								}
							}
							return item;
						}),
					}
				}
			);
		},
		onError: () => {
			toast({
				title: "Error al editar",
				description: "No se pudo editar la búsqueda.",
			});
		},
	});

	const [edit, setEdit] = useState(false);

	const triggerComponent = (resultado: any, index: number) => (
		<Card className="max-h-80 w-60 cursor-pointer select-none" key={index}>
			<ScrollArea>
				<CardHeader>
					<CardTitle className="text-sm line-clamp-4">
						<Badge className="mr-2">
							{resultado.Tipo}
						</Badge>
						{resultado.Nombre}
					</CardTitle>
					<CardDescription>
						{resultado.CodigoExterno}
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="text-sm line-clamp-6">
						{resultado.Descripcion}
					</div>
				</CardContent>
				<ScrollBar orientation="vertical" />
			</ScrollArea>
		</Card>
	)
	
	return (
		<Card className="w-full relative">
			<CardHeader>
				<CardTitle className="flex flex-row w-full">
					<Input
						className="text-xl font-primary border-none"
						type="text"
						defaultValue={busqueda.query}
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								mutationEdit.mutate({
									token: busqueda.token,
									query: e.currentTarget.value,
									filtros: busqueda.filtros,
								});
							}
						}}
						disabled={!edit}
					/>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button className="ml-2" variant="outline" size="sm" onClick={() => setEdit(!edit)}>
								<EditIcon />
							</Button>
						</TooltipTrigger>
						<TooltipContent side="bottom">
							Editar búsqueda
						</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button className="ml-2" variant="destructive" size="sm" onClick={() => mutation.mutate(busqueda.token)}>
								<Trash2 />
							</Button>
						</TooltipTrigger>
						<TooltipContent side="bottom">
							Eliminar búsqueda
						</TooltipContent>
					</Tooltip>
				</CardTitle>
				<CardDescription>
					Filtros: {JSON.stringify(busqueda.filtros)}
				</CardDescription>
			</CardHeader>
			<CardContent>
				{queryResult.isPending && <SkeletonCard />}
				{queryResult.isError && <div>Error: {String(queryResult.error)}</div>}
				<div className="flex flex-wrap items-center justify-center space-x-4">
					{resultados.map((resultado, index) => (
						<DialogLicitacion key={index} licitacion={resultado} triggerComponent={triggerComponent(resultado, index)} />								
					))}
				</div>
			</CardContent>
		</Card>
	)
}