import { Button } from 'src/components/ui/button';

import NavBar from './components/NavBar';
import Inicio from './components/Inicio';
// import Customers from './components/Customers';
import Problema from './components/Problema';
import Solucion from './components/Solucion';
import Caracteristicas from './components/Caracteristicas';
import Funcionamiento from './components/Funcionamiento';
import Precios from './components/Precios';
import CallToAction from './components/CallToAction';
import FAQ from './components/FAQ';
import Contacto from './components/Contacto';
import Footer from './components/Footer';

import { ScrollArea } from 'src/components/ui/scroll-area'

import { ArrowRight } from 'lucide-react'

import { Fade } from "react-awesome-reveal";

export default function Landing() {

	return (
		<div className="bg-background" style={{backgroundImage: 'url("gallery/Landing.png")', backgroundSize: 'cover', backgroundPosition: 'center'}}>
			<ScrollArea className="h-screen">
				<div className="flex-col mx-auto" id="Inicio">
					<NavBar />
					<section>
						<Inicio />
					</section>
					{/* <section>
						<Customers />
					</section> */}
					<section>
						<Problema />
					</section>
					<section>
						<Solucion />
					</section>
					<section id="Caracteristicas">
						<Caracteristicas />
					</section>
					<section>
						<Fade cascade>
							<section className="mt-5">
								<div className="container">
									<div className="text-center">
										<div className="p-5 rounded-3 mb-10">
											<div className="py-3">
												<h3 className="opacity-80 tracking-tight lg:text-3xl">¿LISTO PARA COMENZAR?</h3>
												<h2 className="tracking-tight lg:text-4xl text-sky-400">Únete para una prueba de un mes gratis!</h2>
											</div>
											<div className="flex-center d-flex">
												<a href="/login">
													<Button className="btn btn-info">Empezar<ArrowRight className="ml-1 h-4 w-4" /></Button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</section>
						</Fade>
					</section>
					<section className="mt-5">
						<Funcionamiento />
					</section>
					<section id="PlanesYPrecios">
						<Precios />
					</section>
					<section id="FAQ">
						<FAQ />
					</section>
					<section>
						<CallToAction />
					</section>
					<section id="Contacto">
						<Contacto />
					</section>
					<section className="bg-secondary/60">
						<Footer />
					</section>
				</div>
			</ScrollArea>
		</div>
	);
}