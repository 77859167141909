import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { TimelineChart } from "src/components/ui/timeline"

import { CompraAgilDetailCompleto } from 'src/types/Types';

import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function FechasCard( {detail}: {detail: CompraAgilDetailCompleto} ) {

	const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

	let data = useMemo(() => [
		{ date: Number(detail.fechaApertura), content: 'Fecha de Apertura', color: '#3498db' },
		{ date: Number(detail.fechaCierre), content: 'Fecha de Cierre', color: '#670b0f' },
	].filter(item => item.date), [detail]);
	
	return(
		<Card>
			<CardHeader>
				<CardTitle>
					Linea de Tiempo
				</CardTitle>
			</CardHeader>
			<CardContent>
				<TimelineChart data={data} isMobile={isMobile} />
			</CardContent>
		</Card>
	);
}