import { Fade } from "react-awesome-reveal";
import { Button } from "src/components/ui/button";

import { Card, CardContent } from "src/components/ui/card"

export default function Inicio() {

	return (
		<Fade triggerOnce>
			<div className="flex flex-wrap items-center justify-center mt-20 mb-20">
				<h1 className="scroll-m-10 text-4xl font-extrabold tracking-tight lg:text-5xl max-w-[600px] ml-20">
					¡Utiliza el poder de la Inteligencia Artificial para aprovechar las oportunidades del Mercado Público!
				</h1>
				<Card className="bg-secondary/65 min-w-96 max-w-[500px] mt-10">
					<CardContent>
						<p className="leading-7 mt-4 text-lg">
							Deja que los algoritmos hagan el trabajo mecánico por ti y enfócate en tareas más estratégicas y de mayor valor para tu negocio
						</p>
						<a href="#PlanesYPrecios">
							<Button variant='outline' className="mt-5 text-lg" size='lg'>
								Precios y Planes
							</Button>
						</a>
					</CardContent>
				</Card>
			</div>
			<section>
				<div className="container">
					<img className="img-fluid" src="gallery/dashboard.png" alt="Dashboard" />
				</div>
			</section>
		</Fade>
	);
}