import { Category, Item, RecommendationsResponse, Licitacion } from "../../../types/Types";
import DialogLicitacion from "../DialogLicitacion";
import { recommenderLicit } from "src/utils/Recomendador";

import { useQuery } from '@tanstack/react-query'

import Loading from "src/components/Loading";

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "src/components/ui/carousel"

import {
	Card,
	CardTitle,
	CardHeader,
	CardDescription,
	CardContent,
	CardFooter,
} from "src/components/ui/card";

import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "src/components/ui/table"

import { Badge } from "src/components/ui/badge";

export default function Recommender() {

	const { isPending, isError, data, error } = useQuery<RecommendationsResponse>({
		queryKey: ['recommendations'],
		queryFn: recommenderLicit,
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
	})

	if (isPending) return <Loading />

	if (isError) return <div>Error: {String(error)}</div>

	const recommendations = data?.data?.recommendations;

	const triggerComponent = (recommendation: Licitacion) => (
		<Card className="cursor-pointer select-none max-w-[450px]">
			<CardHeader>
				<CardTitle>{recommendation?.Nombre}</CardTitle>
				<CardDescription>{recommendation?.CodigoExterno}</CardDescription>
			</CardHeader>
			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>Estado</TableCell>
							<TableCell>{recommendation?.Estado}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monto Estimado</TableCell>
							<TableCell>{recommendation?.MontoEstimado}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Fecha de Cierre</TableCell>
							<TableCell>{recommendation?.FechaCierre && new Date(recommendation.FechaCierre).toLocaleDateString()}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			<CardFooter>
				<Badge variant="secondary">{recommendation?.Estado}</Badge>
			</CardFooter>
		</Card>
	)
	
	return (
		<>
			<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl mt-5 justify-left">
				Recomendaciones
			</h1>
			<div className="flex flex-col justify-center items-center">
				<div className="flex flex-col justify-center items-center mt-10">
					{recommendations?.map((category: Category, modelIndex: number) => (
						<div key={modelIndex} className="p-4">
							<h2 className="mt-10 scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
								{category.name}
							</h2>
							<h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
								{category.description}
							</h4>
							<div className="flex flex-col">
								{category.items.map((item: Item, itemIndex: number) => (
									<Carousel
										opts={{
											align: "start",
										}}
										key={itemIndex}
										className="md:w-10/12 mx-auto"
									>
										<h3 className="mt-8 scroll-m-20 text-2xl font-semibold tracking-tight mb-5">
											Referencia: {item.reference}
										</h3>
										<CarouselContent className="md:w-full w-[500px]">
											{item.recommendations.map((recommendation: Licitacion, recomendationIndex: number) => (
												<CarouselItem key={recomendationIndex} className="md:basis-1/3 basis-full">
													<div className="m-2">
														<DialogLicitacion licitacion={recommendation} triggerComponent={triggerComponent(recommendation)} key={recomendationIndex} />
													</div>
												</CarouselItem>
											))}
										</CarouselContent>
										<CarouselPrevious />
										<CarouselNext />
									</Carousel>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	)
}