import React, { useContext, createContext, useState } from "react";
import { ChevronFirst, PanelLeftClose } from "lucide-react";
import { useTheme } from "next-themes";
import iconConverter from "./IconConverter";
import { useAuth } from "src/components/AuthContext";

import { useNavigate, useLocation } from 'react-router-dom';

import { Tooltip, TooltipContent, TooltipTrigger } from "src/components/ui/tooltip"
import { Button } from "src/components/ui/button"
import { Separator } from "src/components/ui/separator"

const SidebarContext = createContext<any>(undefined);

interface SidebarProps {
	isMobile: boolean;
	isVisible: boolean;
	toggleVisibility: () => void;
}

export default function Sidebar({ isMobile, isVisible, toggleVisibility }: SidebarProps) {

	const auth = useAuth()
  	const menuItems = auth?.menu || [];
	
	const [expanded, setExpanded] = useState(true);
	const { theme } = useTheme();
	const [sidebarWidth, setSidebarWidth] = useState("w-75");

	const toggleSidebar = () => {
		setExpanded((prevExpanded) => !prevExpanded);
		setSidebarWidth(prevWidth => prevWidth === "w-75" ? "w-20" : "w-75");
	};

	const sidebarClass = isMobile ? (isVisible ? 'translate-x-0' : '-translate-x-full') : 'translate-x-0';

	const positionClasses = isMobile ? 'fixed inset-y-0 left-0 z-50' : '';
  
	const logo =
		theme === "dark" || (theme === "system" && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
			? "/logo/LE-banner-dark.png"
			: "/logo/LE-banner-light.png";
	
	const logo_mini = "/logo/LE-Icon.png";

	return (
		<aside className={`${positionClasses} z-40 bg-background/90 transform transition-transform duration-200 ease-in-out ${sidebarClass} ${sidebarWidth}`}>
			<nav className="h-full flex flex-col border-r shadow-sm z-40">
				<div className={`flex flex-row items-center p-4 pt-6 ${!expanded ? "justify-center" : "justify-end"}`}>
					{expanded && (
						<img
							src={logo}
							className="h-24 p-2"
							alt="LE"
						/>
					)}
					<Button onClick={isMobile ? toggleVisibility : toggleSidebar} variant="outline" size="icon">
						{expanded ? <ChevronFirst /> : (isMobile ? <PanelLeftClose /> : (
							<img
							src={logo_mini}
							className="h-10 w-10"
							alt="LE"
						/>
						))}
					</Button>
				</div>
				<SidebarContext.Provider value={{ expanded }}>
					<ul className={`flex flex-col p-5 ${expanded ? 'h-full' : ''}`}>
						<SidebarItem text="Inicio" active={undefined} icon={iconConverter("AiOutlineHome")} url={"/home"} />
						{menuItems.map((menuItemGroup, index) => (
							<React.Fragment key={index}>
								<Separator orientation="horizontal" />
								{expanded && (
									<h1 className="text-lg font-semibold p-5">{menuItemGroup.title}</h1>
								)}
								{menuItemGroup.items.map((menuItem, idx) => (
									<Tooltip key={idx}>
										<TooltipTrigger asChild>
											<SidebarItem
												url={menuItem.url}
												key={idx}
												text={menuItem.text}
												icon={iconConverter(menuItem.icon)}
												active={undefined}
											/>
										</TooltipTrigger>
										<TooltipContent side="right">{menuItem.text}</TooltipContent>
									</Tooltip>
								))}
							</React.Fragment>
						))}
					</ul>
				</SidebarContext.Provider>
			</nav>
		</aside>
	);
}


export const SidebarItem = React.forwardRef<HTMLButtonElement, { text: string; active: any; icon: JSX.Element, url: string }>(
    ({ text, active, icon, url }, ref) => {
        const { expanded } = useContext(SidebarContext);

		const location = useLocation();
        const navigate = useNavigate();

		const pathSegments = location.pathname;

		if (pathSegments.includes(url)) {
			active = true;
		} else {
			active = false;
		}
        
        return (
            <Tooltip>
                <TooltipTrigger asChild>
                <Button 
					ref={ref} 
					variant={`${active ? "secondary" : "ghost"}`}
					className={`${!expanded ? "mt-2 mb-2": "justify-start"}`} 
					size={`${!expanded ? "icon" : "lg"}`}
					onClick={() => navigate(url)}
				>
                    {icon}
                    {expanded && <span className="ml-4">{text}</span>}
                </Button>
                </TooltipTrigger>
                    <TooltipContent side="right" className="z-40 bg-accent">{text}</TooltipContent>
            </Tooltip>
        );
    }
);