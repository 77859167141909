import { 
	Dialog, 
	DialogTrigger, 
	DialogContent, 
	DialogHeader, 
	DialogFooter, 
	DialogTitle, 
	DialogDescription,
} from "src/components/ui/dialog";

import { Button } from "src/components/ui/button";
import { useState } from "react";
import { Input } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import { z } from "zod"
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { Dispatch, SetStateAction } from "react";

interface CreateBusinessLinesProps {
    setBusinessLines: Dispatch<SetStateAction<{ name: string; products: string }[]>>;
}

export default function CreateBusinessLines({ setBusinessLines }: CreateBusinessLinesProps) {

	const [isOpen, setIsOpen] = useState(false);

	const formSchema = z.object({
        name: z
			.string()
			.min(1, {
				message: "Por favor ingresa un nombre.",
			})
			.max(25, {
				message: "El nombre no puede tener más de 25 caracteres.",
			}),
        products: z
			.string()
			.min(1, {
				message: "Por favor ingresa la descripción de los productos o servicios.",
			})
			.max(250, {
				message: "La descripción no puede tener más de 250 caracteres.",
			}),
    });

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			products: '',
		},
	})

	const handleCreateLine = (data: z.infer<typeof formSchema>) => {

		setBusinessLines((prevBusinessLines) => [...prevBusinessLines, data]);

		form.reset();
		setIsOpen(false);

	};

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button variant="outline">+</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(handleCreateLine)} className="space-y-8">
						<DialogHeader>
							<DialogTitle>Crear Línea de Negocio</DialogTitle>
							<DialogDescription>Edita los detalles en este formulario</DialogDescription>
						</DialogHeader>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Nombre</FormLabel>
									<FormControl>
										<Input 
											id="name" 
											placeholder="Nombre de la linea de negocio"
											{...field} 
										/>
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="products"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Productos o Servicios</FormLabel>
									<FormControl>
										<Textarea 
											id="products" 
											placeholder="Explique los productos o servicios de esta linea de negocio."
											{...field} 
										/>
									</FormControl>
									<FormMessage/>
								</FormItem>
							)}
						/>
						<div>
							<p className="text-sm text-muted-foreground">Puedes ingresar más de un elemento, separado por comas</p>
						</div>
						<DialogFooter className="flex justify-between">
							<Button type="submit">
								Crear Linea de Negocio
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}