import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card";
import { LicitacionDetailCompleto } from 'src/types/Types';
import { useMediaQuery } from "react-responsive";
import { cn } from "src/lib/utils"
import { TimelineChart } from 'src/components/ui/timeline';

const FechasCard: React.FC<{ detail: LicitacionDetailCompleto, className?: string }> = ({ detail, className }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

	let data = useMemo(() => [
		{ date: Number(detail.FechaPublicacion), content: 'Publicación', color: '#3498db' },
		{ date: Number(detail.FechaEstimadaFirma), content: 'Firma (Estimada)', color: '#2ecc71' },
		{ date: Number(detail.FechaActoAperturaTecnica), content: 'Acto Apertura Técnica', color: '#2c3e50' },
		{ date: Number(detail.FechaAdjudicacion), content: 'Adjudicación', color: '#e67e22' },
		{ date: Number(detail.FechaInicio), content: 'Inicio Preguntas', color: '#16a085' },
		{ date: Number(detail.FechaCierre), content: 'Cierre Recepción Ofertas', color: '#670b0f' },
		{ date: Number(detail.FechaPubRespuestas), content: 'Publicación Respuestas', color: '#16a085' },
		{ date: Number(detail.FechaTiempoEvaluacion), content: 'Tiempo Evaluación', color: '#e74c3c' },
		{ date: Number(detail.FechasUsuario), content: 'Usuario', color: '#f1c40f' },
		{ date: Number(detail.FechaVisitaTerreno), content: 'Visita Terreno', color: '#d35400' },
		{ date: Number(detail.FechaActoAperturaEconomica), content: 'Acto Apertura Económica', color: '#2c3e50' },
		{ date: Number(detail.FechaEntregaAntecedentes), content: 'Entrega Antecedentes', color: '#8e44ad' },
		{ date: Number(detail.FechaFinal), content: 'Cierre Preguntas', color: '#16a085' },
		{ date: Number(detail.FechaSoporteFisico), content: 'Soporte Físico', color: '#bdc3c7' },
		{ date: Number(detail.FechaCreacion), content: 'Creación', color: '#27ae60' },
		{ date: Number(detail.FechaEstimadaAdjudicacion), content: 'Adjudicación (Estimada)', color: '#e67e22' },
	].filter(item => item.date).filter(item => item.date > 0), [detail])
	
	if (detail.FechaAdjudicacion) {
		data = data.filter(item => item.content !== 'Adjudicación (Estimada)');
	}

	return (
		<Card className={cn('flex flex-col', className)}>
		<CardHeader>
			<CardTitle>
				Linea de Tiempo
			</CardTitle>
		</CardHeader>
		<CardContent className="items-center justify-center">
			<TimelineChart data={data} isMobile={isMobile} />
		</CardContent>
		</Card>
	);
};

export default FechasCard;