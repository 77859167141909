import axios from 'axios';

const baseurl = process.env.REACT_APP_BACKEND_URL ?? '';

const axiosInstance = axios.create({
	baseURL: baseurl,
});

axiosInstance.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';
	return config;
});

const fetchUserData = async () => {
	const response = await axiosInstance.get('/user/profile_data');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const deleteUser = async (password: string) => {
	const response = await axiosInstance.put('/user/delete', { password });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchMenu = async () => {
	const response = await axiosInstance.get('/user/get_menu');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const userConfigurate = async (configuration: { region: string; comuna: string; direccion: string; descripcion: string; businesslines: { name: string; products: string; }[] }) => {
	const response = await axiosInstance.post('/onboarding/load_configuration', configuration)
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchConfiguration = async () => {
	const response = await axiosInstance.get('/onboarding/get_configuration');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const editProfile = async (formData: FormData) => {
	const response = await axiosInstance.post('/user/update_profile', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
}


export {
	fetchUserData,
	deleteUser,
	userConfigurate,
	fetchConfiguration,
	fetchMenu,
	editProfile
};