import axios from 'axios';

const baseurl = process.env.REACT_APP_BACKEND_URL ?? '';

const axiosInstance = axios.create({
	baseURL: baseurl,
});


axiosInstance.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';
	
	
	return config;
});

const fetchRutData = async (rut: string) => {
	try {
		const response = await axiosInstance.get('/onboarding/rut_data', { params: { rut: rut } });
		return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {}}
		}
	}
};

const postRutData = async (data: any) => {
	try {
		const response = await axiosInstance.post('/onboarding/load_rut_data', data);
		return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {}}
		}
	}
};

const submitRequest = async (formData: FormData) => {
	const response = await axiosInstance.post('/onboarding/submit_request_empresa', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
};

export {
	fetchRutData, 
	submitRequest,
	postRutData,
};