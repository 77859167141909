import BusquedaCard from "./components/Busqueda";
import { fetchBusquedasGuardadas } from "src/utils/BusquedasGuardadas";

import Loading from "src/components/Loading";

import { useQuery } from '@tanstack/react-query'

import { BusquedaGuardadaResponse } from "src/types/Types";

export default function BusquedasGuardadas() {

	const { isPending, isError, data, error } = useQuery<BusquedaGuardadaResponse>({
		queryKey: ['busquedas'],
		queryFn: fetchBusquedasGuardadas,
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
	})

	const busquedas = data?.data.busquedas || [];

	if (isPending) return <Loading />

	if (isError) return <div>Error: {String(error)}</div>
	
	return (
		<>
			<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl mt-5 justify-left">
				Busquedas Guardadas
			</h1>
			{busquedas.length === 0 && (
				<div className="flex justify-center items-center w-full h-80">
					<p className="text-xl">No tienes busquedas guardadas</p>
				</div>
			)}
			<div className="relative flex flex-col justify-center items-center lg:px-0 w-full">
				<div className="flex flex-col justify-center items-center w-11/12 mt-10">
					{busquedas.map((busqueda, index) => (
						<div key={index} className="w-full h-auto flex justify-center items-center m-1">
							<BusquedaCard busqueda={busqueda} />
						</div>
					))}
				</div>
			</div>
		</>
	)
}