import { Badge } from "src/components/ui/badge";

import {
	Card,
	CardContent,
	CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { CompraAgilDetailCompleto } from 'src/types/Types';

export default function ResumenCard( {detail}: {detail: CompraAgilDetailCompleto} ) {

	const variant = (() => {
		switch (detail?.estado) {
			case "Cerrada":
				return "destructive";
			case "Adjudicada":
				return "warning";
			case "Desierta":
				return "failed";
			case "Revocada":
				return "failed";
			case "Suspendida":
				return "warning";
			case "Publicada":
				return "success";
			case "OC Emitida":
				return "destructive";
			case "Cancelada":
				return "canceled";
			case "Desierta (o art. 3 ó 9 Ley 19.886)":
				return "failed";
			default:
				return "default";
		}
	})();

	return(
		<Card className="md:col-span-3">
			<CardHeader>
				<CardTitle>
					{detail.nombre}
				</CardTitle>
				<CardDescription className="text-lg">
					Código: {detail.codigo}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col space-y-5">
					<div>
						<p className="text-sm font-semibold">Tipo</p>
						<p className="text-lg font-semibold text-muted-foreground">(COT) Compra Ágil, monto igual o inferior a 30 UTM</p>
					</div>
					<div>
						<p className="text-sm font-semibold">Descripción</p>
						<p className="text-base font-semibold text-muted-foreground">{detail.descripcion}</p>
					</div>
					<div>
					<Badge variant={variant}>{detail.estado}</Badge>
					</div>
					{detail.motivoCancelacion && (
						<div>
							<p className="text-sm font-semibold">Motivo Cancelación</p>
							<p className="text-lg font-semibold text-muted-foreground">{detail.motivoCancelacion}</p>
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	);
}
