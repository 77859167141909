import { Outlet } from "react-router-dom"
import React from "react"
import { useAuth } from "src/components/AuthContext"
import backgroundImage from "./bg_mark.png"
import NotAuthorized from "../NotAuthorized"
import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area"
import Copyright from "src/components/Copyright"
import { ModeToggle } from "src/components/ModeToggle"
import { useTheme } from "next-themes"
import { LogOutIcon } from "lucide-react"
import { Button } from "src/components/ui/button"
import { useNavigate } from "react-router-dom"

export default function OnBoardingLayout() {
	const { theme } = useTheme()
	const navigate = useNavigate()

	const logo = theme === 'dark' || (theme === 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? '/logo/LE-banner-dark.png' : '/logo/LE-banner-light.png';

	const auth = useAuth()
	const token = auth?.token

	const logout = auth?.logout

	const handleLogout = () => {
		logout && logout();
		navigate("/login");
	}

	if (token) {
		return (
			<React.Fragment>
				<div className="flex">
					<ScrollArea className="w-full rounded-md border">
						<div className="flex-1 bg-cover h-[99vh] bg-center flex items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
							<div className="flex flex-col items-center">
								<Outlet />
								<img src={logo} width="200px" alt="licitaeasy" className="pt-5 mx-auto" />
								<div className="flex justify-center mt-5">
									<Copyright />
								</div>
								<div className="flex right-4 bottom-4 md:right-8 md:bottom-8 absolute">
									<ModeToggle />
									<Button variant="outline" onClick={handleLogout} size="icon" className="ml-2">
										<LogOutIcon />
									</Button>
								</div>
							</div>
						</div>
						<ScrollBar orientation="vertical" />
					</ScrollArea>
				</div>
			</React.Fragment>
		)
	} else
		return <NotAuthorized />

}