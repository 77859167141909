import { ModeToggle } from "src/components/ModeToggle"
import Copyright from "./Copyright"

const WorkInProgress = () => {

	return (
		<div className="container relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
			<div className="relative hidden h-full flex-col bg-black p-10 text-white lg:flex dark:border-r">
				<div className="relative z-20 flex items-center text-lg font-medium">
					<img
						className="w-40 h-40 mr-2"
						src="logo/LE-blanco.png"
						alt="Licitaeasy Logo"
					>
					</img>
				</div>
				<div className="relative z-20 mt-auto" style={{ marginBottom: '10%' }}>
					<h1 className="text-3xl mb-4">En Progreso</h1>
					<p>Estamos trabajando en esta sección, por favor regresa más tarde o ponte en contacto con nosotros</p>
				</div>
			</div>
			<div className="lg:p-8">
				<div className="flex justify-center mt-8">
					<Copyright />
				</div>
				<div className="flex right-4 bottom-4 md:right-8 md:bottom-8 absolute">
					<ModeToggle />
				</div>
			</div>
		</div>
	);
};

export default WorkInProgress;