import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Button } from "src/components/ui/button"
import { Input } from "src/components/ui/input"
import { useParams } from 'react-router-dom';
import { verifyEmail } from 'src/utils/Users';
import { useToast } from "src/components/ui/use-toast"
import { Icons } from "src/components/ui/icons"

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import {
	Alert,
	AlertDescription,
} from "src/components/ui/alert"

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

export default function ConfirmEmailForm() {
	
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	
	const { token } = useParams<{ token?: string }>();
	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});
	
	const { toast } = useToast();

	const FormSchema = z.object({
		email : z
			.string()
			.email({
			message: "Por favor ingresa un email válido.",
			})
	});

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			email: '',
		},
		});
	
	async function onSubmit(data: z.infer<typeof FormSchema>) {

		setIsLoading(true);

		const response = await verifyEmail(token ?? '', data.email);
		setApiResponse(response);

		setIsLoading(false);

	};

	useEffect(() => {
		if (apiResponse?.status === 200) {
			toast({
				title: "Correo Confirmado",
				description: "Tu correo ha sido confirmado exitosamente, puedes cerrar esta ventana e iniciar sesión.",
			});
		}
	}, [apiResponse, toast]);

	return (
		<>
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[450px]">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-4xl font-semibold tracking-tight">
						Confirma tu correo
					</h1>
					<p className="text-2xl text-muted-foreground">
						Ingresa tu correo y presiona el botón para confirmar.
					</p>
				</div>
				<div className="flex flex-col space-y-4">
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Email</FormLabel>
										<FormControl>
											<Input id="email" type="email" {...field} />
										</FormControl>
										<FormMessage/>
									</FormItem>
								)}
							/>
							<div className="flex justify-center w-full">
								<Button type="submit" disabled={isLoading} className="w-full">
								{isLoading ? (
									<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
								) : (
									null
								)}{" "}
									Confirma tu Correo
								</Button>
							</div>
								{apiResponse.status && (
									<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
										<AlertDescription>
											{apiResponse.message}
										</AlertDescription>
									</Alert>
								)}
						</form>
					</Form>
				</div>
			</div>
		</>
	)
}