import { fetchItems,addFollowed, deleteFollowed, fetchFollowedDetails } from "src/utils/Models"

import {  useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { useState } from 'react';

import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card";

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip"

import { FollowedDetailsResponse } from "src/types/Types";

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { Button } from "src/components/ui/button"

import { Badge } from "src/components/ui/badge";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogClose,
} from "src/components/ui/dialog"

import { Skeleton } from "src/components/ui/skeleton";

import { ScrollArea } from "src/components/ui/scroll-area"

import { ItemsResponse, Licitacion } from "src/types/Types";

import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "src/components/ui/use-toast";
import { Eye, EyeOff } from "lucide-react";

export function CardSkeleton() {
	return (
		<Card>
			<CardHeader>
				<CardTitle><Skeleton className="h-[30px] w-[200px]" /></CardTitle>
				<CardDescription><Skeleton className="h-[20px] w-[100px]" /></CardDescription>
			</CardHeader>
			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell><Skeleton className="h-[20px] w-[120px]" /></TableCell>
							<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						</TableRow>
						<TableRow>
							<TableCell><Skeleton className="h-[20px] w-[120px]" /></TableCell>
							<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						</TableRow>
						<TableRow>
							<TableCell><Skeleton className="h-[20px] w-[120px]" /></TableCell>
							<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			<CardFooter>
				<Badge variant="secondary"><Skeleton className="h-[20px] w-[80px]" /></Badge>
			</CardFooter>
		</Card>
	)
}

interface AlertLicitacionProps {
	licitacion: Licitacion;
	triggerComponent: React.ReactNode;
}

export default function DialogLicitacion({ licitacion, triggerComponent }: AlertLicitacionProps) {
	
	const [dialogOpen, setDialogOpen] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const { isPending, isError, data, error } = useQuery<ItemsResponse>({
		queryKey: ['items', licitacion.CodigoExterno],
		queryFn: () => fetchItems([licitacion.CodigoExterno]),
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: dialogOpen,
	})
	
	const { data: followedData, isLoading: followedIsLoading } = useQuery<FollowedDetailsResponse>({
		queryKey: ['seguidos'],
		queryFn: fetchFollowedDetails,
		staleTime: 1000 * 60 * 60 * 2,
		enabled: dialogOpen,
	});
	
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const addMutation = useMutation({
		mutationFn: addFollowed,
		onMutate: () => {
			toast({
				title: "Agregando licitación",
				description: "Agregando la licitación a tus seguidas.",
			});
		},
		onSuccess: () => {
			queryClient.setQueryData(
				['seguidos'],
				(prev: FollowedDetailsResponse) => {
					return {
						...prev,
						data: {
							...prev.data,
							follows_details: [
								...prev.data.follows_details,
								licitacion
							]
						}
					}
				}
			)
			queryClient.invalidateQueries({
				queryKey: ['seguidos'],
			});
			toast({
				title: "Licitación agregada",
				description: "La licitación se agregó a tu lista de seguidos.",
			});
		},
		onError: () => {
			toast({
				title: "Error al agregar",
				description: "Hubo un error al intentar agregar la licitación a tus seguidas, por favor intenta nuevamente.",
			});
		},
	});

	const deleteMutation = useMutation({
		mutationFn: deleteFollowed,
		onMutate: () => {
			toast({
				title: "Eliminando licitación",
				description: "Eliminando la licitación de tus seguidas.",
			});
		},
		onSuccess: () => {
			queryClient.setQueryData(
				['seguidos'],
				(prev: FollowedDetailsResponse) => {
					return {
						...prev,
						data: {
							...prev.data,
							follows_details: prev.data.follows_details.filter(item => item.CodigoExterno !== licitacion.CodigoExterno),
						}
					}
				}
			)
			queryClient.invalidateQueries({
				queryKey: ['seguidos'],
			});
			toast({
				title: "Licitación eliminada",
				description: "La licitación ha sido eliminada de tus seguidas.",
			});
			setDialogOpen(false);
		},
		onError: () => {
			toast({
				title: "Error al eliminar",
				description: "Hubo un error al intentar eliminar la licitación de tus seguidas, por favor intenta nuevamente.",
			});
		},
	});

	const isFollowed = followedData?.data?.follows_details?.some(item => item.CodigoExterno === licitacion.CodigoExterno);

	const items = data?.data?.items ? data.data.items : undefined;

	const nf = new Intl.NumberFormat("es-CL", {
		style: "currency",
		currency: "CLP",
	})

	const tiempoFaltante = (fecha: number) => {
		const fechaCierre = new Date(fecha);
		const fechaActual = new Date();
		const diferencia = fechaCierre.getTime() - fechaActual.getTime();
		const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
		const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

		if (diferencia < 0) {
			return "Licitación Cerrada";
		} else {
			return `${dias} días y ${horas} horas`;
		}
	}

	const variant = (() => {
		switch (licitacion?.Estado) {
			case "Cerrada":
				return "destructive";
			case "Adjudicada":
				return "warning";
			case "Desierta":
				return "failed";
			case "Revocada":
				return "failed";
			case "Suspendida":
				return "warning";
			case "Publicada":
				return "success";
			case "OC Emitida":
				return "destructive";
			case "Cancelada":
				return "canceled";
			case "Desierta (o art. 3 ó 9 Ley 19.886)":
				return "failed";
			default:
				return "default";
		}
	})();

	return (
		<Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
			<DialogTrigger asChild>
				{triggerComponent}
			</DialogTrigger>
			<DialogContent className="max-w-5xl">
				<ScrollArea className="max-h-[70vh]">
					<DialogHeader className="sm:flex-col">
						<div className="py-2">
							<Badge variant={variant}>{licitacion?.Estado}</Badge>
						</div>
						<DialogTitle>
							{licitacion?.Nombre}
						</DialogTitle>
						<DialogDescription>Código: {licitacion?.CodigoExterno}</DialogDescription>
						<div className="py-3">
							<p className="mb-1"><strong>Cliente: </strong>{licitacion?.NombreOrganismo}</p>
							<p className="mb-1"><strong>Descripcion: </strong>{licitacion?.Descripcion}</p>
						</div>
					</DialogHeader>
					<div className="grid md:grid-cols-3 m-1 space-x-6">
						<div className="flex flex-col gap-4">
							<Card>
								<CardHeader>
									<CardTitle>Presupuesto</CardTitle>
									<CardDescription>Presupuesto de la Licitación</CardDescription>
								</CardHeader>
								<CardContent>
									<div className="grid grid-cols-3 gap-4">
										<div className="col-span-2">
											<p className="text-sm font-semibold">MontoEstimado</p>
											<h1 className="scroll-m-20 text-3xl tracking-tight">{nf.format(licitacion?.MontoEstimado)}</h1>
										</div>
										<div>
											<p className="text-sm font-semibold">Moneda</p>
											<p className="text-lg font-semibold text-muted-foreground">{licitacion?.Moneda}</p>
										</div>
									</div>
								</CardContent>
							</Card>
							<Card>
								<CardHeader>
									<CardTitle>Fecha de Cierre</CardTitle>
									<CardDescription>Fecha de Cierre de la Licitación</CardDescription>
								</CardHeader>
								<CardContent>
									<div className="grid grid-cols-1 gap-4">
										<div>
											<p className="text-sm font-semibold">Fecha</p>
											<p className="text-lg font-semibold text-muted-foreground">{new Date(licitacion?.FechaCierre).toLocaleString()}</p>
										</div>
										<div>
											<p className="text-sm font-semibold">Tiempo Restante</p>
											<p className="text-lg font-semibold text-muted-foreground">{tiempoFaltante(licitacion?.FechaCierre)}</p>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
						<Card className="md:col-span-2">
							<CardHeader>
								<CardTitle>Items</CardTitle>
								<CardDescription>Items a Licitar</CardDescription>
							</CardHeader>
							<CardContent>
								<ScrollArea className="w-full h-72">
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead>NombreProducto</TableHead>
												<TableHead>Cantidad</TableHead>
												<TableHead>UnidadMedida</TableHead>
											</TableRow>
										</TableHeader>
										{isPending &&
											<TableBody>
												<TableRow>
													<TableCell><Skeleton className="h-[20px] w-[160px]" /></TableCell>
													<TableCell><Skeleton className="h-[20px] w-[80px]" /></TableCell>
													<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
												</TableRow>
											</TableBody>
										}
										{isError && <p>Error: {String(error)}</p>}
										<TableBody>
											{items?.map((item: any, index: number) => (
												<TableRow key={index}>
													<TableCell>{item.NombreProducto}</TableCell>
													<TableCell>{item.Cantidad}</TableCell>
													<TableCell>{item.UnidadMedida}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</ScrollArea>
							</CardContent>
						</Card>
					</div>
				</ScrollArea>
				<DialogFooter>
					{!followedIsLoading && (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button variant="secondary" size="icon" onClick={() => {
										if (isFollowed) {
											deleteMutation.mutate(licitacion.CodigoExterno);
										} else {
											addMutation.mutate(licitacion.CodigoExterno);
										}
									}}>
										{isFollowed ? (
											<EyeOff />
										) : (
											<Eye />
										)}
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									<p>{isFollowed ? 'Dejar de seguir' : 'Seguir'}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					<Button onClick={() => navigate(`${location.pathname}/${licitacion?.CodigoExterno}`)}>
						Ver Detalle
					</Button>
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cerrar
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
