import { useState, useEffect } from 'react';
import { Button } from 'src/components/ui/button';
import { Menu } from 'lucide-react';
import { ModeToggle } from "src/components/ModeToggle"
import { useTheme } from "next-themes"

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
    const { theme } = useTheme()

	const logo = theme === 'dark' || (theme === 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? '/logo/LE-banner-dark.png' : '/logo/LE-banner-light.png';
  
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1280);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

	return (
		<header className={'sticky w-full top-0 z-50 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-secondary/65 h-40'}>
			<div className={'container flex items-center justify-between h-full'}>
				<div className="flex justify-between w-full">
					{isMobile && (
					<button onClick={() => setIsOpen(!isOpen)}>
						<Menu size={24} />
					</button>
					)}
					<div className="flex items-center">
						<a href="#Inicio">
							<img src={logo} alt="..." style={{maxWidth: '250px', height: 'auto'}}/> 
						</a>
					</div>
					<div className="flex items-center left-4">
						<ModeToggle />
					</div>
					<nav className={`flex items-center ${isMobile ? (isOpen ? 'flex' : 'hidden') : 'space-x-4 flex'} ${isMobile && isOpen ? 'absolute grid grid-cols-1 space-y-2 top-40 left-5' : 'flex-row'}`}>
						<a href="#Inicio">
							<Button variant='outline' size='default' className="text-base">Inicio</Button>
						</a>
						<a href="#Caracteristicas">
							<Button variant='outline' size='default' className="text-base">Características</Button>
						</a>
						<a href="#PlanesYPrecios">
							<Button variant='outline' size='default' className="text-base">Planes y Precios</Button>
						</a>
						<a href="#FAQ">
							<Button variant='outline' size='default' className="text-base">FAQ's</Button>
						</a>
						<a href="#Contacto">
							<Button variant='outline' size='default' className="text-base">Contacto</Button>
						</a>
						<a href="/login">
							<Button variant='secondary' size='lg' className="text-lg">Ingresa</Button>
						</a>
						<a href="/register">
							<Button variant='default' size='lg' className="text-lg">Regístrate</Button>
						</a>
					</nav>
				</div>
			</div>
		</header>
	);
}
  
export default NavBar;