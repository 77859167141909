import axios from 'axios';

const baseurl = process.env.REACT_APP_BACKEND_URL ?? '';

const axiosInstance = axios.create({
	baseURL: baseurl,
});

axiosInstance.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';
	return config;
});

const fetchBusquedasGuardadas = async () => {
	const response = await axiosInstance.get('/busquedas_guardadas/list');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
};

const deleteBusquedaGuardada = async (token: string) => {
	const response = await axiosInstance.delete('/busquedas_guardadas/delete', {
		params: {
			token: token
		}
	});
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
}

const addBusquedaGuardada = async (busqueda: any) => {
	const response = await axiosInstance.post('/busquedas_guardadas/add', busqueda);
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
}

const updateBusquedaGuardada = async (busqueda: any) => {
	const response = await axiosInstance.post('/busquedas_guardadas/update', busqueda);
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data};
}

export {
	fetchBusquedasGuardadas,
	deleteBusquedaGuardada,
	addBusquedaGuardada,
	updateBusquedaGuardada
};