import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { ScrollArea } from "src/components/ui/scroll-area"
import { useTheme } from "next-themes"

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "src/components/ui/accordion"

import { Criterio } from "src/types/Types"

export default function CriteriosCard({ criterios, className }: { criterios?: Criterio[], className?: string}) {

	const { theme, systemTheme } = useTheme();
	
	const currentTheme = theme === "system" ? systemTheme : theme;

	const lightThemeColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#54006b', '#ff851b', '#ff4136']
	const darkThemeColors = ['#8f6200', '#0074D9', '#7FDBFF', '#39CCCC', '#3D9970', '#D3D3D3', '#8000a3', '#ff851b', '#ff4136']

	const COLORS = currentTheme === "dark" ? darkThemeColors : lightThemeColors;

	const data = criterios?.map((criterio, index) => ({
		name: criterio.item,
		value: parseFloat(criterio.ponderacion),
		observaciones: criterio.observaciones,
	}));
	
	const CustomizedTooltip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			const { name, value, observaciones } = payload[0].payload;
			return (
				<Card>
					<CardHeader>
						<CardTitle className="text-base">
							{name}
						</CardTitle>
						<CardDescription>
							{value}%
						</CardDescription>
					</CardHeader>
					<CardContent>
						{observaciones}
					</CardContent>
				</Card>
			);
		}
		return null;
	};

	if (!criterios || criterios.length === 0) {
		return (
			<Card className={className}>
				<CardHeader>
					<CardTitle>
						Criterios
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-muted-foreground text-lg">
						No se encontraron Criterios en Ficha.
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle className="flex flex-col space-y-5 h-12">
					Criterios
				</CardTitle>
			</CardHeader>
			<CardContent>
				<ResponsiveContainer width="100%" height={300}>
					<PieChart>
						<Pie
							data={data}
							cx="50%"
							cy="50%"
							innerRadius={85}
							outerRadius={105}
							fill="#8884d8"
							paddingAngle={5}
							dataKey="value"
							label={({ name, value }) => `${name} (${value}%)`}
						>
							{data?.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip 
							content={<CustomizedTooltip />}
						/>
					</PieChart>
				</ResponsiveContainer>
			</CardContent>
			<CardContent>
				<Accordion type="single" collapsible className="w-full">
					<AccordionItem value="item-1">
						<AccordionTrigger>Ver Tabla</AccordionTrigger>
						<AccordionContent>
							<ScrollArea className="h-72 w-[100%] rounded-md border">
								<Table>
									<TableHeader>
										<TableRow>
											<TableHead>Ítem</TableHead>
											<TableHead>Observaciones</TableHead>
											<TableHead>Ponderación</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										{criterios.map((criterio: Criterio, idx: number) => (
											<TableRow key={idx}>
												<TableCell>{criterio.item}</TableCell>
												<TableCell>{criterio.observaciones}</TableCell>
												<TableCell>{criterio.ponderacion}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</ScrollArea>
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</CardContent>
		</Card>
	)
}