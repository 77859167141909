import * as React from "react"

import { 
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'src/components/ui/table'

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"

import { ChevronDown } from "lucide-react"

import { Input } from "src/components/ui/input"

import { Button } from "src/components/ui/button"

import { fetchFollowedDetails } from "src/utils/Models";

import Loading from "src/components/Loading";

import { useQuery } from '@tanstack/react-query'

import { columns } from "./components/columns";

import { FollowedDetailsResponse } from "src/types/Types";

export default function Seguidos() {

	const [sorting, setSorting] = React.useState<SortingState>([])

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	)

	const [pagination, setPagination] = React.useState<PaginationState>({
		pageIndex: 0,
		pageSize: 8,
	})

	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({
			'Tipo': false,
			'FechaImportAPI': false,
	})
	
	const { isPending, isError, data: response, error } = useQuery<FollowedDetailsResponse>({
		queryKey: ['seguidos'],
		queryFn: fetchFollowedDetails,
		staleTime: 1000 * 60 * 60 * 2
	})

	const data = response?.data?.follows_details || [];

	const table = useReactTable({
		data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			pagination,
		},
	})

	if (isPending) return <Loading />

	if (isError) return <div>Error: {String(error)}</div>

	return (
		<>	
			<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl mt-5 justify-left">
				Licitaciones Seguidas
			</h1>
			<div className="relative flex flex-col justify-center items-center lg:px-0 w-full mt-5">
				<div className="overflow-x-auto w-full">
					<div className="w-full">
						<div className="flex items-center py-4">
						<Input
							placeholder="Filtrar por Código..."
							value={(table.getColumn("CodigoExterno")?.getFilterValue() as string) ?? ""}
							onChange={(event) =>
							table.getColumn("CodigoExterno")?.setFilterValue(event.target.value)
							}
							className="max-w-sm"
						/>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
							<Button variant="outline" className="ml-auto">
								Columnas <ChevronDown className="ml-2 h-4 w-4" />
							</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
							{table
								.getAllColumns()
								.filter((column) => column.getCanHide())
								.map((column) => {
								return (
									<DropdownMenuCheckboxItem
									key={column.id}
									className="capitalize"
									checked={column.getIsVisible()}
									onCheckedChange={(value) =>
										column.toggleVisibility(!!value)
									}
									>
									{column.id}
									</DropdownMenuCheckboxItem>
								)
								})}
							</DropdownMenuContent>
						</DropdownMenu>
						</div>
						<div className="rounded-md border bg-background/90">
						<Table>
							<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
									<TableHead key={header.id}>
										{header.isPlaceholder
										? null
										: flexRender(
											header.column.columnDef.header,
											header.getContext()
											)}
									</TableHead>
									)
								})}
								</TableRow>
							))}
							</TableHeader>
							<TableBody>
							{table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
								>
									{row.getVisibleCells().map((cell) => (
										row.getIsSelected() ? (
											<TableCell key={cell.id} className="line-through">
												{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
												)}
											</TableCell>
										) : (
									<TableCell key={cell.id}>
										{flexRender(
										cell.column.columnDef.cell,
										cell.getContext()
										)}
									</TableCell>
									)
									))}
								</TableRow>
								))
							) : (
								<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No tienes licitaciones seguidas.
								</TableCell>
								</TableRow>
							)}
							</TableBody>
						</Table>
						</div>
						<div className="flex items-center justify-end space-x-2 py-4">
							<div className="space-x-2">
								<Button
								variant="outline"
								size="sm"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
								>
									Anterior
								</Button>
								<Button
								variant="outline"
								size="sm"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
								>
									Siguiente
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
