import { Fade, Slide } from "react-awesome-reveal";

export default function Funcionamiento() {

	return (
		<div className="container justify-center md:w-2/3">
			<Fade triggerOnce>
				<div className="text-center mb-20">
					<p className="text-2xl">Probablemente te estés preguntando...</p>
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
						¿Como funciona?
					</h1>
				</div>
				<Slide direction='left' triggerOnce>
					<div className="grid md:grid-cols-2 justify-center items-center text-center mt-10">
						<div className="order-1 m-10">
							<h3 className="border-b pb-2 text-3xl font-semibold tracking-tight text-sky-400">
								Búsqueda Inteligente
							</h3>
							<p className="text-xl text-muted-foreground">
								Nuestro algoritmo de búsqueda inteligente compara la estructura semántica de tu frase de búsqueda con la estructura semántica de las licitaciones que tenemos en nuestra base de datos, para encontrar las que tienen mayor similitud.
							</p>
						</div>
						<div className="order-2 m-10">
							<img src="gallery/comment1.png" alt="..." />
						</div>
					</div>
				</Slide>
				<Slide direction='right' triggerOnce>
					<div className="grid md:grid-cols-2 items-center text-center mt-10 w-full">
						<div className="order-2 m-10">
							<h3 className="border-b pb-2 text-3xl font-semibold tracking-tight text-sky-400">
								Modelo Recomendador
							</h3>
							<p className="text-xl text-muted-foreground">
								El modelo recomendador de Licitaciones es un sistema de recomendación que se basa en el comportamiento de los usuarios para recomendar licitaciones que puedan ser de su interés. Utiliza un algoritmo de aprendizaje automático que analiza las licitaciones que has visto y las que has guardado, para recomendarte licitaciones similares.	
							</p>
						</div>
						<div className="order-1 m-10">
							<img src="gallery/comment2.png" alt="..." />
						</div>
					</div>
				</Slide>
				<Slide direction='left' triggerOnce>
					<div className="grid md:grid-cols-2 items-center text-center mt-10 w-full">
						<div className="order-1 m-10">
							<h3 className="border-b pb-2 text-3xl font-semibold tracking-tight text-sky-400">
								Sistema de Seguimiento
							</h3>
							<p className="text-xl text-muted-foreground">
								Este sistema te permite hacer seguimiento a las licitaciones que te interesan, para que no se te pase ninguna. Puedes guardarlas, marcarlas como favoritas, y recibir notificaciones cuando haya cambios en ellas, además tendrás un calendario con todas las fechas importantes de las licitaciones que sigues, de tal forma que puedas estar siempre al tanto de todo.
							</p>
						</div>
						<div className="order-2 m-10">
							<img src="gallery/comment3.png" alt="..." />
						</div>
					</div>
				</Slide>
				<Slide direction='right' triggerOnce>
					<div className="grid md:grid-cols-2 items-center text-center mt-10 w-full">
						<div className="order-2 m-10">
							<h3 className="border-b pb-2 text-3xl font-semibold tracking-tight text-sky-400">
								Procesamiento de Licitaciones
							</h3>
							<p className="text-xl text-muted-foreground">
								El procesamiento de licitaciones es una tarea que consume mucho tiempo y recursos, por eso hemos desarrollado un sistema de procesamiento masivo de licitaciones que te permite analizar y extraer información relevante de cientos de licitaciones en segundos. Con este sistema podrás obtener información valiosa de las licitaciones de forma rápida y sencilla, pudiendo así tomar decisiones más informadas y oportunas.
							</p>
						</div>
						<div className="order-1 m-10">
							<img src="gallery/dashboard.png" alt="..." />
						</div>
					</div>
				</Slide>
			</Fade>
		</div>
	);
}