import EmpresaDialog from "./components/Dialog/EmpresaDialog";
import PersonalDialog from "./components/Dialog/PersonalDialog";

import { Button } from "src/components/ui/button";

import { useAuth } from "src/components/AuthContext";
import { useNavigate } from "react-router-dom";

export default function OnBoarding() {

	const navigate = useNavigate();
	const auth = useAuth();
	
	if ((Object.keys(auth?.configuration).length > 0)) {
		return(
			<div className="container relative flex-col items-center justify-center grid">
				<div className="mx-auto flex w-full flex-col justify-center space-y-6 lg:w-[950px]">
					<div className="flex flex-col space-y-2 text-center">
						<h1 className="text-4xl font-semibold tracking-tight">¡Ya has completado tu registro!</h1>
						<p className="mt-5 text-2xl">Puedes seguir navegando en la plataforma y disfrutar de todas las funcionalidades que tenemos para ti</p>
					</div>
					<div className="flex items-center flex-col mt-5">
						<Button onClick={() => navigate('/home')} className="w-full" variant="default" size="lg">Ir a la plataforma</Button>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<div className="flex items-center justify-center">
			<div className="container relative flex-col items-center justify-center grid">
				<div className="mx-auto flex w-full flex-col text-center space-y-6 sm:w-[950px]">
					<h1 className="lg:text-5xl font-semibold tracking-tight">¡Bienvenido!</h1>
					<div className="mt-10 items-center">
						<p className="scroll-m-20 font-semibold tracking-tight lg:text-2xl">
							Estamos felices de que te unas, pero para poder ayudarte de una forma más efectiva, 
							necesitamos que respondas algunas preguntas. No te tomará más de cinco minutos.
						</p>
					</div>
					<div className="mt-10">
						<p className="leading-7 mt-4 text-xl text-muted-foreground">Para comenzar, elige el tipo de cuenta que deseas crear:</p>
					</div>
				</div>
				<div className="flex items-center flex-col">
					<div className="flex justify-center my-3 p-10 py-2">
						<PersonalDialog />
						<EmpresaDialog />
					</div>
				</div>
			</div>
		</div>
	)
}
