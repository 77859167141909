import React, { useEffect } from "react";
import { ModeToggle } from "src/components/ModeToggle"
import Copyright from "../Copyright"
import { Outlet } from "react-router-dom"
import NotAuthorized from "../NotAuthorized"
import { useNavigate } from "react-router-dom"

export default function UserLayout() {
	const environment = process.env.REACT_APP_ENV || "dev"

	const navigate = useNavigate()

	useEffect(() => {
        if (sessionStorage.getItem('token')) {
            navigate('/home');
        }
    }, [navigate]);

	if (environment === "dev" && !sessionStorage.getItem('token')) {
		return (
			<React.Fragment>
				<div className="container relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
					<div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
						<div className="absolute inset-0 bg-zinc-900"></div>
						<div className="relative z-20 flex items-center text-lg font-medium">
							<img
								className="w-40 h-40 mr-2"
								src="logo/LE-blanco.png"
								alt="Licitaeasy Logo"
							>
							</img>
						</div>
					</div>
					<div className="lg:p-8">
						<Outlet />
						<div className="flex justify-center mt-8">
							<Copyright />
						</div>
						<div className="flex right-4 bottom-4 md:right-8 md:bottom-8 absolute">
							<ModeToggle />
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	} else
		return <NotAuthorized />
}