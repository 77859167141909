import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { ScrollArea } from "src/components/ui/scroll-area"

import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "src/components/ui/collapsible"

import { ChevronsUpDown } from "lucide-react"

import { Skeleton } from "src/components/ui/skeleton"

import { Garantia } from "src/types/Types"

function SkeletonCard({ className }: { className?: string }) {
	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					<Skeleton className="w-[350px] h-[30px]" />
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col space-y-5">
					<TableRow>
						<TableCell><Skeleton className="h-[20px] w-[80px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[160px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[200px]" /></TableCell>
					</TableRow>
				</div>
			</CardContent>
		</Card>
	);
}

export default function GarantiasCard({ garantias = [], className }: { garantias?: Garantia[], className?: string }) {

	if (!garantias) {
		return <SkeletonCard className={className} />;
	}

	if (garantias.length === 0) {
		return (
			<Card className={className}>
				<CardHeader>
					<CardTitle>
						Garantias
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-muted-foreground text-lg">
						No se encontraron Garantías en ficha
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Garantias
				</CardTitle>
			</CardHeader>
			<CardContent>
				<ScrollArea>
					{garantias.map((garantia: Garantia, idx: number) => (
						<Collapsible key={idx}>
							<div className="flex items-center justify-between m-3">
								<span className="text-lg font-semibold">{garantia.tipo}</span>
								<CollapsibleTrigger className="flex items-center">
									<span className="flex items-center cursor-pointer hover:bg-muted p-2 rounded">
										<ChevronsUpDown className="h-4" />
									</span>
								</CollapsibleTrigger>
							</div>
							<CollapsibleContent>
								<Table>
									<TableHeader>
										<TableRow>
											<TableHead>Beneficiario</TableHead>
											<TableHead>Descripción</TableHead>
											<TableHead>Fecha de Vencimiento</TableHead>
											<TableHead>Forma de Restitución</TableHead>
											<TableHead>Glosa</TableHead>
											<TableHead>Monto</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										<TableRow>
											<TableCell>{garantia.beneficiario}</TableCell>
											<TableCell>{garantia.descripcion}</TableCell>
											<TableCell>{garantia.vencimiento}</TableCell>
											<TableCell>{garantia.restitucion}</TableCell>
											<TableCell>{garantia.glosa}</TableCell>
											<TableCell>{garantia.monto}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</CollapsibleContent>
						</Collapsible>
					))}
				</ScrollArea>
			</CardContent>
		</Card>
	)
}