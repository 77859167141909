import { CgSearchFound } from "react-icons/cg";
import { BsUiChecksGrid } from "react-icons/bs";
import { PiGarageDuotone } from "react-icons/pi";
import { BsMagic } from "react-icons/bs";
import { BiExpand } from "react-icons/bi";
import { BsGraphUpArrow } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { CgPerformance } from "react-icons/cg";
import { BsCalendar2Check } from "react-icons/bs";
import { HiViewfinderCircle } from "react-icons/hi2";

const iconConverter = (iconName: string, size: number = 20): JSX.Element => {
	switch (iconName) {
		case "AiOutlineHome":
			return <AiOutlineHome size={size} />;
		case "CgSearchFound":
			return <CgSearchFound size={size} />;
		case "BsUiChecksGrid":
			return <BsUiChecksGrid size={size} />;
		case "PiGarageDuotone":
			return <PiGarageDuotone size={size} />;
		case "BsMagic":
			return <BsMagic size={size} />;
		case "BiExpand":
			return <BiExpand size={size} />;
		case "BsGraphUpArrow":
			return <BsGraphUpArrow size={size} />;
		case "CgPerformance":
			return <CgPerformance size={size} />
		case "AiOutlineEye":
			return <AiOutlineEye size={size} />;
		case "BsCalendar2Check":
			return <BsCalendar2Check size={size} />;
		case "HiViewfinderCircle":
			return <HiViewfinderCircle size={size} />;
		default:
			return <></>; 
	}
};
	
export default iconConverter;
