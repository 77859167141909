import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import { CompraAgilDetailCompleto } from 'src/types/Types';

export default function PresupuestoCard( {detail}: {detail: CompraAgilDetailCompleto} ) {

	const nf = new Intl.NumberFormat("es-CL", {
		style: "currency",
		currency: "CLP",
	})

	return(
		<Card>
			<CardHeader>
				<CardTitle>
					Presupuesto
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="grid grid-cols-3 gap-4">
					<div className="col-span-2">
						<p className="text-sm font-semibold">MontoEstimado</p>
						<h1 className="scroll-m-20 text-3xl tracking-tight">{nf.format(detail.montoMoneda)}</h1>
					</div>
					<div>
						<p className="text-sm font-semibold">Moneda</p>
						<p className="text-lg font-semibold text-muted-foreground">{detail.moneda}</p>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
