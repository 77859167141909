import React, { useState } from 'react';
import { Dispatch, SetStateAction } from "react";
import regionData from "./dataregion.json";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../../../../ui/select"

interface SelectRegionProps {
    setRegion: Dispatch<SetStateAction<string>>;
}

export function SelectRegion({ setRegion }: SelectRegionProps) {

	const [selectedRegion, setSelectedRegion] = useState<string>("");

	const handleRegionChange = (region: string) => {
		setSelectedRegion(region);
		setRegion(region);
	};

	return (
		<div className='flex space-x-10'>
			<Select onValueChange={handleRegionChange}>
				<div className='flex flex-col'>
				<SelectTrigger className="w-[280px]">
					<SelectValue placeholder={selectedRegion ? selectedRegion : "Selecciona una región"} />
				</SelectTrigger>
					</div>
				<SelectContent>
					<SelectGroup>
						<SelectLabel>{selectedRegion ? selectedRegion : "Selecciona una región"}</SelectLabel>
						{regionData.regiones.map((region, index) => (
							<SelectItem key={index} value={region.region}>{region.region}</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	);
}

interface SelectComunaProps {
	setComuna: Dispatch<SetStateAction<string>>;
	selectedRegion: string;
}
export function SelectComuna({ setComuna, selectedRegion }: SelectComunaProps) {

	const [selectedComuna, setSelectedComuna] = useState<string>("");

	const handleComunaChange = (comuna: string) => {
		setSelectedComuna(comuna);
		setComuna(comuna);
	};

	return (
		<Select value={selectedComuna} onValueChange={handleComunaChange}>
			<div className='flex flex-col '>
			<SelectTrigger className="w-[280px]">
				<SelectValue placeholder={selectedComuna ? selectedComuna : "Selecciona una comuna"} />
			</SelectTrigger>
			</div>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>{selectedComuna ? selectedComuna : "Selecciona una comuna"}</SelectLabel>
					{selectedRegion && regionData.regiones.find(r => r.region === selectedRegion)?.comunas.map((comuna, index) => (
						<SelectItem value={comuna} key={index}>{comuna}</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
