import { Fade } from "react-awesome-reveal";
import { Button } from 'src/components/ui/button';
import { ArrowRight } from 'lucide-react'

import {
	Card,
	CardContent,
	// CardDescription,
	CardHeader,
	CardTitle,
	CardFooter,
} from "src/components/ui/card"

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "src/components/ui/tabs"

import { Separator } from "src/components/ui/separator"

import { useNavigate } from "react-router-dom";

const CardComponent = ({ title, price, iva, description }: { title: string, price: string, iva: string, description: string[] }) => {

	const navigate = useNavigate();

	return (
		<Card className="bg-secondary/65 w-[400px] min-h-[450px] p-6">
			<CardHeader>
				<CardTitle className="font-extrabold text-2xl">
					{title}
				</CardTitle>
			</CardHeader>
			<CardContent className="flex-grow w-[350px]">
				<h1 className="text-3xl font-bold">{price}</h1>
				<p className="text-sm">{iva}</p>
				<Separator className="mb-3 mt-3" />
				<ul className="list-disc text-base list-inside space-y-2 text-muted-foreground">
					{description.map((item, index) => {
						return <li key={index}>{item}</li>
					})}
				</ul>
				<Separator className="mb-3 mt-3" />
			</CardContent>
			<CardFooter className="flex justify-center bottom-0 w-full">
				<Button variant="default" onClick={() => navigate('/register')}>
					Empezar <ArrowRight className="ml-1 h-4 w-4" />
				</Button>
			</CardFooter>
		</Card>
	)
}

export default function Precios() {

	return (
		<div className="container">
			<Fade triggerOnce>
				<div className="text-center mb-20 mt-20">
					<p className="leading-7">Planes</p>
					<h1 className="scroll-m-20 font-extrabold tracking-tight lg:text-3xl">Conoce Nuestros Precios y Beneficios</h1>
				</div>
				<Tabs defaultValue="anual" className="text-center">
					<div className="mb-20">
						<TabsList>
							<TabsTrigger value="anual">Facturación Anual</TabsTrigger>
							<TabsTrigger value="mensual">Facturación Mensual</TabsTrigger>
						</TabsList>
					</div>
					<TabsContent value="anual" className="text-left">
						<div className="grid md:grid-cols-3 gap-20">
							<Fade className="flex justify-center" triggerOnce cascade>
								<CardComponent
									title="Básico"
									price="*Gratis*"
									iva=""
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial (5 Consultas diarias)",
										"Modelo de Recomendación basado en IA (2 Recomendaciones diarias)",
										"Soporte en línea",
									]}
								/>
								<CardComponent
									title="Ejecutivo"
									price="1,6 UF/mes (-20%)"
									iva="(mas IVA)"
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial",
										"Alertas configurables",
										"Modelo de Recomendación basado en IA",
										"Soporte especializado 24/7",
										"Procesador de Documentos",
									]}
								/>
								<CardComponent
									title="Premium"
									price="4 UF/mes (-20%)"
									iva="(mas IVA)"
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial",
										"Alertas configurables",
										"Modelo de Recomendación basado en IA",
										"Soporte especializado 24/7",
										"Procesador de Documentos",
										"Modelo de Expansión de Negocio",
										"Modelo predictor de costos",
									]}
								/>
							</Fade>
						</div>
					</TabsContent>
					<TabsContent value="mensual" className="text-left">
						<div className="grid md:grid-cols-3 gap-20">
							<Fade className="flex justify-center" triggerOnce cascade>
								<CardComponent
									title="Básico"
									price="*Gratis*"
									iva=""
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial (5 Consultas diarias)",
										"Modelo de Recomendación (2 Recomendaciones diarias)",
										"Soporte en línea",
									]}
								/>
								<CardComponent
									title="Ejecutivo"
									price="2 UF/mes"
									iva="(mas IVA)"
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial",
										"Alertas configurables",
										"Modelo de Recomendación basado en IA",
										"Soporte en línea",
										"Procesador de Documentos",
									]}
								/>
								<CardComponent
									title="Premium"
									price="5 UF/mes"
									iva="(mas IVA)"
									description={[
										"Modelo de Búsqueda con Inteligencia Artificial",
										"Alertas configurables",
										"Modelo de Recomendación basado en IA",
										"Soporte especializado 24/7",
										"Procesador de Documentos",
										"Modelo de Expansión de Negocio",
										"Modelo predictor de costos",
									]}
								/>
							</Fade>
						</div>
					</TabsContent>
				</Tabs>
			</Fade>
		</div>
	);
}