import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

const baseurl = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
	baseURL: baseurl,
});

const loginUserEmail = async (email: string, password: string) => {
	try {
		const response = await axiosInstance.post('/user/login/email', { email, password });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const loginUserGoogle = async (info: any) => {
	try {
		const response = await axiosInstance.post('/user/login/google', { 'data': info });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const loginUserGoogleToken = async (idToken: string) => {
	try {
		const decodedToken = jwtDecode(idToken);
		const response = await axiosInstance.post('/user/login/google', { 'data': decodedToken });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const fetchUserInfo = async (access_token: string) => {
	try {
		const response = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`,
			{
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
		return response.data;
	} catch (error: any) {
		console.error(error)
		return { status: 500, message: 'Error de conexión', data: {} }
	}
}

const fetchUserValid = async (email: string) => {
	try {
		const response = await axiosInstance.get('/user/valid', { params: { email } });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const createUser = async (values: any, provider: string) => {
	try {
		const data = {
			...values,
			provider: provider,
			create_date: moment().format('YYYY-MM-DD HH:mm:ss'),
		};
		const response = await axiosInstance.post('/user/create', data);
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const resetPassword = async (values: any) => {
	try {
		const email = values.email;
		const response = await axiosInstance.post('/user/reset_password', { email });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const changePassword = async (token: string, email: string, new_password: string, confirmPassword: string) => {
	try {

		if (new_password !== confirmPassword) {
			return { status: 400, message: 'Las contraseñas no coinciden', data: {} }
		}

		const response = await axiosInstance.post('/user/change_password', { token, email, new_password });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const verifyEmail = async (token: string, email: string) => {
	try {
		const response = await axiosInstance.post('/user/verify_user_email', { token, email });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};

const submitContactForm = async (nombre: string, email: string, celular: string, mensaje: string) => {
	try {
		const response = await axiosInstance.post('/user/contact_form', { nombre, email, celular, mensaje });
		return { 'status': response.status, 'message': response.data.message, 'data': response.data.data }
	} catch (error: any) {
		if (error.response) {
			return { status: error.response.status, message: error.response.data.message, data: error.response.data.data }
		} else {
			console.error(error)
			return { status: 500, message: 'Error de conexión', data: {} }
		}
	}
};



export {
	loginUserEmail,
	loginUserGoogle,
	loginUserGoogleToken,
	fetchUserInfo,
	fetchUserValid,
	createUser,
	resetPassword,
	changePassword,
	verifyEmail,
	submitContactForm,
};