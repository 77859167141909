import React from "react"

import { Input } from "src/components/ui/input"
import { WandSparkles, CircleChevronRight } from "lucide-react"
import { Trash2, Save } from "lucide-react"

import { Button } from "src/components/ui/button"

import { Slider } from "src/components/ui/slider"

import { fetchBusquedaInteligente, fetchBusquedaPorID } from "src/utils/Models";

import { addBusquedaGuardada, deleteBusquedaGuardada, fetchBusquedasGuardadas } from "src/utils/BusquedasGuardadas"

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from "src/components/ui/use-toast"

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	// FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { DataTable } from "./components/table";

import { BusquedaResponse, BusquedaGuardadaResponse } from "src/types/Types";

export default function BusquedaInteligente() {

	const { toast } = useToast();

	const queryClient = useQueryClient();
	const ultimaConsulta = sessionStorage.getItem('ultimaConsulta') || '';
	const ultimoTopValues = parseInt(sessionStorage.getItem('ultimoTopValues') || '15');

	const [query, setQuery] = React.useState<string>(ultimaConsulta);
	const [top_k, setTopK] = React.useState<number>(ultimoTopValues);
	const [isFormModified, setIsFormModified] = React.useState<boolean>(false);

	async function onSubmit(data: z.infer<typeof FormSchema>) {
		setQuery(data.consulta);
		setTopK(data.top_values[0]);
		sessionStorage.setItem('ultimaConsulta', data.consulta);
		sessionStorage.setItem('ultimoTopValues', data.top_values[0].toString());
		form.reset({
			consulta: data.consulta,
			top_values: data.top_values,
		});
	}

	const filtros = {
		"estado": "Publicada"
	}

	const queryRegex = new RegExp('^\\d{1,10}-\\d{1,10}-[A-Z0-9]{1,5}$');

	const queryResult = useQuery<BusquedaResponse>({
		queryKey: ['busqueda_inteligente', query, filtros, top_k],
		queryFn: async () => {
			if (queryRegex.test(query)) {
				const response = await fetchBusquedaPorID(query, filtros, top_k);
				return response;
			} else {
				const response = await fetchBusquedaInteligente(query, filtros, top_k);
				return response;
			}
		},
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!query && query.length > 0,
	})

	const FormSchema = z.object({
		consulta: z
			.string()
			.min(1, {
				message: "Por favor ingresa una búsqueda.",
			})
			.max(100, {
				message: "La búsqueda no puede tener más de 100 caracteres.",
			}),
		top_values: z
			.array(z.number()
				.gte(1, {
					message: "El valor mínimo es 1.",
				})
				.lte(25, {
					message: "El valor máximo es 25.",
				})
			)
	})

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			consulta: query,
			top_values: [top_k],
		},
	})

	React.useEffect(() => {
		const isModified = form.formState.dirtyFields.consulta || form.formState.dirtyFields.top_values ? true : false;
		setIsFormModified(isModified);
	}, [form.formState.dirtyFields.consulta, form.formState.dirtyFields.top_values]);

	const savedRequest = useQuery<BusquedaGuardadaResponse>({
		queryKey: ["busquedas"],
		queryFn: fetchBusquedasGuardadas,
		staleTime: 1000 * 60 * 60 * 2,
	})

	const addMutation = useMutation({
		mutationFn: addBusquedaGuardada,
		onMutate: () => {
			toast({
				title: "Guardando Búsqueda",
				description: `Estamos guardando la busqueda de ${query} en búsquedas guardadas.`
			});
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['busquedas'],
			});
			toast({
				title: "Búsqueda Guardada",
				description:`La búsqueda de ${query} ha sido guardada correctamente.`,
			});
		},
		onError: () => {
			toast({
				title: "Error al Guardar la Búsqueda",
				description:`Hubo un error al intentar agregar la búsqueda de ${query} a tus guardadas, por favor intenta nuevamente.`,
			});
		},
	});

	const deleteMutation = useMutation({
		mutationFn: deleteBusquedaGuardada,
		onMutate: () => {
			toast({
				title: "Eliminando la busqueda",
				description: `Se está eliminando la búsqueda de ${query}`
			})
		}, onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['busquedas'],
			});
			toast({
				title: "Búsqueda Eliminada",
				description: `La búsqueda de ${query} ha sido eliminada correctamente.`,
			});
		},
		onError: () => {
			toast({
				title: "Error al Guardar la Búsqueda",
				description: `Hubo un error al intentar eliminar la búsqueda ${query} a tus guardadas, por favor intenta nuevamente.`,
			});
		},
	});
	
	const savedItem = savedRequest?.data?.data?.busquedas.find(item => item.query === query && item.filtros === filtros);
	const isSaved = savedItem ? true : false;
	const token = savedItem?.token;

	return (
		<>
			<h1 className="scroll-m-20 text-3xl tracking-tight lg:text-3xl mt-5 justify-left">
				Búsqueda Inteligente
			</h1>
			<div className="relative flex flex-col justify-center items-center lg:px-0 w-full mt-5">
				<div className="w-full">
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<div className="flex justify-center items-center">
								<div className="relative flex flex-col md:flex-row items-center w-9/12 space-x-4 space-y-4">
									<WandSparkles className="h-6 w-6 absolute left-7 top-6" />
									<FormField
										control={form.control}
										name="consulta"
										render={({ field }) => (
											<FormItem className="w-full text-lg">
												<FormControl>
													<Input
														placeholder="Ingresa tu búsqueda o el código de la licitación referencial..."
														className="pl-12 text-lg"
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="top_values"
										render={({ field: { value, onChange } }) => (
											<FormItem className="w-64 text-lg">
												<FormControl>
													<Slider
														min={1}
														max={25}
														step={1}
														defaultValue={[top_k]}
														onValueChange={onChange}
													/>
												</FormControl>
												<FormMessage />
												<p className="text-sm text-center text-muted-foreground">
													{value} resultados
												</p>
											</FormItem>
										)}
									/>
									<div className="flex space-x-5">
										<Button type="submit" size="icon" className="w-20" disabled={queryResult.isLoading}>
											<CircleChevronRight />
										</Button>
										<Button
											variant={isSaved ? "destructive" : "secondary"}
											disabled={queryResult.isPending || isFormModified}
											onClick={() => {
												if (isSaved && token) {
													deleteMutation.mutate(token);
												} else {
													addMutation.mutate({ query, filtros });
												}
											}}
										>
											{isSaved ? <><Trash2 className="mr-2" />Eliminar</> : <><Save className="mr-2" />Guardar</>}
										</Button>
									</div>
								</div>
							</div>
						</form>
					</Form>
					<DataTable queryResponse={queryResult} />
				</div>
			</div>
		</>
	);
}
