import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router-dom';
import { Icons } from "src/components/ui/icons"
import { Button } from "src/components/ui/button"
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"
import { Input } from "src/components/ui/input"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { changePassword } from 'src/utils/Users';

import { Link } from "react-router-dom"
import { useToast } from "src/components/ui/use-toast"

import {
	Alert,
	AlertDescription,
} from "src/components/ui/alert"

export default function ResetPasswordForm() {

	const { token } = useParams<{ token?: string }>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const navigate = useNavigate();
	const { toast } = useToast();

	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});

	const FormSchema = z
		.object({
			email : z
				.string()
				.email({
				message: "Por favor ingresa un email válido.",
				}),
			password: z
				.string()
				.min(1, {
					message: "Por favor ingresa tu contraseña.",
				})
				.min(8, {
				message: "La contraseña debe tener al menos 8 caracteres.",
				})
				.max(50, {
				message: "La contraseña debe tener como máximo 50 caracteres.",
				})
				.regex(/[A-Z]/, {
					message: "La contraseña debe tener al menos una letra mayúscula"
				})
				.regex(/[a-z]/, {
					message: "La contraseña debe tener al menos una letra minúscula"
				})
				.regex(/[0-9]/, {
					message: "La contraseña debe tener al menos un número"
				}),
			confirmPassword: z
				.string()
				.min(1, {
					message: "Por favor confirma tu contraseña.",
			})
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: "Las contraseñas no coinciden.",
			path: ["confirmPassword"],
		})

	const form = useForm<z.infer<typeof FormSchema>>({
	resolver: zodResolver(FormSchema),
	defaultValues: {
		email: '',
		password: '',
		confirmPassword: '',
	},
	});

	async function onSubmit(data: z.infer<typeof FormSchema>) {

		setIsLoading(true);

		const response = await changePassword(token ?? '', data.email, data.password, data.confirmPassword);

		setApiResponse(response);

		setIsLoading(false);

	};

	useEffect(() => {
		if (apiResponse?.status === 200) {
			toast({
				title: "Contraseña Cambiada",
				description: "Tu contraseña ha sido cambiada exitosamente.",
			});
		  	navigate('/login');
		}
	}, [apiResponse, navigate, toast]);

	return (
		<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[450px]">
			<div className="flex flex-col space-y-2 text-center">
				<h1 className="text-4xl font-semibold tracking-tight">
					Reinicia tu Contraseña
				</h1>
				<p className="text-2xl text-muted-foreground">
					Ingresa tu correo para reiniciar tu contraseña.
				</p>
			</div>
			<div className="flex flex-col space-y-4">
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input id="email" type="email" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Contraseña</FormLabel>
								<FormControl>
									<Input id="password" type="password" {...field} />
								</FormControl>
								<FormMessage/>
								<FormDescription>
									La contraseña debe tener al menos 8 caracteres, una letra mayúscula, 
									una letra minúscula, un dígito y no debe contener espacios.
								</FormDescription>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="confirmPassword"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Confirmar Contraseña</FormLabel>
								<FormControl>
									<Input id="confirmPassword" type="password" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<div className="flex justify-center w-full">
						<Button type="submit" disabled={isLoading} className="w-full">
						{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						) : (
							null
						)}{" "}
							Reinicia la Contraseña
						</Button>
					</div>
					{apiResponse.status && (
						<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
							<AlertDescription>
								{apiResponse.message}
							</AlertDescription>
						</Alert>
					)}
				</form>
			</Form>
		</div>
		<div className="flex flex-col space-y-4">
			<p className="text-center text-muted-foreground">
				¿No tienes una cuenta?{" "}
				<Link
					to="/register"
					className="underline underline-offset-4 hover:text-primary"
				>
					Regístrate
				</Link>
			</p>
		</div>
	</div>
  )
}