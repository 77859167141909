import { Outlet } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive";
import Sidebar from "./SideBar"
import Header from "./Header"
import Footer from "./Footer"
import NotAuthorized from "../NotAuthorized"
import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../AuthContext";

export default function AppLayout() {

	const environment = process.env.REACT_APP_ENV || "dev"

	const [isMobileSidebarVisible, setMobileSidebarVisible] = useState(false);

	const toggleMobileSidebar = () => {
		setMobileSidebarVisible(!isMobileSidebarVisible);
	};

	const auth = useAuth();

	const navigate = useNavigate()

	useEffect(() => {
		if (!auth?.token) {
			navigate('/login');
		};
		if (auth?.token && (Object.keys(auth?.configuration).length === 0)) {
			navigate('/onboarding');
		}
	}, [auth, navigate]);
	
	const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

	if (environment === "dev") {
		return (
			<React.Fragment>
				<div className="flex flex-row h-[100vh] w-full">
					<Sidebar isMobile={isMobile} isVisible={isMobileSidebarVisible} toggleVisibility={toggleMobileSidebar} />
					<div className="flex flex-col z-10 flex-grow">
						<Header toggleSidebar={toggleMobileSidebar} />
						<ScrollArea className="w-full rounded-md border p-4 z-0 flex-grow">
							<div className="flex-1 bg-cover bg-center z-0 h-[calc(98vh-150px)]" style={{ backgroundImage: 'url("gallery/Landing.png")' }}>
								<Outlet />
							</div>
							<ScrollBar orientation="vertical" />
							<ScrollBar orientation="horizontal" />
						</ScrollArea>
						<Footer />
					</div>
				</div>
			</React.Fragment>
		)
	} else
		return <NotAuthorized />
}
