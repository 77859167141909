import { Skeleton } from "src/components/ui/skeleton"

import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

export default function Configuracion(){
	return (
		<Card>
			<CardHeader>
				<CardTitle>
					Configuración de Usuario
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Skeleton className="w-[400px] h-[200px]" />
			</CardContent>
		</Card>
	)
}