import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

export default function VisitaTecnicaCard( { visita_tecnica, className }: { visita_tecnica?: String, className?: string } ) {
	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Visita Tecnica
				</CardTitle>
			</CardHeader>
			<CardContent>
				{(!visita_tecnica || visita_tecnica === "") && (
					<p className="text-muted-foreground text-lg">No se encontró indicación de visita técnica en la ficha</p>
				)}
				<p className="font-semibold text-base">{visita_tecnica}</p>
			</CardContent>
		</Card>
	)
}