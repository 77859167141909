import React, { useEffect, useState } from "react"

import { useNavigate, Link } from 'react-router-dom';

import { Icons } from "src/components/ui/icons"
import { Button } from "src/components/ui/button"
import { Input } from "src/components/ui/input"

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import { AlertCircle, MailCheck } from "lucide-react"

import {
	Alert,
	AlertDescription,
} from "src/components/ui/alert"

import {
	Form,
	FormControl,
	// FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { fetchUserValid } from 'src/utils/Users';

import { useGoogleLogin } from '@react-oauth/google';

import { createUser, fetchUserInfo } from 'src/utils/Users';

import { useToast } from "src/components/ui/use-toast"

export default function Register({ className, ...props }: { className?: string }) {

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});

	const navigate = useNavigate();
	const { toast } = useToast();

	const FormSchema = z.object({
		email: z
			.string()
			.email({
				message: "Por favor ingresa un email válido.",
			})
	})

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			email: '',
		},
	});

	async function onSubmit(data: z.infer<typeof FormSchema>) {

		setIsLoading(true);

		const validation = await fetchUserValid(data.email);

		if (validation.data.valid === true) {
			navigate('/registerform', { state: { email: data.email } });
		} else {
			setApiResponse(validation);
		}

		setIsLoading(false);

	};


	const googlelogin = useGoogleLogin({
		onSuccess: async (credentialResponse: any) => {

			setIsLoading(true);
			const info = await fetchUserInfo(credentialResponse.access_token);
			const result = await createUser(info, 'google');
			setApiResponse(result);
			setIsLoading(false);

		},
		onError: errorResponse => {
			console.error(errorResponse);
		},
	});
	
	useEffect(() => {
		if (apiResponse?.status === 200) {
			toast({
				title: "Cuenta creada",
				description: "Tu cuenta ha sido creada exitosamente, ya puedes iniciar sesión.",
			})
			navigate('/login');
		}
	}, [apiResponse, navigate, toast]);

	return (

		<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[450px]">
			<div className="flex flex-col space-y-2 text-center">
				<h1 className="text-4xl font-semibold tracking-tight">
					Crear cuenta
				</h1>
				<p className="text-2xl text-muted-foreground">
					Ingresa tu correo para crear una cuenta
				</p>
			</div>
			<div className="grid gap-2">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Email</FormLabel>
									<FormControl>
										<Input id="email" type="email" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex justify-center w-full">
							<Button type="submit" disabled={isLoading} className="w-full">
								{isLoading ? (
									<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
								) : (
									<MailCheck className="mr-2 h-4 w-4" />
								)}{" "}
								Crea tu cuenta usando tu correo
							</Button>
						</div>
						{apiResponse.status && (
							<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
								<AlertCircle className="mr-2 h-4 w-4" />
								<AlertDescription>
									{apiResponse.message}
								</AlertDescription>
							</Alert>
						)}
					</form>
					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t" />
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-background px-2 text-muted-foreground">
								o bien
							</span>
						</div>
					</div>
					<div className="flex justify-center w-full">
					<Button variant="outline" type="button" disabled={isLoading} className='w-full' onClick={() => googlelogin()} >
							{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
							) : (
								<Icons.Google className="mr-2 h-4 w-4" />
							)}{" "}
							Crea tu cuenta usando Google
						</Button>
					</div>
				</Form>
			</div>
			<div className="flex flex-col space-y-4">
				<p className="text-center text-muted-foreground">
					¿Yá tienes una cuenta?{" "}
					<Link
						to="/login"
						className="underline underline-offset-4 hover:text-primary"
					>
						Inicia Sesión
					</Link>
				</p>
			</div>
			<p className="px-8 text-center text-sm text-muted-foreground">
				Al hacer click y crear la cuenta aceptas nuestros {" "}
				<Link
					to="#"
					className="underline underline-offset-4 hover:text-primary"
				>
					Términos de Servicio
				</Link>{" "}
				y{" "}
				<Link
					to="#"
					className="underline underline-offset-4 hover:text-primary"
				>
					Política de Privacidad
				</Link>
				.
			</p>
		</div>
	)
}