import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	// TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { Skeleton } from "src/components/ui/skeleton";

import { ScrollArea } from "src/components/ui/scroll-area"

import { useQuery } from '@tanstack/react-query'
import { fetchItems } from "src/utils/Models"

import { ItemsResponse } from 'src/types/Types';

function SkeletonCard() {
	return (
		<Card className="md:col-span-2">
			<CardHeader>
				<CardTitle>
					<Skeleton className="w-[350px] h-[30px]" />
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col space-y-5">
					<TableRow>
						<TableCell><Skeleton className="h-[20px] w-[80px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[160px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
						<TableCell><Skeleton className="h-[20px] w-[200px]" /></TableCell>
					</TableRow>
				</div>
			</CardContent>
		</Card>
	);
}

export default function ItemsCard( {codigo, className}: {codigo: string, className?: string} ) {

	const { isPending, isError, data, error } = useQuery<ItemsResponse>({
		queryKey: ['items', codigo],
		queryFn: () => fetchItems([codigo]),
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
		enabled: !!codigo,
	})

	if (codigo === '') {
		return <SkeletonCard />;
	}

	const items = data?.data?.items ? data.data.items : undefined;

	return(
		<Card className={className}>
			<CardHeader>
				<CardTitle>
					Items
				</CardTitle>
			</CardHeader>
			<CardContent>
				<ScrollArea className="w-full h-72">
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>Código</TableHead>
								<TableHead>NombreProducto</TableHead>
								<TableHead>Cantidad</TableHead>
								<TableHead>UnidadMedida</TableHead>
								<TableHead>Descripción</TableHead>
							</TableRow>
						</TableHeader>
						{isPending && 
							<TableBody>
								<TableRow>
									<TableCell><Skeleton className="h-[20px] w-[80px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[160px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[200px]" /></TableCell>
								</TableRow>
							</TableBody>
						}
						{isError && <p>Error: {String(error)}</p>}
						<TableBody>
							{items?.map((item: any, index: number) => (
								<TableRow key={index}>
									<TableCell>{item.CodigoProducto}</TableCell>
									<TableCell>{item.NombreProducto}</TableCell>
									<TableCell>{item.Cantidad}</TableCell>
									<TableCell>{item.UnidadMedida}</TableCell>
									<TableCell>{item.Descripcion}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</ScrollArea>
			</CardContent>
		</Card>
	);
}
