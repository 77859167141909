import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";

import { SetStateAction, useState } from "react";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label"

import { SelectComuna, SelectRegion } from "./components/selectRegionComuna"
import CreateBusinessLines from "./components/createBusinessLines";
import EditBusinessLines from "./components/editBusinessLines";
import { Button } from "../../../ui/button";
import { userConfigurate } from "src/utils/UsersAuth";
import { Textarea } from "src/components/ui/textarea";
import React from "react";
import { Icons } from "src/components/ui/icons";
import { AiOutlineArrowRight } from "react-icons/ai";

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"

import { useToast } from "src/components/ui/use-toast"

import { useQueryClient } from "@tanstack/react-query";

export default function DataForm() {

	const queryClient = useQueryClient();
	
	const { toast } = useToast();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [businessLines, setBusinessLines] = useState<{ name: string; products: string }[]>([]);

	const updateBusinessLines = (updatedBusinessLines: SetStateAction<{ name: string; products: string; }[]>) => {
		setBusinessLines(updatedBusinessLines);
	};

	const [region, setRegion] = useState<string>("");
	
	const handleRegionChange = (region: any) => {
		setRegion(region);
	};

	const formSchema = z.object({
		region: z
			.string()
			.min(1, { message: "Debes seleccionar una región" }),
		comuna: z
			.string()
			.min(1, { message: "Debes seleccionar una comuna" }),
		direccion: z
			.string()
			.min(1, { message: "Debes ingresar una dirección" })
			.max(100, { message: "La dirección no puede exceder los 100 caracteres" }),
		descripcion: z
			.string()
			.min(1, { message: "Debes ingresar una descripción" })
			.min(50, { message: "La descripción debe tener al menos 50 caracteres" })
			.max(250, { message: "La descripción no puede exceder los 250 caracteres" }),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			region: "",
			comuna: "",
			direccion: "",
			descripcion: "",
		},
	});

	async function userConfigurationSubmit(data: z.infer<typeof formSchema>) {

		try {
			setIsLoading(true);

			const submit_data = {
				...data,
				businesslines: businessLines,
			};

			const response = await userConfigurate(submit_data);

			if (response.status === 200) {
				queryClient.invalidateQueries({ queryKey: ['configuration'] });

				toast({
					title: "Configuración guardada",
					description: "Tu configuración ha sido guardada exitosamente",
				});

			} else {
				toast({
					title: "Error al guardar",
					description: "Hubo un error al intentar guardar tu configuración, por favor intenta nuevamente.",
				});
			}

		} catch (error) {
			toast({
				title: "Error al guardar",
				description: "Hubo un error al intentar guardar tu configuración, por favor intenta nuevamente.",
			});
		}

		setIsLoading(false);
	}

	return (
		<div className="border border-solid border-gray-900 rounded-md p-10 pt-10 bg-secondary"> 
		<Form {...form}>
			<h1 className="text-2xl font-semibold tracking-tight">Formulario de cliente</h1>
			<p className="text-sm text-muted-foreground">Ingresa los datos requeridos, podrás volver a este formulario más adelante si lo necesitas</p>
				<form className="flex-col">
					<FormField
						control={form.control}
						name="direccion"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Dirección Comercial</FormLabel>
								<FormControl>
									<Input id="direccion" type="text" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<div className="flex space-x-5 py-2">
						<div className="flex-col">
							<div className='flex space-x-10'>
								<FormField
									control={form.control}
									name="region"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Región</FormLabel>
											<FormControl>
												<SelectRegion setRegion={(value) => { field.onChange(value); handleRegionChange(value); }} {...field} />
											</FormControl>
											<FormMessage/>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="comuna"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Comuna</FormLabel>
											<FormControl>
												<SelectComuna setComuna={(value) => { field.onChange(value); }} selectedRegion={region} {...field} />
											</FormControl>
											<FormMessage/>
										</FormItem>
									)}
								/>
							</div>
						</div>
					</div>
					<FormField
						control={form.control}
						name="descripcion"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Descripción de la Empresa</FormLabel>
								<FormControl>
									<Textarea id="descripcion" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<p className="text-sm text-muted-foreground">Cuéntanos con tus palabras a que se dedica tu empresa en grandes rasgos</p>
					<div className="py-2"> 
						<Label>Lineas de Negocio</Label>
						<div className="space-y-2">
							<EditBusinessLines businessLines={businessLines} setBusinessLines={updateBusinessLines} />
							<CreateBusinessLines setBusinessLines={setBusinessLines} />
						</div>
					</div>
					<p className="text-sm text-muted-foreground">Si licitas productos o servicios muy diversos y quieres tener un 
					control más granular de tu negocio, te aconsejamos a crear una línea de negocio para cada grupo 
					y recibir recomendaciones más diferenciadas.</p>
					<div className="flex justify-end">
						<Button type="submit" variant="default" onClick={form.handleSubmit(userConfigurationSubmit)}>
							{!isLoading && 'Finalizar'}
							{isLoading ? <Icons.Spinner className="ml-2 h-4 w-4 animate-spin" /> : <AiOutlineArrowRight className="ml-2" />}
						</Button>
					</div>
				</form>
			</Form>
		</div>
	)
}
