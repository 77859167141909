import { Fade } from "react-awesome-reveal";
import { FC } from 'react';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "src/components/ui/accordion"

interface AccordionItemProps {
	value: string;
	trigger: string;
	content: string;
}

const CustomAccordionItem: FC<AccordionItemProps> = ({ value, trigger, content }) => {
	return (
		<AccordionItem value={value}>
			<AccordionTrigger className="text-xl">
				{trigger}
			</AccordionTrigger>
			<AccordionContent className="text-lg">
				{content}
			</AccordionContent>
		</AccordionItem>
	);
}

export default function FAQ() {

	return (
		<Fade triggerOnce>
			<div className="flex flex-col justify-center items-center mt-20">
				<p className="leading-7">FAQ's</p>
				<h1 className="scroll-m-20 font-extrabold tracking-tight text-3xl">Preguntas Frecuentes</h1>
				<div className="justify-center items-center" style={{ width: '50%' }}>
					<Accordion type="single" collapsible defaultValue='item-1'>
					<Fade cascade damping={0.2} triggerOnce>
						<CustomAccordionItem 
							value="item-1"
							trigger="¿Cómo Funciona el sistema?"
							content="Nuestro sistema recopila datos desde la API de mercado público constantemente, los procesa y almacena de manera segura. Con la información que recopilamos, entrenamos modelos de machine learning e inteligencia artificial para poder entregarte oportunidades de negocio que se ajusten a tus necesidades. Además, podrás configurar alertas para recibir notificaciones cuando encuentre oportunidades que te interesen."
						/>
						<CustomAccordionItem 
							value="item-2"
							trigger="¿Los datos de compras ágiles / licitaciones son confiables?"
							content="Los datos se obtienen directamente desde la API de mercado público, por lo que la información es confiable y actualizada. En cada oportunidad de negocio, podrás ver la fecha de actualización de la información y la fecha de cierre de la licitación."
						/>
						<CustomAccordionItem 
							value="item-3"
							trigger="¿Cada cuanto se actualiza la base de datos?"
							content="El proceso de actualización de la base de datos es constante, sin embargo, la frecuencia de actualización de los datos depende de la plataforma de mercado público, por lo que no podemos garantizar una actualización en tiempo real."
						/>
						<CustomAccordionItem 
							value="item-4"
							trigger="¿Debo estar constantemente llenando datos o completando formularios?"
							content="Para poder configurar tus alertas y recibir notificaciones, es necesario que completes tu perfil con la información necesaria. Sin embargo, una vez que lo hagas, el sistema se encargará de notificarte cuando encuentre oportunidades que se ajusten a tus necesidades, no es necesario que llenes información constantemente ya que podemos obtener los datos desde la plataforma de mercado público."
						/>
						<CustomAccordionItem 
							value="item-5"
							trigger="¿Puedo postular desde la aplicación?"
							content="No. La aplicación está diseñada para que puedas encontrar o buscar oportunidades de negocio, para ayudarte a gestionarlas y adjudicarlas, pero no es un sistema de gestión por lo que debes postular directamente desde la plataforma de mercado público."
						/>
						<CustomAccordionItem 
							value="item-6"
							trigger="¿Puedo usar la aplicación en mi celular?"
							content="Sí, la aplicación es responsive, por lo que podrás usarla desde tu celular, tablet o computador ingresando a la url."
						/>
						<CustomAccordionItem 
							value="item-7"
							trigger="¿Machine Learning, Inteligencia artificial, cómo ayuda esto a mi negocio?"
							content="El machine learning y la inteligencia artificial son herramientas muy útiles para poder mejorar tu desempeño en el mercado público. Teniendo modelos automáticos que realicen tareas operativas de búsqueda, análisis, predicción y procesamiento masivo de documentos, podrás enfocarte en tareas más estratégicas y de mayor valor para tu negocio. Deja que las máquinas hagan el trabajo mecánico por ti."
						/>
						<CustomAccordionItem 
							value="item-8"
							trigger="¿Cómo puedo obtener soporte?"
							content="Para obtener soporte, puedes contactarnos a través de nuestro chat en la aplicación, o enviarnos un correo a soporte@licitaeasy.cl Tenemos un equipo dedicado que te ayudará con cualquier duda o problema que tengas."
						/>
						<CustomAccordionItem 
							value="item-9"
							trigger="¿Puedo probar la aplicación antes de comprar?"
							content="Sí, puedes probar la aplicación de forma gratuita por 30 días con todas sus funcionalidades. No necesitas ingresar tu tarjeta de crédito para probarla."
						/>
						<CustomAccordionItem 
							value="item-10"
							trigger="¿Cómo puedo pagar?"
							content="Recomendamos pagar con tarjeta de crédito o débito mediante subscripción mensual o anual. Si necesitas otro método de pago, por favor contáctanos."
						/>
						<CustomAccordionItem 
							value="item-11"
							trigger="¿Puedo cancelar mi suscripción en cualquier momento?"
							content="Sí, puedes cancelar tu suscripción en cualquier momento. La cancelación de tu suscripción se hará efectiva al finalizar el periodo de facturación actual. No se realizarán reembolsos por periodos no utilizados."
						/>
					</Fade>
				</Accordion>
				</div>
			</div>
		</Fade>
	);
}