import DataForm from "./DataForm";

import { Button } from "src/components/ui/button";

import { useAuth } from "src/components/AuthContext";

import { useNavigate } from "react-router-dom";

export default function Form() {

	const navigate = useNavigate();
	const auth = useAuth();
	
	if ((Object.keys(auth?.configuration).length > 0)) {
		return(
			<div className="container relative flex-col items-center justify-center grid">
				<div className="mx-auto flex w-full flex-col justify-center space-y-6 lg:w-[950px]">
					<div className="flex flex-col space-y-2 text-center">
						<h1 className="text-4xl font-semibold tracking-tight">¡Ya has completado tu registro!</h1>
						<p className="mt-5 text-2xl">Puedes seguir navegando en la plataforma y disfrutar de todas las funcionalidades que tenemos para ti</p>
					</div>
					<div className="flex items-center flex-col mt-5">
						<Button onClick={() => navigate('/home')} className="w-full" variant="default" size="lg">Ir a la plataforma</Button>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<div className="container relative flex-col items-center justify-center grid">
			<div className="mx-auto flex w-full flex-col justify-center space-y-6 lg:w-[950px]">
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-4xl font-semibold tracking-tight">¡Ayúdanos a conocerte mejor!</h1>
					<p className="mt-5 text-2xl">Procura que tus respuestas estén correctas y verifica los datos que ingreses en el formulario, ya que estos influyen directamente en el sistema
						Puedes volver a este formulario cuando lo necesites y editar tus preferencias para afinar la calidad de tus recomendaciones</p>
				</div>
				<div className="flex items-center flex-col mt-5">
					<DataForm />
				</div>
			</div>
		</div>

	);
}
