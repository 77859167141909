import axios from 'axios';

const baseurl = process.env.REACT_APP_BACKEND_URL ?? '';

const axiosInstance = axios.create({
	baseURL: baseurl,
});

axiosInstance.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token');
	config.headers.Authorization =  token ? `Bearer ${token}` : '';
	return config;
});

const fecthRecomendations = async () => {
	const response = await axiosInstance.get('/licitaciones/recommender');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchFollowed = async () => {
	const response = await axiosInstance.get('/follow/list');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const fetchFollowedDetails = async () => {
	const response = await axiosInstance.get('/follow/details');
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const addFollowed = async (codigo: string) => {
	const response = await axiosInstance.get('/follow/add', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const deleteFollowed = async (codigo: string) => {
	const response = await axiosInstance.delete('/follow/delete', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchDetailsComplete = async (codigos: string[]) => {
	const codesParams = codigos.map(codigo => `codigos=${codigo}`).join('&');
	const response = await axiosInstance.get(`/licitaciones/get_detail?${codesParams}`);
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchDetailsSimple = async (codigos: string[]) => {
	const codesParams = codigos.map(codigo => `codigos=${codigo}`).join('&');
	const response = await axiosInstance.get(`/licitaciones/get_detail_simple?${codesParams}`);
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchDetailsCA = async (codigo: string) => {
	const response = await axiosInstance.get('/licitaciones/get_details_ca', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchDetailsLicit = async (codigo: string) => {
	const response = await axiosInstance.get('/licitaciones/get_details_licit', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const fetchItems = async (codigos: string[]) => {
	const codesParams = codigos.map(codigo => `codigos=${codigo}`).join('&');
	const response = await axiosInstance.get(`/licitaciones/get_items?${codesParams}`);
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
};

const scrapeDetails = async (codigo: string) => {
	const response = await axiosInstance.get('/licitaciones/scrape_details', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const scrapeAdjuntos = async (codigo: string) => {
	const response = await axiosInstance.get('/licitaciones/scrape_adjuntos', { params: { codigo } });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const fetchBusquedaInteligente = async (query: string, filtros: any, top_k: number) => {
	const response = await axiosInstance.post('/licitaciones/busqueda_inteligente', { query, filtros, top_k });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const fetchBusquedaPorID = async (codigo: string, filtros: any, top_k: number) => {
	const response = await axiosInstance.post('/licitaciones/busqueda_por_id', { codigo, filtros, top_k });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const queryDocumentos = async (codigo: string, query: string, top_n: number) => {
	const response = await axiosInstance.post('/documentos/ask', { codigo, query, top_n });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

const queryCache = async (codigo: string) => {
	const response = await axiosInstance.post('/documentos/cache', { codigo });
	return {'status': response.status, 'message': response.data.message, 'data': response.data.data}
}

export { 
	fecthRecomendations, 
	fetchFollowed,
	fetchFollowedDetails,
	addFollowed,
	deleteFollowed,
	fetchDetailsComplete,
	fetchDetailsSimple,
	fetchDetailsCA,
	fetchDetailsLicit,
	scrapeDetails,
	scrapeAdjuntos,
	fetchItems,
	fetchBusquedaInteligente,
	fetchBusquedaPorID,
	queryDocumentos,
	queryCache
};