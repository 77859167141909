import {
	Card,
	CardContent,
	// CardDescription,
	// CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/card"

import {
	Table,
	TableBody,
	// TableCaption,
	TableCell,
	// TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "src/components/ui/table"

import { Skeleton } from "src/components/ui/skeleton";

import { ScrollArea } from "src/components/ui/scroll-area"

import { useQuery } from '@tanstack/react-query'
import { fetchItems } from "src/utils/Models"

import { CompraAgilDetailCompleto, ItemsResponse } from 'src/types/Types';

export default function ItemsCard( {detail}: {detail: CompraAgilDetailCompleto} ) {

	const { isPending, isError, data, error } = useQuery<ItemsResponse>({
		queryKey: ['items', detail.codigo],
		queryFn: () => fetchItems([detail.codigo]),
		staleTime: 1000 * 60 * 60 * 2, // data is stale after 2 hours
	})

	const items = data?.data?.items ? data.data.items : undefined;
	
	return(
		<Card className="md:col-span-2">
			<CardHeader>
				<CardTitle>
					Items
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Table>
					<ScrollArea className="w-full h-72">
						<TableHeader>
							<TableRow>
								<TableHead>Código</TableHead>
								<TableHead>NombreProducto</TableHead>
								<TableHead>Cantidad</TableHead>
								<TableHead>UnidadMedida</TableHead>
								<TableHead>Descripción</TableHead>
							</TableRow>
						</TableHeader>
						{isPending && 
							<TableBody>
								<TableRow>
									<TableCell><Skeleton className="h-[20px] w-[80px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[160px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[100px]" /></TableCell>
									<TableCell><Skeleton className="h-[20px] w-[200px]" /></TableCell>
								</TableRow>
							</TableBody>
						}
						{isError && <p>Error: {String(error)}</p>}
						<TableBody>
							{items?.map((item: any, index: number) => (
								<TableRow key={index}>
									<TableCell>{item.CodigoProducto}</TableCell>
									<TableCell>{item.NombreProducto}</TableCell>
									<TableCell>{item.Cantidad}</TableCell>
									<TableCell>{item.UnidadMedida}</TableCell>
									<TableCell>{item.Descripcion}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</ScrollArea>
				</Table>
			</CardContent>
		</Card>
	);
}
