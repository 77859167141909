import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import esLocale from '@fullcalendar/core/locales/es';

import moment from 'moment';

import { useEffect, useState } from 'react';

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "src/components/ui/dialog"

import { Badge } from 'src/components/ui/badge';

import '../assets/index.css';

export default function CalendarComponent(events: any) {

	const [dialogOpen, setDialogOpen] = useState(false)
	const [selectedEvent, setSelectedEvent] = useState<any>()

	useEffect(() => {
		let calendarEl: HTMLElement = document.getElementById('calendar')!;

		let calendar = new Calendar(calendarEl, {
			plugins: [ multiMonthPlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
				right: 'multiMonthYear,dayGridMonth,timeGridWeek,listWeek'
			},
			initialDate: moment().toDate(),
			initialView: 'timeGridWeek',
			multiMonthMaxColumns: 2,
			multiMonthMinWidth: 450,
			selectable: true,
			navLinks: true,
			editable: false,
			dayMaxEvents: true,
			locale: esLocale,
			events: events,
			height: 'auto',
			eventClick: function(info) {
				setSelectedEvent(info.event)
				setDialogOpen(true)
			}
		});

		calendar.render();
		// calendar.updateSize();
	}, [events]);

	return (
		<div className='md:w-full'>
		<div id="calendar" className="md:min-w-[1000px]"></div>
			<div>
				<Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
					<DialogContent 
						{...selectedEvent?.extendedProps?.class === 'Cierre' ? {className: 'border-rose-500'} : 
							selectedEvent?.extendedProps?.class === 'Publicación' ? {className: 'border-green-500'} : 
							{className: ''}
						}
					>
						<DialogHeader>
							<DialogTitle>
								<Badge className='mr-2'>{selectedEvent?.extendedProps?.type}</Badge>{selectedEvent?.title}
							</DialogTitle>
							<DialogDescription>
								Código: {selectedEvent?.extendedProps?.code}
							</DialogDescription>
							<div className="flex items-center space-x-2">
								<div className="grid flex-1 gap-2">
									<p>{selectedEvent?.extendedProps?.description}</p>
								</div>
							</div>
						</DialogHeader>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
}