import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from 'src/components/ui/card';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from 'src/components/ui/accordion';

import { ScrollArea } from "src/components/ui/scroll-area"

import { Pregunta } from 'src/types/Types';

export default function PreguntasRespuestasCard({ pyr=[], className }: { pyr?: Pregunta[], className?: string }) {

	return (
		<Card className={className}>
			<CardHeader>
				<CardTitle>Preguntas y Respuestas</CardTitle>
			</CardHeader>
		<CardContent>
			<ScrollArea className="w-full max-h-80">
				{pyr.length > 0 ? (
				<Accordion type="single" collapsible className="w-full">
					{pyr.map((item:Pregunta, index: number) => (
					<AccordionItem key={index} value={`item-${index + 1}`}>
						<AccordionTrigger className="text-left text-sm">
							{item.FechaHora} | {item.Descripcion}
						</AccordionTrigger>
						<AccordionContent>
							{item.RespuestaPublicada.EstadoR !== 0 ? (
								<div className="text-left">
									<p>{item.RespuestaPublicada.FechaHora} | Respuesta: {item.RespuestaPublicada.Descripcion}</p>
								</div>
							) : (
								<p className='text-muted-foreground'>No hay respuesta publicada.</p>
							)}
						</AccordionContent>
					</AccordionItem>
					))}
				</Accordion>
				) : (
				<p className='text-muted-foreground text-lg'>No hay preguntas ni respuestas disponibles.</p>
				)}
			</ScrollArea>
		</CardContent>
		</Card>
	);
}
