import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Icons } from "src/components/ui/icons"
import { Button } from "src/components/ui/button"
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "src/components/ui/form"
import { Input } from "src/components/ui/input"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { AlertCircle, MailCheck } from "lucide-react"

import { createUser, fetchUserInfo } from 'src/utils/Users';

import { useGoogleLogin } from '@react-oauth/google';

import {
	Alert,
	AlertDescription,
} from "src/components/ui/alert"

import { useToast } from "src/components/ui/use-toast"

export default function RegisterForm() {

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const location = useLocation();
	const navigate = useNavigate();
	const { toast } = useToast();
	
	const [apiResponse, setApiResponse] = useState<{ status?: number, message?: string, data?: any }>({});

	const email = location.state?.email;

	useEffect(() => {
		if (!email) {
			navigate('/register');
		}
	}, [email, navigate]);

	const FormSchema = z
		.object({
			email : z
				.string()
				.email({
				message: "Por favor ingresa un email válido.",
				}),
			name: z
				.string()
				.min(1, {
					message: "Por favor ingresa tu nombre.",
				})
				.min(3, {
					message: "El nombre debe tener al menos 3 caracteres.",
				})
				.max(30, {
					message: "El nombre debe tener como máximo 30 caracteres.",
				})
				.refine((value) => value.trim().length > 0, {
					message: "El nombre no puede contener solo espacios en blanco.",
				}),
			password: z
				.string()
				.min(1, {
					message: "Por favor ingresa tu contraseña.",
				})
				.min(8, {
				message: "La contraseña debe tener al menos 8 caracteres.",
				})
				.max(50, {
				message: "La contraseña debe tener como máximo 50 caracteres.",
				})
				.regex(/[A-Z]/, {
					message: "La contraseña debe tener al menos una letra mayúscula"
				})
				.regex(/[a-z]/, {
					message: "La contraseña debe tener al menos una letra minúscula"
				})
				.regex(/[0-9]/, {
					message: "La contraseña debe tener al menos un número"
				}),
			confirmPassword: z
				.string()
				.min(1, {
					message: "Por favor confirma tu contraseña.",
			})
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: "Las contraseñas no coinciden.",
			path: ["confirmPassword"],
		})

	const form = useForm<z.infer<typeof FormSchema>>({
	resolver: zodResolver(FormSchema),
	defaultValues: {
		email: email,
		name: '',
		password: '',
		confirmPassword: '',
	},
	})

	async function onSubmit(data: z.infer<typeof FormSchema>) {

		setIsLoading(true);
		const result = await createUser(data, 'email');
		setApiResponse(result);
		setIsLoading(false);

	}

	const googlelogin = useGoogleLogin({
		onSuccess: async (credentialResponse: any) => {

			setIsLoading(true);
			const info = await fetchUserInfo(credentialResponse.access_token);
			const result = await createUser(info, 'google');
			setApiResponse(result);
			setIsLoading(false);

		},
		onError: errorResponse => {
			console.error(errorResponse);
		},
	});

	useEffect(() => {
		if (apiResponse?.status === 200) {
			toast({
				title: "Cuenta creada",
				description: "Tu cuenta ha sido creada exitosamente, ya puedes iniciar sesión.",
			})
			navigate('/login');
		}
	}, [apiResponse, navigate, toast]);

	return (
	<div className="lg:p-8">
		<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[450px]">
			<div className="flex flex-col space-y-2 text-center">
				<h1 className="text-4xl font-semibold tracking-tight">
				Crear cuenta
				</h1>
				<p className="text-2xl text-muted-foreground">
				Ingresa los datos del formulario para poder crear tu cuenta
				</p>
			</div>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input id="email" type="email" disabled {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Nombre</FormLabel>
								<FormControl>
									<Input id="nombre" type="text" {...field} />
								</FormControl>
								<FormDescription>Este es el nombre que aparecerá en tu perfil.</FormDescription>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Contraseña</FormLabel>
								<FormControl>
									<Input id="password" type="password" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="confirmPassword"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Confirmar Contraseña</FormLabel>
								<FormControl>
									<Input id="confirmPassword" type="password" {...field} />
								</FormControl>
								<FormMessage/>
							</FormItem>
						)}
					/>
					<div className="flex justify-center w-full">
						<Button type="submit" disabled={isLoading} className="w-full">
						{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						) : (
							<MailCheck className="mr-2 h-4 w-4" />
						)}{" "}
							Crear cuenta con tu Correo
						</Button>
					</div>
					{apiResponse.status && (
						<Alert variant={apiResponse.status === 200 ? 'default': 'destructive'}>
							<AlertCircle className="mr-2 h-4 w-4" />
							<AlertDescription>
								{apiResponse.message}
							</AlertDescription>
						</Alert>
					)}
					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t" />
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-background px-2 text-muted-foreground">
							o bien
							</span>
						</div>
					</div>
					<div className="flex justify-center w-full">
						<Button variant="outline" type="button" disabled={isLoading} onClick={() => googlelogin()} className='w-full'>
						{isLoading ? (
							<Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
						) : (
							<Icons.Google className="mr-2 h-4 w-4" />
						)}{" "}
						Crea tu cuenta usando Google
						</Button>
					</div>
				</form>
			<p className="px-8 text-center text-sm text-muted-foreground">
				Al hacer click y crear la cuenta aceptas nuestros {" "}
				<Link
					to="#"
					className="underline underline-offset-4 hover:text-primary"
					>
					Términos de Servicio
				</Link>{" "}
				y{" "}
				<Link
					to="#"
					className="underline underline-offset-4 hover:text-primary"
					>
					Política de Privacidad
				</Link>
				.
			</p>
			</Form>
		</div>
	</div>
  )
}