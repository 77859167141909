import { Fade } from "react-awesome-reveal";

import {
	Card,
	CardHeader,
	CardContent,
} from "src/components/ui/card";

import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "src/components/ui/collapsible"

import { ChevronsUpDown } from "lucide-react";

const CustomCard = ({ title, content }: { title: string, content: string }) => {
	return (
		<div className="w-[250px] m-1 overflow-visible">
			<Collapsible>
				<Card>
					<CollapsibleTrigger>
						<CardHeader>
							<div className="flex items-center justify-between">
								<h3 className="font-bold text-left">{title}</h3>
								<ChevronsUpDown className="h-8 w-8" />
							</div>
						</CardHeader>
					</CollapsibleTrigger>
					<CollapsibleContent>
						<CardContent>
							<p className="text-gray-400 h-[150px]">{content}</p>
						</CardContent>
					</CollapsibleContent>
				</Card> 
			</Collapsible>
		</div>
	);
};

export default function Problema() {
return (
	<Fade triggerOnce>
		<div className="container mt-20 mb-20">
			<div className="flex flex-wrap justify-center">
				<div className="max-w-[700px] text-left">
					<h2 className="text-4xl font-bold text-sky-400">
						Al vender al estado descubrimos un problema común
					</h2>
					<p className="text-2xl text-gray-400 mt-10">
						La falta de notificaciones en tiempo real, la dificultad para encontrar oportunidades relevantes y la escasez de tiempo para preparar licitaciones son solo algunos de los desafíos que enfrentan las empresas que participan en el mercado público.
					</p>
				</div>
				<div className="w-[400px] mt-10 md:mt-0">
					<img src="/gallery/comment2.png" alt="..." />
				</div>
			</div>
			<div className="flex flex-wrap mt-10 justify-center">
				<div className="flex items-left">
					<h2 className="text-2xl font-bold text-sky-400 mb-10">Descubrimos también que:</h2>
				</div>
				<div className="flex flex-wrap items-center justify-center">
					<Fade triggerOnce direction="down" cascade damping={0.2} duration={500}>
						<CustomCard
							title="Cuesta navegar por el sitio"
							content="Navegar por el Mercado Público puede resultar confuso y poco intuitivo para los nuevos usuarios, lo que dificulta el proceso de adaptación."
						/>
						<CustomCard
							title="El seguimiento de licitaciones es complejo"
							content="La falta de notificaciones instantáneas dificulta la capacidad de estar al tanto de las oportunidades de licitación relevantes en el momento adecuado, y la vista completa de las licitaciones y sus fechas relevantes."
						/>
						<CustomCard
							title="Es dificil encontrar las mejores oportunidades"
							content="La sobrecarga de información y la falta de precisión en la clasificación de licitaciones pueden llevar a oportunidades perdidas debido a la dificultad para encontrarlas, además el tiempo para evaluar cada licitación puede ser alto."
						/>
						<CustomCard
							title="Falta tiempo para preparar licitaciones"
							content="La cantidad abrumadora de licitaciones y la necesidad de dedicar tiempo a cada una pueden resultar en ofertas apresuradas y de baja calidad, lo que reduce las posibilidades de adjudicación."
						/>
						<CustomCard 
							title="Hay falta de información clave"
							content="La falta de información detallada sobre las licitaciones puede llevar a compromisos incumplidos y pérdida de oportunidades de adjudicación, lo que resulta en una pérdida de tiempo y recursos."
						/>
					</Fade>
				</div>
			</div>
		</div>
	</Fade>
	);
}