import { Fade } from "react-awesome-reveal";
import { Button } from "src/components/ui/button";
import { ArrowRight } from 'lucide-react'

export default function CallToAction() {

	return (
		<Fade cascade>
			<section className="mt-5">
				<div className="container">
					<div className="text-center">
						<div className="p-5 rounded-3 mb-10">
							<div className="py-3">
								<h3 className="opacity-80 tracking-tight lg:text-3xl">¿LISTO PARA COMENZAR?</h3>
								<h2 className="tracking-tight lg:text-2xl">Moderniza tu sistema de licitaciones públicas utilizando la tecnología de hoy.</h2>
								<h2 className="tracking-tight lg:text-2xl text-sky-400">Únete para una prueba de un mes gratis!</h2>
							</div>
							<div className="flex-center d-flex mt-5">
								<a href="/login">
									<Button className="btn btn-info text-lg" size='lg'>Empezar<ArrowRight className="ml-1 h-4 w-4" /></Button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fade>
	);
}